.MainMenu{
    height: 42px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #c3c3c3;
    position: relative;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.MainMenuElementOutOutOut0>.MainMenuSubMenuOut>.MainMenuSubMenu>div>a>nobr{
    margin-right: 20px;
    margin-left: 20px;
}
.MainMenuElementOutOutOut5>.MainMenuSubMenuOut>.MainMenuSubMenu>div>a>nobr{
    margin-right: 20px;
    margin-left: 20px;
}

.MainMenuElementOutOutOut{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.MainMenuElementOutOutOut:last-of-type{

    left: 1px;
    position: relative;
}

.MainMenuElementOutOutOutForum>div{
    border-right: none;
}


.MainMenuElementOutOut{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MainMenuElementOut{
    width: 100%;
}

.MainMenuElementOut>.MainMenuElement>a,.MainMenuElementOut>.MainMenuElement>a:visited{
    /*color: rgb(0, 0, 0);*/
}

.MainMenuElementOutOutOut:hover{
    background-color: #e3e3e3;

}


.MainMenuElementOutOutOutActive>.MainMenuElementOutOut>.MainMenuElementOut>.MainMenuElementBorder{
    border: none;
}

.MainMenuElementOutOutOut>.MainMenuElementOutOut{
    border: 0 solid #ced4d9;
    height: 49px;
    border-bottom: none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    margin-top: -4px;
    transition-delay: 300ms;
}

.MainMenuElementOutOutOut:hover>.MainMenuElementOutOut{
    border: 1px solid #ced4d9;
    margin-left: -1px;
    height: 49px;
    border-bottom: none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    margin-top: -4px;
    transition-delay: 300ms;
}

.MainMenuElementOutOutOutActive>.MainMenuElementOutOut{
    border: 1px solid #ced4d9;
    margin-left: -1px;
    height: 49px;
    border-bottom: none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    margin-top: -4px;
    transition-delay: 300ms;
}

.MainMenuElementOutOutOut>.MainMenuElementOutOut>.MainMenuElementOut{
    height: 47px;
    width: 100%;
    border-top: 0px solid #ffffff;
    border-left: 0px solid #ffffff;
    border-right: 0px solid #ffffff;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    align-items: center;
    transition-delay: 300ms;
    position: relative;
    margin-top: 1px;
    z-index: 5;
}

.MainMenuElementOutOutOut:hover>.MainMenuElementOutOut>.MainMenuElementOut{
    background-color: #f0f5f8;
    height: 49px;
    width: 100%;
    border-top: 1px solid #ffffff;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    align-items: center;
    transition-delay: 300ms;
    position: relative;
    z-index: 5;
}

.MainMenuElementOutOutOut:hover>.MainMenuElementOutOut>.MainMenuElementOut>.MainMenuElement{

}


.MainMenuElementOutOutOutActive>.MainMenuElementOutOut>.MainMenuElementOut{
    background-color: #f0f5f8;
    height: 49px;
    width: 100%;
    border-top: 1px solid #ffffff;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    align-items: center;
    transition-delay: 300ms;
    position: relative;
    z-index: 5;
}

.MainMenuElementOutOutOut:first-of-type:hover{
}

.MainMenuElementOutOutOut:hover>.MainMenuElementOutOut>.MainMenuElementOut>.MainMenuElementBorder{
    border-image: 0;
    border-right: 1px #f0f5f8 solid;
}



.MainMenuElement{
    width: 100%;
    height: 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 15px;
}



.MainMenuElementBorder{
    border-image: url("../../../static/img/MainMenu/MainMenu01.png") 1;
    border-right: solid 1px;
}



.MainMenuElementOutOutOut:hover>.MainMenuSubMenuOut{
    visibility: visible;
    transition-delay: 300ms;
    z-index: 3;
}

.MainMenuElementOutOutOutActive>.MainMenuSubMenuOut{
    visibility: hidden;
    transition-delay: 300ms;
}


.MainMenuSubMenuOut{
    transition-delay: 300ms;
    position: absolute;
    visibility: hidden;
    left: -1px;
    width: 100%;
    margin:auto;
    top: 43px;
    border-bottom: 1px solid #ced4d9;
    border-left: 1px solid #ced4d9;
    border-right: 1px solid #ced4d9;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    /*Если снова поставлю этот элемент - проверить что выпадающее меню О компании перекрывает активный элемент в терминалах z-index: 550;*/
}


.MainMenuSubMenuOutActive{
    visibility: visible;
    position: relative;
    max-width: 1144px;
    margin: auto;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    z-index: 0;
}

.MainMenuSubMenu{
    background-color: #f0f5f8;
    border-bottom: 1px solid #ffffff;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 15px;
    padding-bottom: 10px;
}


.MainMenuSubMenuElement{
    font-weight: 500;
    font-size: 13px;
    color: #0b528c;
    position: relative;
    margin-top: 8px;
}

.MainMenuTradeMenu>div>.MainMenuSubMenuElement{
    margin-top: 0;
}

.MainMenuSubMenuElement>a{
    color: #0b528c;
    padding: 5px 10px 5px 10px;
}

.MainMenuSubMenuElement:hover>a{
    color: #00780b;
}

.MainMenuSubMenuOutLinkActive{
    font-weight: 800;
    padding: 10px;
    color: black;
    text-decoration: none;
    border-radius: 2px;
    box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.15);
    background-color: #fcfcfd;
}

.MainMenuSubMenuElement>.MainMenuSubMenuOutLinkActive:visited{
    font-weight: 800;
    font-size: 17px;
    background-color: white;
    color: black;
}

.MenuwhitePixel{
    height: 1px;
    width: 100%;
    background: white;
    position: relative;
    z-index: -2;
}

.MainMenuTradeMenu{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    padding: 8px 0 12px 13px;
}

.MainMenuTradeMenu>div{

    padding-left: 15px;

}

.MainMenuTradeMenu>.MainMenuTradeMenu01{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-right: 10px;
    grid-row-gap: 8px;
}
.MainMenuTradeMenu>.MainMenuTradeMenu02{
    border-left: 1px solid #a5becd;
    align-items: center;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-row-gap: 8px;
}
.MainMenuTradeMenu03{

}
.MainMenuTradeMenu03{
    display: grid;
    grid-row-gap: 8px;

    flex-wrap: wrap;
    border-left: 1px solid #a5becd;
    align-items: center;
    justify-content: center;
}

.MainMenuTradeMenu01Text{
    color: #797979;
    margin-right: -3px;
    position: relative;
    font-size: 13px;
}

.MainMenuTerminalMenu{
    padding: 8px 0 12px 12px;
    display: flex;
    justify-content: space-around;
}

.MainMenuTerminalMenu>a{
    padding: 10px 15px 10px 15px;
    margin-left: 5px;
}
.MainMenuTerminalMenu>a:hover{
    background-color: white;
}


.MainMenuTerminalMenu>a>div{
    align-items: center;
}
.MainMenuTerminalMenu>a>div>img{
    padding-right: 10px;
}

.MainMenuTerminalMenu>a>.MainMenuSubMenuOutActiveNot{display: flex;}
.MainMenuTerminalMenu>.MainMenuSubMenuTerminalOutLinkActive>.MainMenuSubMenuOutActiveNot{display: none;}
.MainMenuTerminalMenu>a>.MainMenuSubMenuOutActive{display: none;}
.MainMenuTerminalMenu>.MainMenuSubMenuTerminalOutLinkActive>.MainMenuSubMenuOutActive{display: flex;}

.MainMenuSubMenuTerminalOutLinkActive{
    background-color: #cdd4da;
    font-weight: 600;
    border-radius: 4px;
    text-decoration: none;
}

.MainMenuSubMenuOutActiveNot{
    color: #02518e;
}

.MainMenuSubMenuOutActive{
    color: black;
}

.MainMenuElementOutOutOutWebterminal{
    object-fit: contain;
    box-shadow: inset 3px 0 7px 0 rgba(0, 0, 0, 0.36);
    background-image: linear-gradient(to top, #126db9, #318fdd);
    border-top-right-radius: 5px;
    color: #000000;
    /*border: 1px solid #126db9;*/
    min-width: 170px;
}

.MainMenuElement>a{
    text-decoration: none;
}
.MainMenuElementOutOutOutWebterminal>a,.MainMenuElementOutOutOutWebterminal>a:visited{
    color: black;
    text-decoration: none;
}
.MainMenuElementOutOutOutWebterminal>a>.MainMenuElement{
    padding-right: 10px;
}

.MainMenuElementOutOutOutWebterminal>a>.MainMenuElement>img{
    position: relative;
    top: -2px;
    padding-right: 10px;
}

.MainMenuElementRight{
    margin-top: -4px;
}


@media only screen and (max-width: 900px) {
    .MainMenuElement{
        font-size: 12px;
    }
}

@media only screen and (max-width: 700px) {
    .MainMenuElement{
        font-size: 10px;
    }
}

@media only screen and (max-width: 900px) {
    .MainMenuTradeMenu>.MainMenuTradeMenu01{
        padding-left: 0;
        margin-right: 0;
    }
    .MainMenuTradeMenu>.MainMenuTradeMenu02{
        padding-left: 2px;
        padding-right:  2px;
    }
    .MainMenuTradeMenu>.MainMenuTradeMenu03{
        padding-left: 2px;
        padding-right:  2px;
    }
}

@media only screen and (max-width: 840px) {
    .MainMenuTradeMenu>.MainMenuTradeMenu01>div{
        font-size: 11px;}
    .MainMenuTradeMenu>.MainMenuTradeMenu02>div{
        font-size: 11px;}
    .MainMenuTradeMenu>.MainMenuTradeMenu03>div{
        font-size: 11px;}
}

@media only screen and (max-width: 800px) {
    .MainMenu,.MainMenuSubMenu{
        display: none;
    }
    .MainMenuSubMenuOutActive{
        border: 1px solid #ced4d9;
        border-radius: 5px;
        z-index: 2;
    }
    .HeaderAllRightOut{
        justify-content: space-between;
    }
    .HeaderAllRight{
        display: flex;
        width: 100%;
    }
}

.MainMenuElementTwitter>a>img{
    position: relative;
    top: 3px;
    margin-right: 8px;
}