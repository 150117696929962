.ContestIndexMain{
    display: grid;
    grid-template-columns: auto 204px;
}

.ContestIndexMainFlex{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.ContestIndexMainGridElement{
    height: 249px;
    margin-bottom: 29px;
}

.ContestIndexMainGridElementText{
    background-color: #f7f7f7;
    padding: 19px 19px 23px 23px;
    margin-top: -6px;
}
@media only screen and (max-width: 1200px) {
.ContestIndexMain{
    display: block;
}
}
@media only screen and (min-width: 1000px) {

.ContestNowGrid{
    display: grid;
    grid-template-columns: auto 204px;
}
.ContestNowGrid>.ContestMenu {
    grid-column: 2;
    margin-top: 0;
}
.ContestNowGrid>.ContestMenu {
    grid-column: 2;
    margin-top: 0;
}
.ContestNowGrid>.StandartPage {
    grid-column: 1;
    grid-row: 1;
}
}