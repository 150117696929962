.TraderClientOpen{
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 800px) {
    .TraderClientOpen{
        display: block;
    }
}

.TraderClientOpenTopFlex{
    display: flex;
    justify-content: space-between;
}


.OpenRealDocuments{
    border-radius: 4px;
    box-shadow: 1.5px 2.6px 15px 0 rgba(46, 81, 102, 0.1);
    border: solid 2px #8cc845;
    width: 145px;
    height: auto;
    padding: 19px;
}

.OpenRealDocumentsElement{
    display: flex;
}

.OpenRealDocumentsTitle{
    font-size: 15px;
    color: black;
    font-weight: 700;
}

.OpenRealDocumentsElement{
    margin-top: 18px;
}

.OpenRealDocumentsElementImg{
    margin-right: 12px;
    margin-top: 4px;
}

.OpenRealDocumentsElementText>a{
    font-size: 13px;
    color: #7bba31;
    font-weight: 400;
}

.TraderClientOpenMain {
    display: flex;
    justify-content: space-between;
}

.TraderRegistration,.TraderRegistrationSuccess{
    width: 100%;
    padding: 5px;
    border: 1px #d8e4eb solid;
    margin-right: 27px;
    border-radius: 4px;
}


.TraderRegistrationIn{
    background-color: #f8fafc;
    padding-top: 26px;
    padding-bottom: 26px;
}
.TraderRegistrationGridRegistrationFormElement{
    display: grid;
    grid-template-columns: 200px auto;
    margin: 0 26px 24px 26px;
}

.TraderRegistrationGridRegistrationForm>.TraderRegistrationPartPromo{
    margin-top: 24px;
}

.TraderRegistrationGridRegistrationFormElement3{
    display: grid;
    grid-template-columns: 200px 180px auto;
    margin: 0 26px 15px 26px;
}

.TraderRegistrationGridRegistrationFormElementTopTitle{
    width: 200px;
    margin-top: 10px;
    font-size: 15px;
}

.TraderRegistrationGridRegistrationFormElementInput{
    width: 100%;
}

.TraderRegistrationGridRegistrationFormElementInput>input,.TraderRegistrationGridRegistrationFormElementInput>.TraderRegistrationGridPassword>input{
    width: 100%;
    height: 28px;
    font-size: 15px;
    padding-left: 9px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0 1px 3px 0 #171616;
}

.TraderRegistrationGridRegistrationFormElementTopText{
    font-size: 11px;
    color: #898989;
    margin-left: 20px;
    margin-top: 6px;
}

.TraderRegistrationGridRegistrationFormElementTopTextPass{
    margin-top: 0;
    display: flex;
    align-items: center;
}

.TraderRegistrationGridRegistrationFormElementTopTextError{
    margin-top: 6px;
    font-size: 11px;
    color: #da343d;
    display: none;
}

.TraderRegistrationSmallInput{
    max-width: 180px;
}

.TraderRegistrationPasswordOpen,.TraderRegistrationPasswordClose{
    position: absolute;
    right: 5px;
    top: 6px;
}

.TraderRegistrationGridPassword{
    position: relative;
}

.TraderRegistrationGridRegistrationFormElementInput>.TraderRegistrationGridPassword>div>img{
    cursor: pointer;
}
.PasswordNoView>.TraderRegistrationGridRegistrationFormElementInput>.TraderRegistrationGridPassword>div>.TraderRegistrationPasswordOpen{
    display: none;
}
.PasswordView>.TraderRegistrationGridRegistrationFormElementInput>.TraderRegistrationGridPassword>div>.TraderRegistrationPasswordClose{
    display: none;
}

.TraderRegistrationGridRegistrationFormElementTopTextError{
    grid-column: 2/span 3;
}

.ButtonTraderRegistrationPassword{
    font-size: 11px;
    color: #09518e;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: underline dotted;
    display: flex;
    align-items: center;
}

.TraderRegistrationAllPasswordBackground{
    background-color: #f4f7f9;
    padding: 12px 0 12px 0;
}

.TraderRegistrationButtomText{
    font-size: 13px;
    margin-left: 26px;
}

.TraderRegistrationGridRegistrationFormElementError>.TraderRegistrationGridRegistrationFormElementTopTitle{
    color: #da343d;
}
.TraderRegistrationGridRegistrationFormElementError>.TraderRegistrationGridRegistrationFormElementTopTextPassPromo{
    color: #da343d;
    display: block;
}
.TraderRegistrationGridRegistrationFormElementTopTextPassPromo{
    grid-column: 2/span 3;
    margin-left: 0;
    display: none;
}

.TraderRegistrationGridRegistrationFormElementError>.TraderRegistrationGridRegistrationFormElementInput>.TraderRegistrationGridRegistrationFormElementTopTextError,.TraderRegistrationGridRegistrationFormElementError>.TraderRegistrationGridRegistrationFormElementTopTextError {
    display: block;
}
.TraderRegistrationGridRegistrationFormElementError>.TraderRegistrationGridRegistrationFormElementInput>input,.TraderRegistrationGridRegistrationFormElementError>.TraderRegistrationGridRegistrationFormElementInput>.TraderRegistrationGridPassword>input{
    background-color: #fff1f2;
    border-top: 1px solid #beb6b6;
    border-left: 1px solid #beb6b6;
}

.TraderRegistrationSuccess>.TraderRegistrationIn{
    padding: 0;
}

.TraderRegistrationGridRegistrationFormElement  {
    padding-right: 28px;
}

.TraderRegistrationSuccess>.TraderRegistrationIn>.TraderRegistrationInIn>.ButtonTraderRegistration{
    margin-left: 0;
}
.TraderRegistrationSuccess>.TraderRegistrationIn>.TraderRegistrationInIn{
    padding: 26px;
}
.TraderRegistrationSuccessTitle{
    font-size: 23px;
    font-weight: 700;
}

.TraderRegistrationSuccessText{
    font-size: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.TraderRegistrationSuccessPasswordWithdrawal{
    background-color: #eefbdf;
    padding: 26px;
}

.TraderRegistrationSuccessAllInfo{
    margin-top: 15px;
}

.TraderRegistrationSuccessAllInfoElement{
    display: flex;
}

.TraderRegistrationSuccessAllInfoElementText{
    font-weight: 700;
    width: 250px;
    padding-bottom: 20px;
}
.TraderRegistrationSuccessAllInfoOut{
    display: flex;
    justify-content: space-between;
}
.TraderRegistrationSuccessAllInfoPrint>img{
    margin-right: 10px;
}
.TraderRegistrationSuccessAllInfoPrint{
    padding: 18px 18px 18px 19px ;
    max-width: 252px;
    height: min-content;
    text-decoration: underline;
    font-size: 15px;
    color: #3f3e3e;
    border-radius: 5px;
    border: 1px solid #908f8f;
    margin-top: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 1050px) {
    .TraderRegistrationSuccessAllInfoOut{
        display: block;
    }
    .TraderClientOpenTopFlex{
        display: block;
    }
}
@media only screen and (max-width: 800px) {
    .TraderClientOpenMain {
        display: block;
    }
    .OpenRealDocuments{
        margin: auto;
    }
    .TraderRegistration{
        border: none;
        padding: 0;
    }
    .TraderRegistrationIn{
        background-color: unset;
    }
    .OpenRealDocumentsOut{
        display: flex;
    }
}

@media only screen and (max-width: 550px) {
    .TraderRegistrationGridRegistrationFormElement{
        display: block;
    }
    .TraderRegistrationGridRegistrationFormElementTopTitle{
        margin-bottom: 12px;
    }
    .TraderRegistrationGridRegistrationFormElement3{
        grid-template-columns: auto;
    }
    .TraderRegistrationGridRegistrationFormElementTopText{
        grid-row: 2;
        margin-left: 0;
        margin-top: 3px;
        margin-bottom: 3px;
        line-height: 17px;
    }
    .TraderRegistrationGridRegistrationFormElementInput{
        grid-column: 1/span 2;
    }
    .TraderRegistrationSmallInput{
        max-width: unset;
    }
    .TraderRegistrationGridRegistrationFormElement3>.TraderRegistrationGridRegistrationFormElementTopTitle{
        margin-bottom: 0;
        grid-row: 1;
    }
    .TraderRegistrationGridRegistrationFormElement3>.TraderRegistrationGridRegistrationFormElementTopTextError{
        grid-column: 1/span 2;
    }
    .TraderRegistrationGridRegistrationFormElementInput{
        grid-column: 1/span 2;
    }
    .ButtonTraderRegistrationPassword{
        grid-row: 1;
        margin-left: 0;
        position: relative;
        right: 20px;
        top: 5px;
    }
    .ButtonTraderRegistration{
        width: unset;
    }
    .TraderRegistrationAllPasswordBackground>.TraderRegistrationGridRegistrationFormElement3>.TraderRegistrationGridRegistrationFormElementTopTitle{
        padding-bottom: 10px;
    }
    .TraderRegistrationAllPasswordBackground>.TraderRegistrationGridRegistrationFormElement3>.TraderRegistrationGridRegistrationFormElementInput>.TraderRegistrationGridPassword{
        width: 90%;
    }
    .ButtonTraderRegistration{
        max-width: 90%;
        margin: 20px auto auto;
        width: 70%;
        margin-bottom: 20px;
    }

    .TraderClientOpen>.StandartPage>.StandartTitle{
        display: none;
    }
    .TraderRegistrationSuccess{
        border: none;
    }
    .TraderRegistrationSuccess>.TraderRegistrationIn>.TraderRegistrationInIn{
        padding: 0;
    }
    .TraderRegistrationSuccess>.TraderRegistrationIn>.TraderRegistrationInIn>.TraderRegistrationSuccessTitle,
    .TraderRegistrationSuccess>.TraderRegistrationIn>.TraderRegistrationInIn>.TraderRegistrationSuccessAllInfo,
    .TraderRegistrationSuccess>.TraderRegistrationIn>.TraderRegistrationInIn>.TraderRegistrationSuccessText
    {
        padding-left: 20px;
        padding-right: 20px;
    }
    .TraderRegistrationSuccessText{
        line-height: 27px;
    }
    .TraderClientOpen>.StandartPage>.TraderClientOpenMain>.TraderRegistrationSuccess{
        padding: 0;
    }

}

.TraderRegistrationGridRegistrationFormElementInput>.TraderRegistrationGridPassword>input, .TraderRegistrationGridRegistrationFormElementInput>input {
    width: 100%;
    height: 28px;
    font-size: 15px;
    padding-left: 9px;
    border-radius: 2px;
    /* box-shadow: 0 1px 1px 0 rgba(0,0,0,.25), inset 0 1px 3px 0 #171616; */
    border: solid 1px #d8e4eb;
    box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0px 1px 3px 0 #171616;
}

@media print {
    footer,header,.SubMenuClassName,.MainMenuOut,.BlueFooter,.StandartTitle,.OpenRealDocumentsOut,.TraderRegistrationSuccessAllInfoOut>a,.ButtonTraderRegistration,.PlateTop{
        display: none;
    }
}


@media only screen and (max-width: 700px) {
    .StandartPage>.StandartInZeroBig>.Element2BucksHorz {
        display: none;
    }
}