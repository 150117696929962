
.PartnerInvestmentProgramImg{
    background: url("../../../static/img/Partners/Invest/PartnersInvest01.png") right;
    background-color: black;
    background-size: cover;
    height: 320px;
}

.PartnerInvestmentTopGrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 40px;
}

.PartnerInvestmentProgram{
    padding-bottom: 40px;
}


.PartnerInvestmentBlueWhite{
    font-weight: 400;
    background-color: white;
    padding: 15px 45px 15px 15px;
    position: relative;
    margin: 15px;
}

@media only screen and (max-width: 600px) {
    .PartnerInvestmentProgramImg{
        height: 240px;
    }
    .PartnerInvestmentTopGrid{
        grid-template-columns: auto;
    }
    .PartnerInvestmentBlueWhite{
        margin-right: 0;
        margin-left: 0;
    }
    .PartnerInternetBlueOut{
        padding-left: 0;
        padding-right: 0;
    }
    .PartnerInvestmentBlueWhite{
        padding: 30px 20px 30px 20px;
    }

    .PartnerInvestmentProgram>div>.PartnerInternetBlueOut{
        padding: 0;
    }
}

.PartnerInternetBlue>.StandartPageIn{
    padding-left: 35px;
    padding-right: 35px;
}

@media only screen and (max-width: 800px) {
    .PartnerInvestmentProgram>div>.PartnerInternetBlueOut{
        padding: 0;
    }
}