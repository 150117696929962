.IndexNews{
    grid-column: 1/span 4;
    margin-top: 32px;
    margin-bottom: 32px;
    position: relative;
}

.IndexNews>div>a{
    text-decoration: none;
}

.IndexNews:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    left: -1000px;
    right: -1000px;
    -webkit-box-shadow: inset 0 0 59px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: inset 0 0 59px rgba(0, 0, 0, 0.07);
    box-shadow: inset 0 0 59px rgba(0, 0, 0, 0.07);
    background-color: #fefffd;
    z-index: 1;

}

.IndexNewsInNoContest{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    justify-content: space-between;
    width: 100%;
    position: relative;
    z-index: 5;
}
.IndexNewsIn{
    display: flex;
    position: relative;
    z-index: 5;

}
@media only screen and (max-width: 1000px) {
    .IndexNewsInNoContest{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        justify-content: space-between;
    }
    .IndexNewsElement:nth-child(4){
        display: none;
    }
}
@media only screen and (max-width: 800px) {
    .IndexNewsIn{
        display: grid;
        grid-template-columns: auto;
        grid-column-gap: 20px;
        justify-content: space-between;
    }
    .IndexNewsElement:nth-child(3){
        display: none;
    }
}
@media only screen and (max-width: 600px) {
    .IndexNewsIn{
        display: block;
        grid-column-gap: 20px;
        justify-content: space-between;
    }
    .IndexNewsElement:nth-child(2){
        display: none;
    }
}



.IndexNewsElementDate{
    font-weight: 700;
    font-size: 15px;
    color: #181818;
    padding-top: 25px;
    padding-bottom: 10px;
}

.IndexNewsElementTitle{
    font-size: 14px;
}
.IndexNewsElementText{
    font-size: 14px;
    padding-bottom: 20px;
}

.IndexNews>.StandartButtonOut{
    display: none;
}

@media only screen and (max-width: 600px) {
    .IndexNews>.StandartButtonOut{
        display: flex;
    }

}

.IndexNewsPhoneButtonTop{
    position: absolute;
    display: none;
    width: 70px;
    justify-content: space-between;
    z-index: 15;
    top: 26px;
    right: 32px;
}

.IndexNewsPhoneButtonTopElement{
    width: 14px;
    height: 14px;
    /*background-color: #2d508d;*/
    background-color: #6e8cb0;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    z-index: 10;
}

.IndexNewsFirst>.IndexNewsPhoneButtonTop>.IndexNewsPhoneButtonTopElement:first-of-type{
    background-color: #2d508d;
}
.IndexNewsSecond>.IndexNewsPhoneButtonTop>.IndexNewsPhoneButtonTopElement:nth-of-type(2){
    background-color: #2d508d;
}
.IndexNewsThird>.IndexNewsPhoneButtonTop>.IndexNewsPhoneButtonTopElement:nth-of-type(3){
    background-color: #2d508d;
}


@media only screen and (max-width: 600px) {
    .IndexNewsPhoneButtonTop {
        display: flex;
    }
    .IndexNews>.IndexNewsIn>.IndexNewsElement{
        display: none;
    }
    .IndexNewsFirst>.IndexNewsIn>.IndexNewsInNoContest>.IndexNewsElement:nth-of-type(1){
        display: block;
    }
    .IndexNewsSecond>.IndexNewsIn>.IndexNewsInNoContest>.IndexNewsElement:nth-of-type(2){
        display: block;
    }
    .IndexNewsSecond>.IndexNewsIn>.IndexNewsInNoContest>.IndexNewsElement:nth-of-type(1){
        display: none;
    }
    .IndexNewsThird>.IndexNewsIn>.IndexNewsInNoContest>.IndexNewsElement:nth-of-type(3){
        display: block;
    }
    .IndexNewsThird>.IndexNewsIn>.IndexNewsInNoContest>.IndexNewsElement:nth-of-type(1){
        display: none;
    }
}

.IndexNewsInContest{
    width: 371px;
    position: relative;
    z-index: 20;
    /*background-image: url("static/img/Index/newForexstartCup.svg");*/
}
.IndexNewsContestElementIMG{
    position: absolute;
    opacity: 0.1;
    height: 80%;
    right: 10px;
    bottom: 10px;
    z-index: -1;
}
.IndexNewsInContestTitle{
    margin-top: 30px;
    font-size: 14px;
    color: #1e1e1e;
    font-weight: 700;
}
.IndexNewsContestElement{
    margin-top: 14px;
    display: flex;
    flex-wrap: wrap;
}

.IndexNewsElementContestText{
    font-size: 14px;
    color: #525252;
    font-weight: 700;
}

.IndexNewsContestElement>span{
    margin-right: 5px;
}
.IndexNewsContestElement>a{
    font-size: 14px;
}