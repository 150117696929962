.InvestRegistrationOpen{
    display: grid;
    margin-top: 30px;
    grid-template-columns: 546px auto;
    grid-column-gap: 38px;
}

.InvestRegistrationOpenDocumentsTopOut{
    display: flex;
}
.InvestRegistrationOpenDocumentsTop{
    display: flex;
    border-radius: 3px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.6);
    border: solid 1px #e8e8e8;
    background-image: linear-gradient(to top, #e6e6e6, #ffffff);
    padding: 13px 30px 14px 15px;
}

.InvestRegistrationOpenDocumentsTop>img{
    margin-right: 13px;
}
.InvestRegistrationOpenDocumentsTop>div{
    margin-top: 4px;
}

.InvestRegistrationOpenDocumentsTitle{
    font-size: 13px;
    margin-top: 21px;
    color: #898989;
    max-width: 290px;
}

.RegInvestNoVisible{
    display: none;
}

.OpenInvestDocumentsElement{
    margin-top: 24px;
    display: flex;
    font-size: 14px;
    color: #1a8de6;
}

.OpenInvestDocumentsElementImg{
    margin-right: 12px;
}

.OpenInvestDocumentsElementTextPDF{
    color: #858585;
}

@media only screen and (max-width: 800px) {
    .InvestRegistrationOpen{
        display: block;
    }
    .InvestRegistrationOpenDocuments>div{
        padding-left: 40px;
    }
}


.OpenRealDocuments{
    border-radius: 4px;
    box-shadow: 1.5px 2.6px 15px 0 rgba(46, 81, 102, 0.1);
    border: solid 2px #8cc845;
    width: 145px;
    height: auto;
    padding: 19px;
}

.OpenRealDocumentsElement{
    display: flex;
}

.OpenRealDocumentsTitle{
    font-size: 15px;
    color: black;
    font-weight: 700;
}

.OpenRealDocumentsElement{
    margin-top: 18px;
}

.OpenRealDocumentsElementImg{
    margin-right: 12px;
    margin-top: 4px;
}

.OpenRealDocumentsElementText>a{
    font-size: 13px;
    color: #7bba31;
    font-weight: 400;
}

.InvestRegistrationOpenMain {
    display: flex;
    justify-content: space-between;
}

.InvestRegistration,.InvestRegistrationSuccess{
    width: 100%;
    padding: 5px;
    border: 1px #d8e4eb solid;
    margin-right: 27px;
    border-radius: 4px;
}


.InvestRegistrationIn{
    background-color: #f8fafc;
    padding-top: 26px;
    padding-bottom: 26px;
}

.InvestRegistrationGridRegistrationForm{
    background-color: rgba(0,0,0,0.05);
    padding: 34px 46px 26px 44px;
}

.InvestRegistrationGridRegistrationForm>.StandartTitle{
    padding-top: 0;
    font-weight: 400;
    font-size: 36px;
}

.InvestRegistrationGridRegistrationFormElement{
    /*display: grid;*/
    /*grid-template-columns: 200px auto;*/
    margin: 0 26px 24px 26px;
}

.InvestRegistrationGridRegistrationForm>.InvestRegistrationPartPromo{
    margin-top: 24px;
}

.InvestRegistrationGridRegistrationFormElement3{
    display: grid;
    grid-template-columns: 200px 180px auto;
    margin: 0 26px 15px 26px;
}

.InvestRegistrationGridRegistrationFormElementInputIn{
    position: relative;
}

.InvestRegistrationGridRegistrationFormElementInputInput{
    width: calc(100% - 16px);
    height: 46px;
    color: black;
    font-size: 22px;
    padding-left: 14px;
}

.InvestRegistrationGridRegistrationFormElementInputIn>.ButtonInvestRegistrationPassword{
    position: absolute;
    top: 10px;
    right: 10px;
    height: 28px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.47);
    border: solid 1px #e7e7e7;
    background-image: linear-gradient(to top, #e3e3e3, #ffffff);
    color: #898989;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
}

.InvestRegistrationGridRegistrationFormElementTopTitle{
    width: 100%;
    margin-top: 10px;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
}

.InvestRegistrationGridRegistrationForm>.LayoutInvestHeaderButtonElement>.LayoutInvestHeaderButtonElementText,.InvestRegistrationGridRegistrationForm>div>.LayoutInvestHeaderButtonElement>.LayoutInvestHeaderButtonElementText{
    padding-top: 5px;
}
.InvestRegistrationGridRegistrationForm>.LayoutInvestHeaderButtonElement,.InvestRegistrationGridRegistrationForm>div>.LayoutInvestHeaderButtonElement{
    width: 197px;
    height: 46px;
    line-height: 20px;
    cursor: pointer;
    margin: auto;
}

.InvestRegistrationGridRegistrationFormElementTopTitle02{
    font-size: 12px;
}

.InvestRegistrationGridRegistrationFormElementInput{
    width: 100%;
}

.InvestRegistrationGridRegistrationFormElementInput>input,.InvestRegistrationGridRegistrationFormElementInput>.InvestRegistrationGridPassword>input{
    width: 100%;
    height: 28px;
    font-size: 15px;
    padding-left: 9px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0 1px 3px 0 #171616;
}

.InvestRegistrationGridRegistrationFormElementTopText{
    font-size: 11px;
    color: #898989;
    margin-left: 20px;
    margin-top: 6px;
}

.InvestRegistrationGridRegistrationFormElementTopTextPass{
    margin-top: 0;
}

.InvestRegistrationGridRegistrationFormElementTopTextError{
    margin-top: 6px;
    font-size: 11px;
    color: #da343d;
    display: none;
}

.InvestRegistrationSmallInput{
    max-width: 180px;
}

.InvestRegistrationPasswordOpen,.InvestRegistrationPasswordClose{
    position: absolute;
    right: 170px;
    top: 16px;
}

.InvestRegistrationGridPassword{
    position: relative;
}

.InvestRegistrationGridRegistrationFormElementInput>.InvestRegistrationGridPassword>div>img{
    cursor: pointer;
}
.PasswordNoView>.InvestRegistrationGridRegistrationFormElementInput>div>div>.InvestRegistrationPasswordOpen{
    display: none;
}
.PasswordView>.InvestRegistrationGridRegistrationFormElementInput>div>div>.InvestRegistrationPasswordClose{
    display: none;
}

.InvestRegistrationGridRegistrationFormElementTopTextError{
    grid-column: 2/span 3;
}

.ButtonTraderRegistrationPassword{
    font-size: 11px;
    color: #09518e;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: underline dotted;
}

.InvestRegistrationAllPasswordBackground{
    background-color: #f4f7f9;
    padding: 12px 0 12px 0;
}

.InvestRegistrationButtomText{
    margin-left: -2px;
    margin-top: 14px;
    font-size: 12px;
    color: #595959;
}

.TraderRegistrationGridRegistrationFormElementError>div>div>.InvestRegistrationGridRegistrationFormElementInputInput,.InvestRegistrationGridRegistrationFormElementError>div>.InvestRegistrationGridRegistrationFormElementInputInput{
    border: #e08084 1px solid;
    background-color: #fff5f5;
}
.InvestRegistrationGridRegistrationFormElementError>div>div>.InvestRegistrationGridRegistrationFormElementTopTextError,.InvestRegistrationGridRegistrationFormElementError>div>.InvestRegistrationGridRegistrationFormElementTopTextError{
    color: #da343d;
    display: block;
}

.InvestRegistrationGridRegistrationFormElementError>.InvestRegistrationGridRegistrationFormElementInput>.InvestRegistrationGridRegistrationFormElementTopTextError,.InvestRegistrationGridRegistrationFormElementError>.InvestRegistrationGridRegistrationFormElementTopTextError {
    display: block;
}
.InvestRegistrationGridRegistrationFormElementError>.InvestRegistrationGridRegistrationFormElementInput>input,.InvestRegistrationGridRegistrationFormElementError>.InvestRegistrationGridRegistrationFormElementInput>.InvestRegistrationGridPassword>input{
    background-color: #fff1f2;
    border-top: 1px solid #beb6b6;
    border-left: 1px solid #beb6b6;
}

.InvestRegistrationSuccess>.InvestRegistrationIn{
    padding: 0;
}

.InvestRegistrationGridRegistrationFormElement  {
    padding-right: 28px;
}

.InvestRegistrationSuccess>.InvestRegistrationIn>.InvestRegistrationInIn>.ButtonTraderRegistration{
    margin-left: 0;
}
.InvestRegistrationSuccess>.InvestRegistrationIn>.InvestRegistrationInIn{
    padding: 26px;
}
.InvestRegistrationSuccessTitle{
    font-size: 23px;
    font-weight: 700;
}

.InvestRegistrationSuccessText{
    font-size: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.InvestRegistrationSuccessPasswordWithdrawal{
    background-color: #eefbdf;
    padding: 26px;
}

.InvestRegistrationSuccessAllInfo{
    margin-top: 15px;
}

.InvestRegistrationSuccessAllInfoElement{
    display: flex;
}

.InvestRegistrationSuccessAllInfoElementText{
    font-weight: 700;
    width: 250px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 800px) {
    .InvestRegistrationOpenMain {
        display: block;
    }
    .OpenRealDocuments{
        margin: auto;
    }
    .InvestRegistration{
        border: none;
        padding: 0;
    }
    .InvestRegistrationIn{
        background-color: unset;
    }
}

@media only screen and (max-width: 550px) {
    .InvestRegistrationGridRegistrationFormElement{
        display: block;
    }
    .InvestRegistrationGridRegistrationFormElementTopTitle{
        margin-bottom: 12px;
    }
    .InvestRegistrationGridRegistrationFormElement3{
        grid-template-columns: auto;
    }
    .InvestRegistrationGridRegistrationFormElementTopText{
        grid-row: 2;
        margin-left: 0;
        margin-top: 3px;
        margin-bottom: 3px;
        line-height: 17px;
    }
    .InvestRegistrationGridRegistrationFormElementInput{
        grid-column: 1/span 2;
    }
    .InvestRegistrationSmallInput{
        max-width: unset;
    }
    .InvestRegistrationGridRegistrationFormElement3>.InvestRegistrationGridRegistrationFormElementTopTitle{
        margin-bottom: 0;
        grid-row: 1;
    }
    .InvestRegistrationGridRegistrationFormElement3>.InvestRegistrationGridRegistrationFormElementTopTextError{
        grid-column: 1/span 2;
    }
    .InvestRegistrationGridRegistrationFormElementInput{
        grid-column: 1/span 2;
    }
    .ButtonTraderRegistrationPassword{
        grid-row: 1;
        margin-left: 0;
        position: relative;
        right: 20px;
        top: 5px;
    }
    .ButtonTraderRegistration{
        width: unset;
    }
    .InvestRegistrationAllPasswordBackground>.InvestRegistrationGridRegistrationFormElement3>.InvestRegistrationGridRegistrationFormElementTopTitle{
        padding-bottom: 10px;
    }
    .InvestRegistrationAllPasswordBackground>.InvestRegistrationGridRegistrationFormElement3>.InvestRegistrationGridRegistrationFormElementInput>.InvestRegistrationGridPassword{
        width: 90%;
    }
    .ButtonTraderRegistration{
        max-width: 90%;
        margin: 20px auto auto;
        width: 70%;
        margin-bottom: 20px;
    }

    .InvestRegistrationOpen>.StandartPage>.StandartTitle{
        display: none;
    }
    .InvestRegistrationSuccess{
        border: none;
    }
    .InvestRegistrationSuccess>.InvestRegistrationIn>.InvestRegistrationInIn{
        padding: 0;
    }
    .InvestRegistrationSuccess>.InvestRegistrationIn>.InvestRegistrationInIn>.InvestRegistrationSuccessTitle,
    .InvestRegistrationSuccess>.InvestRegistrationIn>.InvestRegistrationInIn>.InvestRegistrationSuccessAllInfo,
    .InvestRegistrationSuccess>.InvestRegistrationIn>.InvestRegistrationInIn>.InvestRegistrationSuccessText
    {
        padding-left: 20px;
        padding-right: 20px;
    }
    .InvestRegistrationSuccessText{
        line-height: 27px;
    }
    .InvestRegistrationOpen>.StandartPage>.InvestRegistrationOpenMain>.InvestRegistrationSuccess{
        padding: 0;
    }

}

.InvestRegistrationGridRegistrationFormElementInput>.InvestRegistrationGridPassword>input, .InvestRegistrationGridRegistrationFormElementInput>input {
    width: 100%;
    height: 28px;
    font-size: 15px;
    padding-left: 9px;
    border-radius: 2px;
    /* box-shadow: 0 1px 1px 0 rgba(0,0,0,.25), inset 0 1px 3px 0 #171616; */
    border: solid 1px #d8e4eb;
    box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0px 1px 3px 0 #171616;
}