.cabinet_header {grid-column: 5 / span 2;
    grid-row: 2;
    justify-self: end;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    margin-top: 31px;}

.cabinet_header>img{
    display: none;
}

.HeaderAllExit{
    display: none;
}
.HeaderAllRightLK>div{
    display: flex;
}
.HeaderAllRightLK>div>div>img{
    margin-right: 10px;
}
.HeaderAllRightLKText{
    display: flex;
}

.ButtonTransparencyInImgExit>img{
    margin-left: 10px;
}

.header_key{
    display: flex;
    height: 15px;
    line-height: 15px;
    cursor: pointer;
}
.header_key_src{
    margin-top: -5px;
    margin-right: 3px;
}

.for_cab{
    display: block;
    background-color : #000;
    height : 100%;
    left : 0;
    opacity : 0.50;
    position : fixed;
    top : 0;
    width : 100%;
    z-index : 100;}

.cab_in{
    padding: 0 0;
    position: fixed;
    z-index: 500;
    top: 130px;
    right: 50px;
    margin: auto;
    /*height : 360px;*/
    /*width : 380px;*/
    background-color: #E5ECFD;
    display: block;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto;
    justify-content: space-between;
    align-items: center;}


.login_cab>h2{font-family: Roboto, sans-serif;
    font-size: 15px;}
.pass_cab>h2{font-family: Roboto, sans-serif;
    font-size: 15px;}
.up_cab>h1{font-family: Roboto, sans-serif;
    font-size: 21px;}
.pass_me>h2{font-family: Roboto, sans-serif;
    font-size: 15px;
    color: #218ed2;}

.up_cab{
    grid-column: 1/ span 2; grid-row: 1;}
.x_close{font-family: Roboto, sans-serif;
    font-size: 21px;
    color: blue;
    grid-column: 2; grid-row: 1;
    justify-content: space-between;
    text-align: right;}
.login_cab{
    grid-column: 1; grid-row: 2;}
.login_enter_cab{
    grid-column: 2; grid-row: 2;}
.login_input_cab{
    width: 204px;
    height: 43px;}
.pass_input_cab{
    width: 204px;
    height: 43px;}
.pass_cab{grid-column: 1; grid-row: 3;}
.pass_enter_cab{grid-column: 2; grid-row: 3;}
.pass_me{grid-column: 1; grid-row: 4;}
.button_enter_cab{grid-column: 2; grid-row: 4;}

@media only screen and (max-width: 950px) {
    .cabinet_header {margin-top: 15px;}
}
@media only screen and (max-width: 650px) {
     .cabinet_header {grid-column: 2; grid-row: 1;
     }

}

.EnterCabinetLeft{
    height: 39px;
    margin-top: -3px;
}
.EnterCabinetRight{
    height: 39px;
}

@media only screen and (max-width: 500px) {
    .header_key{
        display:none;
    }
    .cabinet_header{
        margin-top: 0;
        grid-column: 4;
    }
    .cabinet_header>img{
        display: block;
    }
    .cab_in{
        right: calc(50% - 193px);
    }
}

.EnterCabinetLeft{
    /*background-image: url("../../../../../static/img/HeaderAll/smallAuthLeftOut.svg");*/
    display: flex;
    align-items: center;
}
.EnterCabinetRight{
    /*background-image: url("../../../../../static/img/HeaderAll/smallAuthRightOut.svg");*/
    /*height: 32px;*/
    /*width: 35px*/
    display: flex;
    align-items: center;
}

.EnterCabinet>.StandartPhone{
    display: none;
}

@media only screen and (max-width: 500px) {
    .EnterCabinet>.StandartPhone{
        display: flex;
    }

    }

@media only screen and (max-width: 440px) {
    .HeaderAllRightLKText{
        display: none;
    }

    .HeaderAllRightLK>div>div>img{
        margin-right: 0;
    }
    .HeaderAllLockOut{
        display: flex;
        justify-content: center;
    }
}
@media only screen and (max-width: 400px) {

    .cab_in{
        right: 0;
        width: calc(100%);
        padding-left: 0;
        padding-right: 0;
    }
    .iFrameCabinetLinks{
        font-size: 10px;
    }
}


.iFrameCabinetCrest{
    position: absolute;
    right: 40px;
    top: 28px;
    cursor: pointer;
}

.iFrameCabinetLinks{
    position: absolute;
    z-index: 2;
    right: 20px;
    bottom: 20px;}

.iFrameCabinetLinksElement{
    margin-bottom: 5px;
}


.HeaderAllLockPlate{
    display: none;
}

.CabinetInAutorizationIn{
    display: flex;
    align-items: center;
    cursor: pointer;
}

/*.HeaderAllRightLK>.HeaderAllRightLKPhone{*/
/*    display: none;*/
/*}*/

@media only screen and (max-width: 500px) {
    .HeaderAllRightLK>.HeaderAllRightLKText{
        display: none;
    }
    .EnterCabinetLeft{
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        box-shadow: 5px 0 7px 0 rgba(0, 0, 0, 0.1);
        border: solid 2px #0a56d0;
        background-image: linear-gradient(to top, #3f86d4, #3065c5);
    }
    .EnterCabinetRight{
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-right-radius: 18px;
        border-top-right-radius: 18px;
        background-image: linear-gradient(to top, #aba5d8, #b8ade8);
        border-top: solid 2px white;
        border-bottom: solid 4px white;

    }

}