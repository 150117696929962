.IndexSpreads{
    width: 275px;
    height: 251px;
    object-fit: contain;
    border: solid 1px rgba(200, 217, 227, 0.7);
    grid-column: 2;
    background-color: white;
}

.IndexSpreadsTitle{
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    color: #1b1b1b;
    margin-top: 18px;
}

.IndexSpreadsGrid{
    display: flex;
    justify-content: space-around;
    font-size: 11px;
    margin-top: 14px;
    margin-bottom: 15px;
}

.IndexSpreadsGraph{
    margin: auto;
    display: flex;
    justify-content: center;
}

.IndexSpreadsGridElement{
    color: #2f4563;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 14px;
    margin-bottom: 15px;
}

.IndexSpreadsGridElementActive{
    text-decoration: none;
    color: black;
    font-weight: 700;
    cursor: default;
}