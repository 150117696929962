.AboutTravelContestsTop{
    display: grid;
    grid-template-columns: auto 260px;
}

.AboutTravelContest{
    position: relative;
}

.AboutTravelContest>div>.AboutTravelContests>.AboutTravelContestsTop,.AboutTravelContest>div>.StandartTitle{
    padding-left: 25px;
}

.AboutTravelContestsTopLeftTitle{
    font-weight: 600;
    font-size: 19px;
    padding-bottom: 16px;
}

.AboutTravelContestsTopLeftText{
    font-size: 15px;
    line-height: 27px;
}
.AboutTravelContestsTopLeftGrid{
    display: flex;
    margin-top: 40px;
}

.AboutTravelContestsTopLeftGridElement{
    display: flex;
}

.AboutTravelContestsTopLeftGridElement:first-of-type{
    padding-right: 23px;
    border-right: 1px solid #c3c3c3;
}

.AboutTravelContestsTopLeftGridElement:nth-of-type(2){
    padding-left: 23px;
    padding-right: 23px;
    border-right: 1px solid #c3c3c3;

}

.AboutTravelContestsTopLeftGridElement:nth-of-type(3){
    padding-left: 23px;
}


.AboutTravelContestsTopLeftGridTitle {
    font-size: 21px;
    line-height: 23px;
    color: #1d6095;
    font-weight: 700;
}
.AboutTravelContestsTopLeftGridText {
    font-size: 15px;
    line-height: 23px;
}

.AboutTravelContestsTopFlag>img{
    position: relative;
    top: -50px;
}

@media only screen and (max-width: 900px) {
    .AboutTravelContestsTopFlag{
        display: none;
    }
}
.AboutTravelContestsTop{
    display: flex;
}

.AboutTravelContestsTopLeftGridElementImg{
    display: none;
}

@media only screen and (max-width: 800px) {
    .AboutTravelContestsTopLeftGridElementImg{
        display: block;
    }
    .AboutTravelContestsTopLeftGridElementImg>img{
        padding-right: 11px;
    }
    .AboutTravelContestsTopLeftGrid>.AboutTravelContestsTopLeftGridElement{
        border-right: none;
    }
    .AboutTravelContestsTopLeftGridElement>div>.AboutTravelContestsTopLeftGridText{
        font-size: 13px;
        color: #00427e;
    }
    .AboutTravelContestsTopLeft{
        display: grid;
    }
    .AboutTravelContestsTopLeftText{
        text-align: center;
        grid-row: 3;
    }
    .AboutTravelContestsTopLeftGrid{
        grid-row: 2;
        margin-bottom: 30px;
    }
    .AboutTravelContestsTop{
        display: grid;
        grid-template-columns: auto;
    }
}

@media only screen and (max-width: 600px) {

    .AboutTravelContestsTopLeftGrid {
        display: block;
    }
    .AboutTravelContestsTopLeftGrid>.AboutTravelContestsTopLeftGridElement{
        padding-right: 0;
        padding-left: 0;
    }
    .AboutTravelContestsTopLeftGridElement{
        justify-content: flex-start;
        margin-bottom: 20px;
    }
}
.AboutTravelGrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    grid-column-gap: 50px;
    margin-bottom: 50px;
}

@media only screen and (max-width: 980px) {
    .AboutTravelGrid{
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (max-width: 650px) {
    .AboutTravelGrid{
        display: block;
    }
    .AboutTravelGrid>div{
        margin: auto;
    }
}

.AboutTravelGridElement{
    position: relative;
    width: 282px;
}

.AboutTravelGridImgTravel{
    position: absolute;
    top: 20px;
    left: 27px;
}

.AboutTravelGridText{
    position: absolute;
    bottom: 28px;
    width: 100%;
    text-align: center;
    font-size: 15px;
}

.AboutTravelContestMap{
    width: 100%;
    height: 300px;
}

.AboutTravelContest>.StandartPage{
    position: relative;
    z-index: 100;
}

.AboutTravelContestTop{
    width: 100%;
    background: url("../../../static/img/About/Travel/AboutTravelGridTop.svg") repeat-x;
}

.AboutTravelGridBottom>img{
    margin: auto;
    width: 100%;
    max-width: 1140px;
}
.AboutTravelGridBottom{
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #c2d7e7;
}
.AboutTravelGridOut{
    background-color: #c2d7e7;
}

.AboutTravelGridElement{
    cursor: pointer;
}

.AboutTravelContestBigPictures{
}

.AboutTravelContestBigPicturesText{
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    color: #0b528c;
    cursor: pointer;
    text-decoration: underline;
}

/*.ActiveSlider>.AboutTravelContestBigPicturesText{*/
/*    display: none;*/
/*}*/


.thumb{
    cursor: pointer;
}
.selected{
    cursor: unset;
}
.carousel-root{
    display: none;
    /*padding: 50px;*/
}
.ActiveSlider{
    display: flex;
    justify-content: center;
}
.ActiveSlider>.carousel-root>.carousel-slider{
    width: auto;
    background-color: rgba(256,256,256,0.01);
}
.ActiveSlider>.carousel-root>.carousel-slider>.slider-wrapper>.slider{
    transform: none !important;
    display: flex;
    justify-content: center;
}
.ActiveSlider>.carousel-root>.carousel-slider>.slider-wrapper>.slider>.slide{
    display: none;
    min-width: auto;
}
.ActiveSlider>.carousel-root>.carousel-slider>.slider-wrapper>.slider>.selected{
    display: block;
}
.ActiveSlider>.carousel-root>.carousel-slider>.slider-wrapper>.slider>.selected>div>.legend{
    opacity: 0.9;
}
.ActiveSlider>.carousel-root>.carousel-slider{
    max-height: 100%;
}
.ActiveSlider>.carousel-root>.carousel-slider>.slider-wrapper{
    max-height: 100%;
}
.ActiveSlider>.carousel-root>.carousel-slider>.slider-wrapper>.slider{
    max-height: 100%;
}
.ActiveSlider>.carousel-root>.carousel-slider>.slider-wrapper>.slider>.slide{
    max-height: 100%;
}
.ActiveSlider>.carousel-root>.carousel-slider>.slider-wrapper>.slider>.slide>div{
    max-height: 100%;
}
.ActiveSlider>.carousel-root>.carousel-slider>.slider-wrapper>.slider>.slide>div>img{
    height: 50%;
    /*width: auto;*/
    height: auto;
    max-width: 100%;
    max-height: 60%;
}
.ActiveSlider>.carousel-root{
    display: block;
    position: fixed;
    top: 50px;
    /*width: calc(80% - 20px);*/
    max-height: 80%;
    max-width: 90%;
    /*width: 1000px;*/
    /*height: 20%;*/
    margin: auto;
    z-index: 1000;
}

.NoActiveSlider>.StandartAllBlack500{
    display: none;
}

.carousel{
    background-color: white;

}