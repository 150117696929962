

.ComponentResultSmallTD01{
    width: 100px;
    height: 60px;
    background-size: cover;
    margin-right: 22px;
    border-bottom: 34px solid white;
    border-right: 27px solid white;
}

.ComponentResultSmallTD01star{
    background-image: url("../../../../../static/img/Trader/Contest/ForArchive/star.jpg");
}
.ComponentResultSmallTD01autumn{
    background-image: url("../../../../../static/img/Trader/Contest/ForArchive/autumn.jpg");
}
.ComponentResultSmallTD01china,.ComponentResultSmallTD01ContestChina{
    background-image: url("../../../../../static/img/Trader/Contest/ForArchive/china.jpg");
}
.ComponentResultSmallTD01daily,.ComponentResultSmallTD01ContestDaily{
    background-image: url("../../../../../static/img/Trader/Contest/ForArchive/daily.jpg");
}
.ComponentResultSmallTD01demo_test{
    background-image: url("../../../../../static/img/Trader/Contest/ForArchive/demo_test.jpg");
}
.ComponentResultSmallTD01forex_start{
    background-image: url("../../../../../static/img/Trader/Contest/ForArchive/forex_start.jpg");
}
.ComponentResultSmallTD01pirate_bay{
    background-image: url("../../../../../static/img/Trader/Contest/ForArchive/pirate_bay.jpg");
}
.ComponentResultSmallTD01try_play{
    background-image: url("../../../../../static/img/Trader/Contest/ForArchive/try_play.jpg");
}
.ComponentResultSmallTD01tsar_hill{
    background-image: url("../../../../../static/img/Trader/Contest/ForArchive/tsar_hill.jpg");
}

.ComponentResultSmallTD02{
    display: flex;
    flex-direction: column;
}

.ComponentResultSmallTD03>div{
    position: relative;
    top: -20px;
}


.ComponentResultSmallTD02Title{
    font-size: 15px;
    line-height: 23px;
}

.ComponentResultSmallTD02Time{
    font-size: 18px;
}

.ComponentResultSmallTable,.ComponentResultSmallTable>tbody,.ComponentResultSmallTable>tbody>tr{
    width: 100%;
}

.ComponentResultSmallTD03{
    font-size: 12px;
    color: #aeaeae;
    font-weight: 700;
}

@media only screen and (max-width: 1000px) {
    .TraderAllArchiveMain{
        grid-template-columns: auto;
    }
}