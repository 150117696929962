.TerminalComponent3IMGCircles>div{
    cursor: pointer;

    margin-left: 9px;
    display: inline-block;
    width: 10px;
    height: 10px;

    border-radius: 50%;}

.BlockNewsChangeMarked3Img{
    background: #2f4563;
}


.BlockNewsChangeMarked3ImgNo{
    background: #e9eaea;
}

.TerminalComponent3IMGCircles{
    display: flex;
    justify-content: center;
    margin-top: 33px;
    margin-bottom: 100px;
}

.TerminalComponent3IMGImage{
    width: 460px;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 0;
}


@media only screen and (max-width: 700px) {
    .TerminalComponent3IMGImage{
        width: auto;
    }
    .TerminalComponent3IMGCircles{
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 430px) {
    .TerminalComponent3IMGImage,.TerminalComponent3IMGImage>img{
        width: 100%;
        object-fit: contain;
    }
    .TerminalComponent3IMGImage{
        height: auto;
        display: block;
    }
}

