.TraderBonusSystem>.StandartPage{
    background: url("../../../static/img/Trader/Bonus/TraderBonus01.png") no-repeat center;
    background-position-y: 20px;
    background-size: 100% auto;
    width: 100%;
    min-height: 1000px;
}


.TraderBonusSystem01{
    max-width: 700px;
    padding-left: 10%;
    padding-right: 10%;
}

.TraderBonusSystem02{
    max-width: 500px;
    padding-top: 30px;
    padding-left: 10%;
    padding-right: 10%;
}

.TraderBonusSystem03{
    position: relative;
    left: 60%;
    width: 40%;
    padding-top: 150px;
}

.TraderBonusSystem03Href{
    display: inline-flex;
    height: 51px;
    padding-left: 21px;
    padding-right: 21px;
    background-color: white;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 10px;
}

.TraderBonusSystem03Href2{
    font-style: italic;
}

.TraderBonusSystemSet{
    font-weight: 700;
    color: #ba3923;
}

.TraderBonusSystemTopPhone{
    display: none;
    height: 240px;
}

@media only screen and (max-width: 600px) {

    .TraderBonusSystemTopPhone{
        display: block;
        height: 240px;
        width: 100%;
        background: url("../../../static/img/Trader/Bonus/TraderBonus02.png") left;
        background-size: cover;
    }
    .TraderBonusSystem{
        background: none;
        height: auto;
    }
    .TraderBonusSystem02,.TraderBonusSystem01,.TraderBonusSystem03{
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 28px;
    }
    .TraderBonusSystem>.StandartPage{
        background-image: none;
    }
    .TraderBonusSystem03{
        left: 0;
        width: auto;
        padding-top: 0;
    }
    .TraderBonusSystem03>.StandartTitle{
        padding-top: 10px;
        padding-bottom: 10px;
    }
}