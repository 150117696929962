.ContestGridWithMenu{
    display: flex;
    justify-content: space-between;

}

.ContestIndexMain>.ContestMenu{
    margin-top: 0;
}
.ContestMenu{
    width: 204px;
    border-radius: 3px;
    margin-top: 78px;
}

.ContestMenuIn{
    width: 186px;
    padding: 9px;
    border: #e6f0f6 solid 1px;
    border-radius: 4px;
}


.ContestMenuElement>a:visited{
    color: inherit;
}

.ContestMenuElement>a{
    width: 100%;
    height: 127px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.ContestMenuLinkActive>a{
    background-color: #e6f0f6;
    text-decoration: none;
    color: black;
}

.ContestMenuElementIMGActive{
    display: none;
}
.ContestMenuLinkActive>a>.ContestMenuElementIMGActive{
    display: block;
}
.ContestMenuLinkActive>a>.ContestMenuElementIMG{
    display: none;
}

.ContestMenuElementText{
    color: #02518e;
    text-decoration: underline;
}

.ContestMenuLinkActive>a>.ContestMenuElementText{
    text-decoration: none;
    color: black;
}


@media only screen and (max-width: 1200px) {
    .ContestIndexMain>.ContestMenu{
        display: none;
    }
}
@media only screen and (max-width: 1000px) {

    .ContestGridWithMenu{
        display: grid;
    }
    .ContestMenu{
        /*display: none;*/
        grid-row: 1;
        /*display: flex;*/
        width: auto;
        margin-top: 10px;
    }
    .ContestMenuIn{
        width: auto;
        display: flex;
        flex-wrap: wrap;
    }
    .ContestMenuElement{
        width: auto;
        min-width: 159px;
        border: 1px solid #02518e;
        margin-bottom: 10px;
    }
    .ContestMenuIn{
        border: none;
        width: 100%;
        justify-content: center;
    }
}
@media only screen and (max-width: 1000px) {

    .ContestMenuIn{
        justify-content: space-around;
    }
}

.PhoneUpDown{
    display: none;
}
@media only screen and (max-width: 1000px) {
    .ContestMenuElement{
        margin-left: 20px;
        margin-right: 20px;
    }
}
@media only screen and (max-width: 1040px) {
    .ContestMenu{
        margin-left: 25px;
        margin-right: 25px;
    }
}

.ContestMenuClose{
    display: none;
}

@media only screen and (max-width: 600px) {
    .ContestMenuElement{
        display: none;
    }
    .ContestMenuLinkActive{
        display: block;
        width: 80%;
    }
    .ContestMenuLinkActive>a>img{
        width: 29px;
    }
    .ContestMenuLinkActive>a{
        flex-direction: initial;
        height: 78px;
    }
    .ContestMenuElementText{
        font-size: 23px;
    }
    .ContestMenuIn{
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
    }
    .PhoneUpDown{
        display: block;
        margin-left: 3px;
        cursor: pointer;
    }
    .MenuPhoneOpen>.ContestMenuIn{

    }
    .MenuPhoneOpen>.ContestMenuIn>.ContestMenuElement{
        display: block;
        width: 80%;
    }
    .MenuPhoneOpen>.ContestMenuIn>.ContestMenuElement>a{
        display: grid;
        grid-template-columns: 70px auto;
        justify-content: flex-start;
        height: 78px;
        text-decoration: none;
    }
    .MenuPhoneOpen>.ContestMenuIn>.ContestMenuElement>a>img{
        height: 42px;
        justify-self: center;
    }
    .MenuPhoneOpen>.ContestMenuIn>.ContestMenuElement>a>div{
        text-decoration: none;
    }
    .MenuPhoneOpen>.ContestMenuIn{
        flex-direction: column;
    }
    .MenuPhoneOpen>.ContestMenuIn>.ContestMenuElement{
        margin-bottom: 0;
        border: none;
    }
    .MenuPhoneOpen{
        border-right: 1px solid #02518e;
        border-left: 1px solid #02518e;
        border-bottom: 4px solid #02518e;
        border-top: 4px solid #02518e;
        display: flex;
    }
    .MenuPhoneOpen>.ContestMenuIn>div>.PhoneUpDown{
        display: none;
    }
    .MenuPhoneOpen>div>.ContestMenuClose{
        display: block;
        margin-top: 20px;
        margin-right: 20px;
        cursor: pointer;
    }
    .MenuPhoneOpen>.ContestMenuIn>.ContestMenuLinkActive>a{
        background-color: unset;
    }
}


@media only screen and (max-width: 500px) {
    .ContestMenu{
        margin-right: 0;
        margin-left: 0;
    }
    .ContestMenuElementText{
        font-size: 18px;
    }
    .ContestMenuElement{
        margin-left: 0;
    }
    .ContestMenuIn{
        margin: auto;
    }
}
@media only screen and (max-width: 350px) {

}