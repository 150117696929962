.html404{
    display: grid;
    grid-template-columns: 284px auto;
    margin-bottom: 24px;
    margin-left: 38px;
    margin-right: 38px;
    margin-top: 20px;
}

.html404TopIMGOut{
    padding-left: 15px;
    padding-right: 15px;
}
.html404TopIMG{
    max-width: 1020px;
    margin: auto;
}

.html404TopIMG{
    width: 100%;
}

.html404TopText{
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    padding-top: 27px;
    color: #2d2d2d;
}

.html404TopTextInput{
    display: flex;
    flex-wrap: wrap;
}


.html404TopText01{
    padding-bottom: 30px;
}

.html404TopText02{
    padding-bottom: 16px;
}

.html404Element{
    padding-bottom: 25px;
    border-bottom: #eff2f4 1px solid;

}
.html404TopTextInput>.FindForm{
    width: auto;
    margin-bottom: 20px;
    padding-left: 22px;
    border: solid #d7d8dc 1px;
    outline: none;
    height: 26px;
    margin-right: 26px;
}





.html404MapIn>.FooterAllPart{
    padding-right: 20px;
    padding-bottom: 24px;
}

.html404MapIn>div>.MainMenuElementTitle{
    font-size: 24px;
}
.html404MapIn>div>div>.FooterAllPartItem{
    font-size: 24px;
    padding-top: 10px;
}

.html404MapIn{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media only screen and (max-width: 1415px) {
    .html404MapIn{
        grid-template-columns: auto auto auto auto;
    }
    .FooterAllPart:nth-last-of-type(1){
        grid-column: 3;
    }
}
@media only screen and (max-width: 1015px) {
    .html404MapIn{
        grid-template-columns: auto auto auto;
    }
    .FooterAllPart:nth-last-of-type(1){
        grid-column: inherit;
    }
}

@media only screen and (max-width: 875px) {
    .html404MapIn {
        grid-template-columns: auto auto;
    }
}

@media only screen and (max-width: 576px) {
    .html404MapIn {
        grid-template-columns: auto;
    }
}

.html404Map>.StandartTitle{
    padding-top: 31px;
    padding-bottom: 20px;
}

.html404Map>.StandartText{
    padding-bottom: 20px;
}


.html404TopTextInput>.ButtonRed{
    height: 26px;
    line-height: 24px;
    margin-top: -5px;
    font-size: 17px;
    font-weight: 400;
}


.html404TopText>.FindForm:focus{
    opacity: 1;
    color: black;
}

.html404FlexButtons{
    display: flex;
}

.html404>.StandartH2{
    padding-top: 36px;
}

.html404MainGridElementImg{
    height: 100px;
}

.html404MainGrid{
    display: grid;
    grid-gap: 30px;
    margin-top: 53px;
    padding-bottom: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto;
}

.html404MainGridElement05{
    grid-column: 1/span 2;
}

.html404MainGridElement06{
    grid-column: 3/span 4;
}

.html404MainGridOut{
    border-bottom: #eff2f4 1px solid;
}

.html404BottomGrid{
    padding-top: 32px;
    padding-bottom: 35px;
    display: grid;
    grid-template-columns: 28px auto;
    grid-row-gap: 8px;
}

.html404BottomGridImg{
    margin-right: 9px;
}

.html404BottomGridText{
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    padding-left: 9px;
    color: #353b50;
}

.html404BottomFeedback{
    margin-top: 39px;
    margin-bottom: 30px;
    border: 2px solid #eff2f4;
    display: grid;
    grid-template-columns: 127px auto;
}

.html404BottomFeedback>img,.html404BottomFeedback>div{
    margin: 35px 44px 35px 0;
    position: relative;
    right: 2px;
}

.html404BottomFeedback>.CompanyContactsTop{
    margin-top: 0;

}

.html404BottomFeedbackText{
    font-weight: 400;
    font-size: 19px;
    line-height: 27px;
    padding-left: 9px;
    color: #353b50;
}

@media only screen and (max-width: 900px) {
    .html404MainGrid{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .html404MainGridElement05{
        grid-column: auto;
    }

    .html404MainGridElement06{
        grid-column: auto;
    }
}

@media only screen and (max-width: 600px) {
    .html404MainGrid{
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 400px) {
    .html404MainGrid{
        grid-template-columns: auto;
    }
}

@media only screen and (max-width: 700px) {
    .html404BottomFeedback{
        grid-template-columns: auto;
        text-align: center;
        justify-content: center;
        border: none;
    }
    .html404BottomFeedback>img{
        margin: auto;
    }
}



.html404TopTitle{
    font-size: 70px;
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 15px;
}

.html404TopTitle02{
    font-size: 48px;
    font-weight: 300;
    margin-top: 40px;
    margin-bottom: 15px;
}

.html404TopText>a{
    margin-left: 5px;
    margin-right: 5px;
    color: black;
}
.html404TopText>b{
    margin-left: 5px;
    margin-right: 5px;
    color: black;
}

.html404TopButtons{
    display: flex;
    flex-wrap: wrap;
}
.html404TopButtons>a{
    text-decoration: none;
    color: inherit;
    margin-top: 18px;
}

@media only screen and (max-width: 1200px) {
    .html404TopIMG>img{
        object-fit: cover;
        width: 100%;
    }
    .html404TopTitle02{
        line-height: 50px;
    }
}
@media only screen and (max-width: 1100px) {
    .html404 {
        grid-template-columns: 172px auto;
        margin-left: 0;
    }
    .html404>.html404TopIMG{
        display: none;
    }
    .html404TopIMGOut{
        padding: 0;
    }
}

@media only screen and (max-width: 600px) {
    .html404 {
        display: block;
    }
    .html404>.html404TopIMGOut{
        display: none;
    }
    .html404TopText{
        padding-left: 15px;
    }
}

.html404TopTitleOutOut>img{
    display: none;
}

@media only screen and (max-width: 600px) {
    .html404{
        margin-right: 5px;
    }
    .html404TopTitleOutOut{
        display: flex;
    }
    .html404TopTitleOutOut>img{
        display: block;
    }
    .html404TopButtons{
        justify-content: center;
    }
    .html404TopTitle{
        font-size: 48px;
    }
    .html404TopTitle02{
        font-size: 18px;
        line-height: 20px;
    }
}

