

.LevelsBottom{
    margin: auto;
    position: relative;
    height: 143px;
    width: 1019px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 30px;
}

.LevelsBottomIn{
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-image: linear-gradient(to top, #5b525c, #a69ba7);
    transform: skewX(-15deg);
    border-radius: 7px;
    position: absolute;
    z-index: -1;
}

.LevelsBottomFlex>a{
    position: relative;
}

.LevelsBottomTextPad{
    display: none;
}

.stepActive{
    position: absolute;
    display: none;
    top: 0;
    left: 0;
}

.LevelsBottomFlexActive{
    cursor: unset;
}
.LevelsBottomFlexActive>.stepActive{
    display: block;

}
.LevelsBottomFlex>a:hover>.stepActive{
    display: block;
}

.LevelsBottomFlex{
    display: flex;
    justify-content: space-between;
    width: 980px;
}

@media only screen and (max-width: 1200px) {
    .LayoutInvestHeaderLogo>div{
        padding-left: 10px;
        padding-right: 10px;
    }
}
@media only screen and (max-width: 1100px) {
    .LayoutInvestHeaderButtons>div{
        margin-left: 15px;
        margin-right: 15px;
    }
    .LevelsBottom {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        height: auto;
    }

    .LevelsBottomIn {
        /*width: 100%;*/
        height: 100%;
        object-fit: contain;
        background-image: linear-gradient(to top, #5b525c, #a69ba7);
        transform: skewX(0);
        border-radius: 0;
        position: absolute;
        z-index: -1;
    }

    .LevelsBottomTextPad {
        display: block;
        color: white;
        font-size: 20px;
        font-weight: 600;
        /*width: 100%;*/
        text-align: center;
        margin: 30px;
    }

    .LevelsBottomFlex {
        width: auto;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-bottom: 30px;
    }

    .LevelsBottomFlex>a {
        margin-right: 5px;
        margin-left: 5px;
    }
    .LayoutInvestFooter{
        text-align: center;
    }
}