.IndexMainTopMainBanner{
    min-height: 254px;
}
.IndexMainTopMainBanner{
    /*width: 753px;*/
    width: 100%;
    margin: auto;
    background: #0096d6 url("../../static/img/Index/bigBannerBackground.svg") no-repeat;
    background-size: cover;
    position: relative;
    z-index: 2;

    font-family: MyriadPro, sans-serif;
}
.IndexMainTopMainBannerTopButtons{
    margin-top: 17px;
    display: flex;
    justify-content: space-around;
}

.IndexMainTopMainBannerTopButtons>div{
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 50px;
    cursor: pointer;
}

.IndexMainTopMainBannerTopButtonsSmall{
    text-shadow: 0 1px 0 rgba(0, 1, 1, 0.75);
    font-size: 25px;
    color: #ffffff;
}
.IndexMainTopMainBannerTopButtonsBig{
    text-shadow: 0 1px 0 rgba(0, 1, 1, 0.75);
    margin-left: 5px;
    font-size: 25px;
    font-weight: bold;
    color: #a9e5ff;
    position: relative;
    /*top: -3px;*/
}

.IndexMainTopMainBannerCircle{
    margin: 6px auto auto;
    height: 71px;
    width: 71px;
    box-shadow: 0 1px 1px 0 #78d7ff, inset 0.5px 0.9px 1px 0 black;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #07608f, #07608f), linear-gradient(to top, #323232, #464646);
    cursor: pointer;
}

.IndexMainTopMainBannerCircleIn{
    border-radius: 50%;
    margin: auto;
    position: relative;
    top: 7px;
    width: 57px;
    height: 57px;
    box-shadow: 0.5px 0.9px 1px 0 rgba(0, 0, 0, 0.77);
    background-image: linear-gradient(to top, #3a3a3a, #565656, #5c5c5c, #787878);
    display: flex;
    justify-content: center;
    align-items: center;
}

.IndexMainTopMainBannerCircleImg{
    background-image: url("../../static/img/Index/strelkaButton.svg");
    width: 21px;
    height: 22px;
    position: absolute;
    left: 20px;
}

.IndexMainTopMainBannerCircle:hover>.IndexMainTopMainBannerCircleIn>.IndexMainTopMainBannerCircleInIn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.1;
    box-shadow: 0.5px 0.9px 1px 0 rgba(0, 0, 0, 0.77);
    background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to top, #3a3a3a, #565656, #5c5c5c, #787878);
}

.IndexMainTopMainBannerCircle:hover>.IndexMainTopMainBannerCircleIn>.IndexMainTopMainBannerCircleImg{
    background-image: url("../../static/img/Index/strelkaButtonHover.svg");
}

.IndexMainTopMainBannerCircle:active>.IndexMainTopMainBannerCircleIn{
    box-shadow: inset 0.5px 0.9px 1px 0 rgba(0, 0, 0, 0.38);
    background-image: linear-gradient(to top, #303030, #505050);
}

.IndexMainTopMainBannerCircle:active>.IndexMainTopMainBannerCircleIn>.IndexMainTopMainBannerCircleImg{
    background-image: url("../../static/img/Index/strelkaButtonActive.svg");
}

.IndexMainTopMainBannerBigText{
    text-align: center;
    font-size: 26px;
    color: white;
}

.IndexMainTopMainBannerButtom{
    width: 331px;
    display: flex;
    margin: 10px auto auto;
}

.IndexMainTopMainBannerButtomImg{
    margin-right: 9px;
}

.IndexMainTopMainBannerButtomText{
    font-size: 13px;
    color: white;
    font-style: italic;
    text-align: center;
    margin-top: 3px;
}

.LeftButton>.IndexMainTopMainBannerTopButtons>.IndexMainTopMainBannerTopButtons01,.RightButton>.IndexMainTopMainBannerTopButtons>.IndexMainTopMainBannerTopButtons02{
    background-image: url("../../static/img/Index/BigBannerActiveElement.svg");
    background-size: cover;
}

.IndexMainTopMainBannerTopButtonsLine{
    display: none;
}

.LeftButton>.IndexMainTopMainBannerTopButtons>.IndexMainTopMainBannerTopButtons02>.IndexMainTopMainBannerTopButtonsLine,.RightButton>.IndexMainTopMainBannerTopButtons>.IndexMainTopMainBannerTopButtons01>.IndexMainTopMainBannerTopButtonsLine{
    display: block;
    width: 100%;
    height: 1px;
    background:  rgba(97,208,255,0.75);
    position: relative;
    top: -12px;
}

.IndexMainTopMainBannerTopButtonsIn{
    display: flex;
}

.IndexMainTopMainBannerLeftSide,.IndexMainTopMainBannerRightSide{
    display: none;
}

.LeftButton>.IndexMainTopMainBannerLeftSide{
    display: block;
}

.RightButton>.IndexMainTopMainBannerRightSide{
    display: flex;
    margin-top: 10px;
}

.RightButton>.RightSideIsSmall{
    height: 127px;
}
.IndexBannerRightSideLeftBig{
    display: block;
}
.IndexBannerRightSideLeftSmall{
    display: none;
}
.RightSideIsSmall>.IndexBannerRightSideLeft>.IndexBannerRightSideLeftBig{
    display: none;
}
.RightSideIsSmall>.IndexBannerRightSideLeft>.IndexBannerRightSideLeftSmall{
    display: block;
}

.IndexBannerRightSideRight>a{
    text-decoration: none;
}

.RightButton>.RightSideIsSmall>.IndexBannerRightSideRight>.IndexBannerRightSideRightElementHand,.RightButton>.RightSideIsSmall>.IndexBannerRightSideRight>.IndexBannerRightSideRightButtonOut{
    display: none;
}
.RightButton>.RightSideIsSmall>.IndexBannerRightSideRight>.IndexBannerRightSideRightElementHand,.RightButton>.RightSideIsSmall>.IndexBannerRightSideRight>.IndexBannerRightSideRightButtomText{
    display: none;
}



.RightButton>.RightSideIsSmall>.IndexBannerRightSideRight>.IndexBannerRightSideRightButtom>.IndexBannerRightSideRightButtomImg>.IndexBannerRightSideRightButtomImgInUp>img{
    transform: rotate(90deg);
}
.RightButton>.RightSideIsSmall>.IndexBannerRightSideRight>.IndexBannerRightSideRightButtom>.IndexBannerRightSideRightButtomText{
    display: none;
}
.IndexBannerRightSideRightButtomTextBig{
    display: none;
}
.RightButton>.RightSideIsSmall>.IndexBannerRightSideRight>.IndexBannerRightSideRightButtom>.IndexBannerRightSideRightButtomTextBig{
    display: block;
}

.IndexBannerRightSideLeft{
    padding-left: 50px;
    width: 224px;
}

.IndexBannerRightSideLeft>img:nth-of-type(3){
    position: relative;
    left: -18px;
}

.IndexBannerRightSideRight{
    margin-top: 15px;
    padding-right: 70px;
}

.IndexBannerRightSideRightButton{
    height: 56px;
    width: 273px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 15px;

}

.IndexBannerRightSideRightButtonIn{
    background-image: url("../../static/img/Index/BigBanner/shape-orange.svg");
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.IndexBannerRightSideRightButtonImgIn{
    width: 23px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    top: 14px;
    background-image: url("../../static/img/Index/BigBanner/shape-5-orange.svg");

}


.IndexBannerRightSideRightButtonImg{
    background: url("../../static/img/Index/orange_ED.svg") no-repeat;
    position: absolute;
    right: 20px;
    bottom: 18px;
}

.IndexBannerRightSideRightButtonImgTop{
    width: 23px;
    height: 24px;
}
.IndexBannerRightSideRightButtonIn:hover{
    background-image: url("../../static/img/Index/BigBanner/shape-green.svg");
}
.IndexBannerRightSideRightButtonIn:hover>.IndexBannerRightSideRightButtonImg{
    background-image: url("../../static/img/Index/green_ED.svg");
}
.IndexBannerRightSideRightButtonIn:hover>.IndexBannerRightSideRightButtonImg>div{
    background-image: url("../../static/img/Index/BigBanner/shape-5-green.svg");
}
.IndexBannerRightSideRightButtonIn:active>.IndexBannerRightSideRightButtonImg{
    background-image: url("../../static/img/Index/blue_ED.svg");
}
.IndexBannerRightSideRightButtonIn:active{
    background-image: url("../../static/img/Index/BigBanner/shape_blue.svg");
}
.IndexBannerRightSideRightButtonIn:active>.IndexBannerRightSideRightButtonImg>div{
    background-image: url("../../static/img/Index/BigBanner/shape-blue.svg");
}

.IndexBannerRightSideRightButtonText{
    font-size: 18px;
    color: #edfecf;
    position: relative;
    left: 20px;
    top: -3px;
    text-shadow: 0 -1px 0 rgba(0,0,0,0.4);
    padding-left: 6px;
}

.IndexBannerRightSideRightButtonOut{
    text-decoration: none;
}

.IndexBannerRightSideRightButtonImgIn>img{
    position: relative;
    top: 4px;
    left: 8px;
}


.IndexBannerRightSideRightButtomImgInUp>img{
    transform: rotate(270deg);
}
.IndexBannerRightSideRightButtomImgIn{
    width: 25px;
    height: 25px;
    background-image: linear-gradient(to top, #3a3a3a, #565656, #5c5c5c, #787878);
    border-radius: 50%;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.IndexBannerRightSideRightElement{
    display: flex;
    padding-bottom: 10px;
}

.IndexBannerRightSideRightElementText{
    font-size: 18px;
    color: white;
}

.IndexBannerRightSideRightElementImg{
    position: relative;
    top: -5px;
    margin-right: 10px;
}

.IndexBannerRightSideRightElementHand{
    display: flex;
    color: white;
    font-size: 12px;
}

.IndexBannerRightSideRightElementHandImg{
    margin-right: 14px;
}

.IndexBannerRightSideRightElementText>a{
    color: white;
    text-decoration: none;
    border-bottom: 1px white solid;
}

.IndexBannerRightSideRightButtomText,.IndexBannerRightSideRightButtomTextBig{
    color: white;
    font-size: 12px;
    font-style: italic;
    text-align: center;
}

.IndexBannerRightSideRightButtom{
    position: absolute;
    display: flex;
    bottom: 14px;
    right: 13px;
    align-items: center;
    cursor: pointer;
}

@media only screen and (max-width: 1000px) {
    .IndexMainBannerMainThreeButtons{
        grid-row: 1;
        display: grid;
        justify-content: space-around;
        flex-direction: row;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        height: 53px;
        border: solid 1px #c4c4c4;
        border-top: none;
    }
    /*.IndexMainBannerMainThreeButtons>a:first-of-type,.IndexMainBannerMainThreeButtons>a:nth-of-type(2){*/
        /*border-right:  solid 1px #908f8f;*/
    /*}*/
    .IndexMainBannerMainThreeButtonsElement{
        display: flex;
        align-items: center;
    }

    .IndexMainBannerMainThreeButtonsElement{
        height: 100%;
    }
}

@media only screen and (max-width: 820px) {
    .IndexMainBannerMainThreeButtons>a:nth-of-type(2){
        display: none;
    }
    .IndexMainBannerMainThreeButtons{
        margin-top: 20px;
    }
}
@media only screen and (max-width: 800px) {
    .IndexMainBannerMainThreeButtons{
        margin-top: 10px;
    }
}

@media only screen and (max-width: 820px) {
    .IndexMainBannerMainThreeButtons{
        grid-column-gap: 15px;
    }
    .IndexMainTopMainBanner {
        display: none;
    }

    .IndexMainBannerMainThreeButtons > div {
        margin: 15px 15px 15px 15px;
    }
    .IndexMainBannerMainThreeButtons,.IndexMainBannerMainThreeButtons>a,.IndexMainBannerMainThreeButtons>a>.IndexMainBannerMainThreeButtonsElement,.IndexMainBannerMainThreeButtons>a:first-of-type, .IndexMainBannerMainThreeButtons>a:nth-of-type(2){
        border: none;
    }
    .IndexMainBannerMainThreeButtonsElement{
        background: #eef2fa none;
    }
    .IndexMainBannerMainThreeButtons>a:nth-of-type(3){
        grid-row: 1;
    }

}

@media only screen and (max-width: 820px) {
    .IndexMainBannerMainThreeButtons{
        grid-template-columns: auto;
        height: auto;
        grid-row-gap: 15px;
    }
    .IndexMainBannerMainThreeButtons>a{
        height: 50px;
    }

}

.LeftSideIsBig>.IndexMainTopMainBannerLeftSideSmall{
    display: none;
}
.LeftSideIsSmall>.IndexMainTopMainBannerLeftSideBig{
    display: none;
}
.IndexMainTopMainBannerLeftSideBigYoutube{
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
}

.IndexBannerRightSideRightButtomText{
    text-decoration: dotted;
}