
/*'MyriadPro'*/
@font-face {
    font-display: fallback;    font-family: 'MyriadPro';
    src: url('../fonts/MyriadPro/MyriadPro.ttf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-display: fallback;    font-family: 'MyriadPro';
    src: url('../fonts/MyriadPro/MyriadProItalic.ttf');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-display: fallback;    font-family: 'MyriadPro';
    src: url('../fonts/MyriadPro/MyriadProBold.ttf');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-display: fallback;    font-family: 'MyriadPro';
    src: url('../fonts/MyriadPro/MyriadProBoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}