.MetatraderPage01{
    display: flex;
    justify-content: left;
    margin-top: 20px;
    align-items: center;
}

.MetatraderPage01>a{
    text-decoration: none;
    margin-right: 20px;
}

.MetatraderPageText{
    font-size: 13px;
}

.MetatraderPage02>a{
    padding-left: 10px;
}

.MetatraderPageH2{
    font-size: 18px;
    margin-top: 15px;
}

.MetatraderPage03{
    text-align: left;
    margin-top: 40px;
    margin-bottom: 20px;
}
.MetatraderPageFlex{
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-wrap: wrap;
}

.MetatraderPageFlexTop{
    display: grid;
    align-items: center;
    grid-template-columns: 30px auto;
    grid-column-gap: 20px;
}

.MetatraderPageFlexElement{
    margin-bottom: 25px;
    margin-right: 60px;
}

.MetatraderPageFlexTopCircle{
    margin-right: 5px;
    background-color: #b3d4fc;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}
.MetatraderPageFlexText>a{
    padding-left: 5px;
    padding-right: 5px;
}

.MetatraderPageFlexText{
    font-size: 15px;
}

.MetatraderPageFlexElement>img{
    margin-top: 20px;
}

.MetatraderPageFlexText>b{
    padding-right: 5px;
    padding-left: 5px;
}

.MetatraderPageFlexElement:nth-of-type(1)>.MetatraderPageFlexTop>.MetatraderPageFlexText{
    max-width: 350px;
}
.MetatraderPageFlexElement:nth-of-type(2)>.MetatraderPageFlexTop>.MetatraderPageFlexText{
    max-width: 600px;
}
.MetatraderPageFlexElement:nth-of-type(3)>.MetatraderPageFlexTop>.MetatraderPageFlexText{
    max-width: 600px;
}

@media only screen and (max-width: 800px) {
    .MetatraderPageIMGLast{
        width: 100%;
    }
}


@media only screen and (max-width: 600px) {
    .MetatraderPageFlexElement{
        margin-bottom: 25px;
        margin-right: 0;
    }
    .MetatraderPageFlexElement>img{
        width: 100%;
    }
}



.MetatraderPageBottom>div>a{
    padding-right: 5px;
    padding-left: 5px;
}
.MetatraderPageBottom>div{
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
}
.MetatraderPageBottom{
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 960px) {
    .MetatraderPageFlex{
        display: block;
    }
}

.MetatraderPagePDFDocumentation>a{
    margin-left: 20px;
}