.InvestVideoTop{
    border: 1px solid gray;
    padding: 5px;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    height: min-content;
}

.InvestVideoTopLinkActive{
    color: black;
}

.InvestVideoTopElement{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 53px;
    font-weight: 700;
}

.InvestVideoTop>.InvestVideoTopLinkActive{
    text-decoration: none;
}

.InvestVideoTopLinkActive>.InvestVideoTopElement{
    background-color: #e6f0f6;
    color: black;
    text-decoration: none;
}

@media only screen and (max-width: 600px) {
    .InvestVideoTop{
        grid-template-columns: auto;
    }
}
