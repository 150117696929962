.PartnerBannersIn{
    width: 100%;
    display: grid;
    /*grid-template-columns: auto auto;*/
    /*grid-template-columns: auto 286px;*/
    grid-template-columns: calc(100% - 326px) 286px;
    grid-column-gap: 41px;
}

.PartnerBannersInOut{
    margin-right: 30px;
    margin-left: 30px;
}

.PartnerBannersGridOut{
    overflow: hidden;
}
.PartnerBannersInputOut{
    grid-row: 1;
    grid-column: 1;
    border: solid 1px rgba(200, 217, 227, 0.7);
    border-radius: 5px;
}
.PartnerBannersInput{
    background-color: #fafafa;
    margin: 3px;
    border-radius: 5px;
}

.PartnerBannersInputIn{
    padding: 20px 20px 20px 26px;
}

.PartnerBannersInputIn01{
    display: flex;
    margin-bottom: 18px;
    align-items: center;
}
.PartnerBannersInputIn02{
    display: flex;
    margin-bottom: 18px;
    align-items: center;
}

@media only screen and (max-width: 850px) {
    .PartnerBannersInputIn01,.PartnerBannersInputIn02{
        display: block;
    }
}

    .PartnerBannersInputInText{
    font-weight: 700;
    font-size: 15px;
    margin-right: 10px;
}
.PartnerBannersInputIn>div>input{
    width: 300px;
}

.PartnerBannersOutput{
    padding: 19px 23px 23px 23px;
    display: flex;
    justify-content: center;
}

.PartnerBannersOutputOut{
    border-radius: 4px;
    border: #8cc845 solid 2px;
    grid-row: 1/span 2;
    grid-column: 2;
    /*width: 286px;*/
    height: min-content;
}

.PartnerBannersGrid{
    grid-row: 2;
    grid-column: 1;
    display: flex;
    flex-wrap: wrap;
}

.PartnerBannersElement{
    margin-right: 40px;
    margin-left: 40px;
    /*width: 90%;*/
    position: relative;
    border: 10px solid white;
    height: min-content;
}

.PartnerBannersElement::after{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
    content: '';
    left: 0;
    top: 0;
    cursor: pointer;
}

.PartnerBannersElementActive{
    border: 10px solid #b3d4fc;
    border-bottom: 6px solid #b3d4fc;
}
.PartnerBannersElementActive:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 10px;
    background-color: #b3d4fc;
    top: calc(100% - 4px);
    left: 0;
}

.PartnerBanners{
    margin-bottom: 30px;
}

.PartnerBannersOutput>textarea{
    width: 90%;
    margin: auto;
    height: 300px;
}

@media only screen and (max-width: 780px) {
    .PartnerBannersIn{
        grid-template-columns: 100%;
    }
    .PartnerBannersOutputOut{
        grid-row: 1;
        grid-column: 1;
    }
    .PartnerBannersInputOut{
        grid-row: 2;
    }
    .PartnerBannersGridOut{
        grid-row: 3;
    }
    .PartnerBannersInputIn>div>input{
        width: 80%;
    }
    .PartnerBannersInOut{
        margin-left: 5px;
        margin-right: 5px;
    }
    .PartnerBannersElement{
        margin-left: 5px;
    }
}
