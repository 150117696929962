.ContestVideoOutOut{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.ContestVideoMain{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    justify-content: center;
}

.ContestVideoOutOut>.ContestMenu{
    margin-top: 0;
}

.ContestVideo>div>.TopTitle{
    padding-left: 0;
}

.ContestVideoTop{
    margin-bottom: 30px;
}

.ContestVideoMainElement{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ContestVideoMainElement{
    margin-top: 8px;
}

@media only screen and (max-width: 1000px) {
    .ContestVideoOutOut{
        display: grid;
    }

}
@media only screen and (max-width: 720px) {
    .ContestVideoMain{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .ContestVideoTop,.ContestVideoTop>div{
        width: 100%;
    }

    .ContestVideoTop iframe{
        width: 100%;
        padding-bottom: 1%;
    }
}
@media only screen and (max-width: 520px) {
    .ContestVideoMain{
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (max-width: 420px) {
    .ContestVideoMain{
        grid-template-columns: 1fr;
    }
}
.video-react{
    padding-top: 0;
}
/*.ContestVideoChina>.video-react{*/
/*    padding-top: 0;*/
/*    max-height: 500px;*/
/*}*/
.ContestVideoChina{
    width: 80%;
}