.SubMenuTopPage{
    transition-delay: 300ms;
    position: relative;
    left: -1px;
    width: 100%;
    margin:auto;
    max-width: 1144px;
    border-bottom: 1px solid #ced4d9;
    border-left: 1px solid #ced4d9;
    border-right: 1px solid #ced4d9;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    z-index: 2;
}

.SubMenuClassName{
    border-left: 1px solid white;
    border-right: 1px solid white;
}

.MainMenuTerminalMenuMobiusLogo{
    position: relative;
    top: 13px;
}

@media only screen and (max-width: 800px) {
    .SubMenuTopPage{
        border: none;
    }
}