.FooterAll{
    width: 100%;
    margin: auto;
    max-width: 1144px;

}

.FooterAllMainOut{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.footer_gursText{
    color: #7b7b7b;
    font-size: 13px;
    line-height: 15px;
}
.footer_gursTitle{
    color: #000;
    font-size: 13px;
    line-height: 15px;
}


.footer_gurs{
    display: flex;

}

.footer_gurs>div>a>img{
    position: relative;
    top: 2px;
    padding-right: 10px;
}

.footer_gursText>a{
    text-decoration: none;
}
.footer_gursText>.footer_gursText>a{
    text-decoration: underline;
}