.AboutGeneralText1{

    font-weight: 400;
    font-size: 23px;
    color: black;
    line-height: 28px;
    padding-top: 28px;
    padding-bottom: 28px;
    text-align: center;
}

@media only screen and (max-width: 800px) {
    .AboutGeneralText1{
        font-size: 19px;

    }
}

.AboutGeneralHystory{
    margin-top: 34px;
    width: 100%;
    height: auto;
    border: #cbd8eb 1px solid;
    border-radius: 5px;
    box-shadow: 1.5px 2.6px 15px 0 rgba(0, 66, 122, 0.15);
    background-color: #fafcfe;
}

.AboutGeneralHystory{
}

.AboutGeneralHystoryTitle{

    font-weight: 400;
    font-size: 23px;
    color: black;
    line-height: 28px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 25px;
}

.AboutGeneralHystoryGrid{
    display: grid;
    grid-template-columns: 71px auto;
    grid-column-gap: 31px;
    padding-right: 30px;
    padding-bottom: 15px;
}

.AboutGeneralHystoryGridYears>div{
    height: 32px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.AboutGeneralHystoryGridYearActive{
    cursor: text !important;
}

.AboutGeneralHystoryGridYearActive>div{
    border-bottom: none !important;

}

.AboutGeneralHystoryGridYears>.AboutGeneralHystoryGridYearActive>div{
    color: black;
}

.AboutGeneralHystoryGridYears>div>div{
    display: inline-block;

    font-weight: 700;
    font-size: 15px;
    color: #09518e;
    margin-left: 25px;
    border-bottom: #09518e 1px dashed;
}

.AboutGeneralHystoryGridYearActive{
    background: url("../../../static/img/About/General/AboutGeneral01.png");
    background-size: contain;
}

.AboutGeneralHystoryGridData{
    line-height: 30px;
}

.AboutGeneralHystoryGridDataElementTitle{

    font-weight: 700;
    font-size: 15px;
    color: black;
    line-height: 25px;
}

.AboutGeneralHystoryGridDataElement{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px #e3ebf4 solid;
}

.AboutGeneralOurClients{
    margin: 37px auto;
    padding-left: 28px;
    padding-right: 28px;
}


.AboutGeneralOurClientsIn{
    background: url("../../../static/img/About/General/AboutGeneral02.jpg");
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    color: white;
}

.AboutGeneralOurClientsTitle{

    font-weight: 700;
    font-size: 40px;
    line-height: 19px;
    padding-top: 50px;
    text-decoration: underline;
    margin: auto;
    width: auto;
    display: flex;
    justify-content: center;
}

.AboutGeneralOurClientsTitle>a,.AboutGeneralOurClientsTitle>a:visited{
    color: white;
}
.AboutGeneralOurClientsTitle>img{
    position: relative;
    top: -11px;
    margin-right: 17px;
}

.AboutGeneralOurClientsGrid{
    margin-top: 45px;
    display: grid;
    grid-template-columns: 1fr 15fr 1fr 9fr 1fr;
}

.AboutGeneralOurClientsGrid01{
    grid-column: 2;
    background-color: white;
    color: black;
    border-radius: 5px;
    padding: 21px 30px 35px 30px;
    display: flex;
    flex-direction: column;

    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    align-items: center;
    text-align: center;
    margin-bottom: 31px;
}

@media only screen and (max-width: 900px) {

    .AboutGeneralOurClientsGrid{
        margin-top: 45px;
        display: grid;
        grid-template-columns: 1fr 15fr 1fr 15fr 1fr;
    }

    .AboutGeneralOurClients{
        padding-left: 0;
        padding-right: 0;
    }
    .AboutGeneralOurClientsIn{
        border-radius: 0px;
        background-position-x: -10px;
    }
    .AboutGeneralOurClientsTitle{
        font-size: 30px;
    }
    .AboutGeneralOurClientsTitle>img{
        display: none;
    }
}


.AboutGeneralOurClientsGrid01>img{
    padding-bottom: 9px;
}

.AboutGeneralOurClientsGrid02{
    grid-column: 4;
}

.AboutGeneralOurClientsGrid02Title{

    font-weight: 700;
    font-size: 19px;
    line-height: 25px;
    margin-top: 21px;
    margin-bottom: 8px;
}

.AboutGeneralOurClientsGrid02Text{

    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
}

@media only screen and (max-width: 600px) {
    .AboutGeneralOurClientsTitle{
        padding-left: 20px;
        padding-right: 20px;
        font-size: 21px;
        text-align: center;
        line-height: 29px;
    }

    .AboutGeneralOurClientsGrid {
        grid-template-columns: auto;
    }
    .AboutGeneralOurClientsGrid01 {
        grid-column: 1;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 0;
        margin-bottom: 0;
    }
    .AboutGeneralOurClientsGrid02 {
        grid-column: 1;
        grid-row: 2;
        padding: 30px 20px 30px 20px;
    }
    .AboutGeneralOurClientsGrid02Title{
        margin-top: 0;
    }
}

.AboutGeneralOurClientsWeNeed{
    position: relative;
}

.AboutGeneralOurClientsWeNeed01{
    width: 697px;
    height: 465px;
    background: url("../../../static/img/About/General/AboutGeneral05.jpg");
    border-radius: 5px;
}

.AboutGeneralOurClientsWeNeed02{
    position: absolute;
    width: 477px;
    background:  url("../../../static/img/About/General/AboutGeneral07.svg");
    background-color: #fefffd;
    background-size: contain;
    right: 0;
    top: 68px;
}

.AboutGeneralOurClientsWeNeed02Title{

    font-weight: 400;
    font-size: 23px;
    line-height: 28px;
    padding: 30px 30px 25px 27px;
}

.AboutGeneralOurClientsWeNeed02Text{

    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    padding: 0 20px 36px 27px;
}

.AboutGeneralOurClientsWeNeed03{
    width: 450px;
}

.AboutGeneralOurClientsWeNeed03Title{

    font-weight: 400;
    font-size: 23px;
    line-height: 28px;
    margin-top: 37px;
    margin-bottom: 22px;
}

.AboutGeneralOurClientsWeNeed03Text{

    font-weight: 400;
    font-size: 13px;
    line-height: 25px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 900px) {
    .AboutGeneralOurClientsWeNeed01{
        width: 100%;
        height: 300px;
        background: url("../../../static/img/About/General/AboutGeneral06.png") center;
        border-radius: 2px;
    }
    .AboutGeneralOurClientsWeNeed02{
        position: relative;
        width: 100%;
        background-image: none;
        background-color: #f3f3f3;
        right: auto;
        top: auto;
    }
    .AboutGeneralOurClientsWeNeed03{
        width: 100%;
    }

}

@media only screen and (max-width: 600px) {
    .AboutGeneralOurClientsWeNeed01{
        display: none;
    }
    .AboutGeneralOurClientsWeNeed02{
        position: relative;
        width: 100%;
        background-image: none;
        background-color: white;
        right: auto;
        top: auto;
    }
    .AboutGeneralOurClientsWeNeed02Title{

        font-weight: 400;
        font-size: 23px;
        line-height: 28px;
        padding: 0 0 20px 0;
    }

    .AboutGeneralOurClientsWeNeed02Text{

        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
        padding: 0 0 20px 0;
    }
}
