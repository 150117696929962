.TraderRegistrationPromo03TopIMG>a{
    display: flex;
    justify-content: center;
    padding-top: 26px;
    margin-bottom: 20px;
}

.TraderRegistrationPromo03Title{
    font-size: 36px;
    color: black;
    text-align: center;
    font-weight: 700;
}
.TraderRegistrationPromo03H1{
    font-size: 25px;
    color: black;
    text-align: center;
    font-weight: 700;
    line-height: 41px;
}

.TraderClientOpenPromo03{
    background-image: url("../../../static/img/Promo/promo3.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
}
.TraderRegistrationPromo03Main{
    margin-top: 45px;

    min-height: 600px;
    display: flex;
    justify-content: space-between;
}

.TraderRegistrationPromo03MainLeftTV{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.TraderRegistrationPromo03MainLeftTVsmall>div>img{
    position: relative;
    left: 3px;
}
.TraderRegistrationPromo03MainLeftTVsmall{
    cursor: pointer;
    position: absolute;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 104px;
    height: 104px;
}

.TraderRegistrationPromo03MainLeftElement{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 50px 200px;
    grid-column-gap: 19px;
    font-size: 16px;
    line-height: 27px;
    align-items: center;
}
.TraderRegistrationPromo03MainLeftElement:nth-of-type(3){
    grid-template-columns: 40px 120px;
}

.TraderRegistrationPromo03MainLeftElement>img{
    justify-self: flex-end;
}

.TraderRegistrationPromo03MainBottom>a{
    padding-left: 5px;
}
.TraderRegistrationPromo03MainBottom{
    margin-top: 23px;
    padding: 19px 35px 19px 35px;
    /*width: 150px;*/
    font-size: 14px;
    background-color: white;
}

.TraderRegistrationPromo03MainLeftTop{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.TraderRegistrationPromo03MainLeftTitle{
    font-size: 25px;
    color: black;
    font-weight: 700;
}

.TraderRegistrationPromo03In{
    background-color: white;
    box-shadow: 1.4px 1.4px 100px 0 #002648;
    padding-top: 26px;
    padding-bottom: 26px;
    border-radius: 3px;

}
.TraderRegistrationPromo03GridRegistrationFormElement{
    /*display: grid;*/
    /*grid-template-columns: 200px auto;*/
    /*margin: 0 26px 24px 26px;*/
}

.TraderRegistrationPromo03GridRegistrationForm>.TraderRegistrationPromo03PartPromo{
    margin-top: 24px;
}

.TraderRegistrationPromo03GridRegistrationFormElement3{
    /*display: grid;*/
    /*grid-template-columns: 200px 180px auto;*/
    margin: 0 26px 0 26px;
}

.TraderRegistrationPromo03GridRegistrationFormElementTopTitle{
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 700;
}

.TraderRegistrationPromo03GridRegistrationFormElementInput{
    width: 100%;
}

.TraderRegistrationPromo03GridRegistrationFormElementInput>input,.TraderRegistrationPromo03GridRegistrationFormElementInput>.TraderRegistrationPromo03GridPassword>input{
    width: 100%;
    height: 28px;
    font-size: 15px;
    padding-left: 9px;
    /*box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0 1px 3px 0 #171616;*/
}

.TraderRegistrationPromo03GridRegistrationFormElementTopText{
    font-size: 11px;
    color: #898989;
    margin-top: 6px;
}

.TraderRegistrationPromo03GridRegistrationFormElementTopTextPass{
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.TraderRegistrationPromo03GridRegistrationFormElementTopTextError{
    margin-top: 6px;
    font-size: 11px;
    color: #da343d;
    display: none;
    max-width: 280px;
}

.ButtonTraderRegistrationPromo03>a{
    text-decoration: none;
}


.TraderRegistrationPromo03PasswordOpen,.TraderRegistrationPromo03PasswordClose{
    position: absolute;
    right: 15px;
    top: 6px;
}

.TraderRegistrationPromo03GridPassword{
    position: relative;
}

.TraderRegistrationPromo03GridRegistrationFormElementInput>.TraderRegistrationPromo03GridPassword>div>img{
    cursor: pointer;
}
.PasswordNoView>.TraderRegistrationPromo03GridRegistrationFormElementInput>.TraderRegistrationPromo03GridPassword>div>.TraderRegistrationPromo03PasswordOpen{
    display: none;
}
.PasswordView>.TraderRegistrationPromo03GridRegistrationFormElementInput>.TraderRegistrationPromo03GridPassword>div>.TraderRegistrationPromo03PasswordClose{
    display: none;
}

.TraderRegistrationPromo03GridRegistrationFormElementTopTextError{
    grid-column: 2/span 3;
}

.ButtonTraderRegistrationPromo03Password{
    font-size: 11px;
    color: #09518e;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: underline dotted;
    display: flex;
    align-items: center;
}

.TraderRegistrationPromo03GridRegistrationFormElementTopTitleOut{
    display: flex;
    justify-content: space-between;
}

.TraderRegistrationPromo03ButtomText{
    font-size: 11px;
    margin-top: 15px;
    margin-left: 26px;
    line-height: 12px;
}

.TraderRegistrationPromo03GridRegistrationFormElementError>.TraderRegistrationPromo03GridRegistrationFormElementTopTitle{
    color: #da343d;
}
.TraderRegistrationPromo03GridRegistrationFormElementError>.TraderRegistrationPromo03GridRegistrationFormElementTopTextPassPromo{
    color: #da343d;
    display: block;
}
.TraderRegistrationPromo03GridRegistrationFormElementTopTextPassPromo{
    grid-column: 2/span 3;
    margin-left: 0;
    display: none;
}

.TraderRegistrationPromo03GridRegistrationFormElementError>.TraderRegistrationPromo03GridRegistrationFormElementInput>.TraderRegistrationPromo03GridRegistrationFormElementTopTextError,.TraderRegistrationPromo03GridRegistrationFormElementError>.TraderRegistrationPromo03GridRegistrationFormElementTopTextError {
    display: block;
}
.TraderRegistrationPromo03GridRegistrationFormElementError>.TraderRegistrationPromo03GridRegistrationFormElementInput>input,.TraderRegistrationPromo03GridRegistrationFormElementError>.TraderRegistrationPromo03GridRegistrationFormElementInput>.TraderRegistrationPromo03GridPassword>input{
    background-color: #fff1f2;
    border-top: 1px solid #beb6b6;
    border-left: 1px solid #beb6b6;
}

.TraderRegistrationPromo03Success>.TraderRegistrationPromo03In{
    padding: 0;
}

.TraderRegistrationPromo03GridRegistrationFormElement{
    padding-right: 28px;
    padding-left: 27px;
}

.TraderRegistrationPromo03Success>.TraderRegistrationPromo03In>.TraderRegistrationPromo03InIn>.ButtonTraderRegistrationPromo03{
    margin-left: 0;
}
.TraderRegistrationPromo03Success>.TraderRegistrationPromo03In>.TraderRegistrationPromo03InIn{
    padding: 26px;
}
.TraderRegistrationPromo03SuccessTitle{
    font-size: 23px;
    font-weight: 700;
}

.TraderRegistrationPromo03SuccessText{
    font-size: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.TraderRegistrationPromo03SuccessPasswordWithdrawal{
    background-color: #eefbdf;
    padding: 26px;
}

.TraderRegistrationPromo03SuccessAllInfo{
    margin-top: 15px;
    margin-right: 15px;
}

.TraderRegistrationPromo03SuccessAllInfoElement{
    display: flex;
}

.TraderRegistrationPromo03SuccessAllInfoElementText{
    font-weight: 700;
    width: 250px;
    padding-bottom: 20px;
}
.TraderRegistrationPromo03SuccessAllInfoOut{
    display: flex;
    justify-content: space-between;
}
.TraderRegistrationPromo03SuccessAllInfoPrint>img{
    margin-right: 10px;
}
.TraderRegistrationPromo03SuccessAllInfoPrint{
    padding: 18px 18px 18px 19px ;
    max-width: 252px;
    height: min-content;
    text-decoration: underline;
    font-size: 15px;
    color: #3f3e3e;
    border-radius: 5px;
    border: 1px solid #908f8f;
    margin-top: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 1050px) {
    .TraderRegistrationPromo03SuccessAllInfoOut{
        display: block;
    }
}
@media only screen and (max-width: 800px) {
    .TraderRegistrationPromo03MainLeftElement>img{
        justify-self: center;
    }
    .TraderRegistrationMain {
        display: block;
    }
    .OpenRealDocuments{
        margin: auto;
    }
    .TraderRegistrationPromo03{
        border: none;
        padding: 0;
    }
    .TraderRegistrationPromo03In{
        background-color: unset;
    }
}

@media only screen and (max-width: 550px) {
    .TraderRegistrationPromo03GridRegistrationFormElement{
        display: block;
    }
    .TraderRegistrationPromo03GridRegistrationFormElementTopTitle{
        margin-bottom: 12px;
    }
    .TraderRegistrationPromo03GridRegistrationFormElement3{
        grid-template-columns: auto;
    }
    .TraderRegistrationPromo03GridRegistrationFormElementTopText{
        grid-row: 2;
        margin-left: 0;
        margin-top: 3px;
        margin-bottom: 3px;
        line-height: 17px;
    }
    .TraderRegistrationPromo03GridRegistrationFormElementInput{
        grid-column: 1/span 2;
    }
    .TraderRegistrationPromo03SmallInput{
        max-width: unset;
    }
    .TraderRegistrationPromo03GridRegistrationFormElement3>.TraderRegistrationPromo03GridRegistrationFormElementTopTitle{
        margin-bottom: 0;
        grid-row: 1;
    }
    .TraderRegistrationPromo03GridRegistrationFormElement3>.TraderRegistrationPromo03GridRegistrationFormElementTopTextError{
        grid-column: 1/span 2;
    }
    .TraderRegistrationPromo03GridRegistrationFormElementInput{
        grid-column: 1/span 2;
    }
    .ButtonTraderRegistrationPromo03Password{
        grid-row: 1;
        margin-left: 0;
        position: relative;
        right: 20px;
        top: 5px;
    }
    .ButtonTraderRegistrationPromo03{
        width: unset;
    }
    .TraderRegistrationPromo03AllPasswordBackground>.TraderRegistrationPromo03GridRegistrationFormElement3>.TraderRegistrationPromo03GridRegistrationFormElementTopTitle{
        padding-bottom: 10px;
    }
    .TraderRegistrationPromo03AllPasswordBackground>.TraderRegistrationPromo03GridRegistrationFormElement3>.TraderRegistrationPromo03GridRegistrationFormElementInput>.TraderRegistrationPromo03GridPassword{
        width: 90%;
    }
    .ButtonTraderRegistrationPromo03{
        max-width: 90%;
        margin: 20px auto auto;
        width: 70%;
        margin-bottom: 20px;
    }

    .TraderRegistration>.StandartPage>.StandartTitle{
        display: none;
    }
    .TraderRegistrationPromo03Success{
        border: none;
    }
    .TraderRegistrationPromo03Success>.TraderRegistrationPromo03In>.TraderRegistrationPromo03InIn{
        padding: 0;
    }
    .TraderRegistrationPromo03Success>.TraderRegistrationPromo03In>.TraderRegistrationPromo03InIn>.TraderRegistrationPromo03SuccessTitle,
    .TraderRegistrationPromo03Success>.TraderRegistrationPromo03In>.TraderRegistrationPromo03InIn>.TraderRegistrationPromo03SuccessAllInfo,
    .TraderRegistrationPromo03Success>.TraderRegistrationPromo03In>.TraderRegistrationPromo03InIn>.TraderRegistrationPromo03SuccessText
    {
        padding-left: 20px;
        padding-right: 20px;
    }
    .TraderRegistrationPromo03SuccessText{
        line-height: 27px;
    }
    .TraderRegistration>.StandartPage>.TraderRegistrationMain>.TraderRegistrationPromo03Success{
        padding: 0;
    }

}

.TraderRegistrationPromo03GridRegistrationFormElementInput>.TraderRegistrationPromo03GridPassword>input, .TraderRegistrationPromo03GridRegistrationFormElementInput>input {
    width: 283px;
    height: 28px;
    font-size: 15px;
    padding-left: 9px;
    border-radius: 2px;
    /* box-shadow: 0 1px 1px 0 rgba(0,0,0,.25), inset 0 1px 3px 0 #171616; */
    border: solid 1px #d8e4eb;
    /*box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0px 1px 3px 0 #171616;*/
}

@media print {
    footer,header,.SubMenuClassName,.MainMenuOut,.BlueFooter,.StandartTitle,.OpenRealDocumentsOut,.TraderRegistrationPromo03SuccessAllInfoOut>a,.ButtonTraderRegistrationPromo03,.PlateTop{
        display: none;
    }
}

.TraderRegistrationPromo03GridRegistrationForm>.ButtonTraderRegistration,.ButtonTraderRegistrationPromo03>a>.ButtonTraderRegistration{
    margin-right: 25px;
    width: auto;
}

@media only screen and (max-width: 900px) {
    .TraderRegistrationPromo03Main{
        display: block;
    }
}
@media only screen and (max-width: 550px) {
    .TraderRegistrationPromo03SuccessAllInfoElement{
        display: block;
    }
    .TraderRegistrationPromo03Success>.TraderRegistrationPromo03In{
        padding: 10px;
    }
    .TraderClientOpenPromo>.StandartInZeroBig{
        padding-left: 5px;
        padding-right: 5px;
    }
    .TraderRegistrationPromo03MainLeftElement{
        grid-template-columns: 40px auto;
    }
    .TraderRegistrationPromo03GridRegistrationForm>.ButtonTraderRegistration, .ButtonTraderRegistrationPromo03>a>.ButtonTraderRegistration{
        margin-right: auto;
    }
    .TraderRegistrationPromo03GridRegistrationFormElementInput>.TraderRegistrationPromo03GridPassword>input, .TraderRegistrationPromo03GridRegistrationFormElementInput>input {
        width: 90%;
    }
    .TraderRegistrationPromo03GridRegistrationForm>.ButtonTraderRegistration, .ButtonTraderRegistrationPromo03>a>.ButtonTraderRegistration{
    }
}

@media only screen and (max-width: 1050px) {
    .TraderRegistrationPromo03Main{
        display: block;
    }
    .TraderRegistrationPromo03GridRegistrationForm{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .TraderRegistrationPromo03GridRegistrationForm>div{
        width: 315px;
    }
    .TraderRegistrationPromo03GridRegistrationFormElementInput>.TraderRegistrationPromo03GridPassword>input, .TraderRegistrationPromo03GridRegistrationFormElementInput>input{
        width: 260px;
    }
    .TraderRegistrationPromo03ButtomText{
        grid-column: 2;
        grid-row: 3;
    }
    .TraderRegistrationPromo03GridRegistrationForm>div{
        width: 275px;
    }
    .TraderRegistrationPromo03GridRegistrationForm>.TraderRegistrationPromo03AllPasswordBackground{
        grid-column: 1/span 2;
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
    }
    .TraderRegistrationPromo03GridRegistrationFormElement3{
        width: 275px;

    }
}

@media only screen and (max-width: 730px) {
    .TraderRegistrationPromo03GridRegistrationForm,.TraderRegistrationPromo03GridRegistrationForm>.TraderRegistrationPromo03AllPasswordBackground{
        display: block;
    }
    }


@media only screen and (max-width: 620px) {
    .TraderRegistrationPromo03MainLeftTV>img{
        width: 100%;
    }
    /*.TraderRegistrationPromo03MainLeftTV>a{*/
    /*    width: 50px;*/
    /*    height: 50px;*/

    /*}*/
    /*.TraderRegistrationPromo03MainLeftTV>a>div>img{*/
    /*    width: 5%;*/
    /*}*/
}
@media only screen and (max-width: 400px) {

    .TraderRegistrationPromo03GridRegistrationForm>div,.TraderRegistrationPromo03GridRegistrationFormElement3{
        width: 220px;
    }

    .TraderRegistrationPromo03GridRegistrationFormElementInput>.TraderRegistrationPromo03GridPassword>input, .TraderRegistrationPromo03GridRegistrationFormElementInput>input{
        width: 180px;
    }
}

