.LayoutMain{
    /*overflow: scroll;*/
    /*height: 99%;*/
}

.PlateTop{
    width: 100%;
    height: 47px;
    color: #acb2b8;
    background-color: #1e2e3e;
    position: fixed;
    z-index: 1000;
}

.PlateTopIn>.CheckLanguageOutOut{
    display: none;
}
.PlateTopIn{
    height: 100%;
    margin: auto;
    max-width: 1144px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 1184px) {
    .PlateTopIn{
        padding-right: 20px;
        padding-left: 20px;
    }
}
@media only screen and (max-width: 1000px) {
    .PlateTop {
        display: none;
    }
}

.PlateTopIn>.CheckLanguageOutOut{
    height: 100%;
}
.PlateTopIn>.CheckLanguageOutOut>.CheckLanguage>.CheckLanguageTriangle>img{
    display: none;
}
.PlateTopIn>.CheckLanguageOutOut>.CheckLanguage>.CheckLanguageTriangle>.TriangleForPlate{
    display: block;
}
.PlateTopIn>.CheckLanguageOutOut:hover{
    background-color: #101921;
}
.PlateTopIn>.CheckLanguageOutOut>.CheckLanguage>.CheckLanguageTitle{
    display: none;
}

.PlateTopInElement>div>.HeaderAllRightLK{
    margin-top: 0;
}
.PlateTopInElement{
    display: flex;
    align-items: center;
}
.PlateTopInElement>img{
    margin-right: 5px;
}

.PlateTopIn>a,.PlateTopIn>a:visited{
    text-decoration: none;
    color: #acb2b8;
}

.PlateTopInElement>.HeaderAllRightLK{
    margin-top: 0;
    border-bottom: none;
}
.PlateTopInElement>.HeaderAllRightLK>div{
    color: #acb2b8;
    font-size: 13px;
    font-weight: 700;
    border-bottom: none;
}
.PlateTopInElement>.HeaderAllRightLK>div>.HeaderAllLockPlate{
    display: block;
}

.PlateTopInElement>.HeaderAllRightLK>div>.HeaderAllLock{
    display: none;
}
