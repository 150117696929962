.TraderContestResults{
    display: grid;
    grid-template-columns: minmax(200px,250px)  minmax(500px,860px) auto;
    margin-right: 15px;
}

.TraderContestResultThisContest{
    background-color: white;
    width: 100%;
}

.TraderContestResultThisContestTable{
    width: 100%;
    padding: 13px 1px 15px 28px;
    border-spacing: 0;
}

.TraderContestResultThisContestTable>tbody{
    font-size: 13px;
}
.TraderContestResultThisContestTable>thead{
    background-color: #d8e4eb;
    font-size: 13px;
}
.TraderContestResultThisContestTable>thead>tr>th{
    padding-top: 10px;
}
.TraderContestResultThisContestTable>thead>tr>th:first-of-type,.TraderContestResultThisContestTable>tbody>tr>td:first-of-type{
    padding-left: 10px;
}
.TraderContestResultThisContestTable>thead>tr>th:last-of-type,.TraderContestResultThisContestTable>tbody>tr>td:last-of-type{
    padding-right: 10px;
}
.TraderContestResultThisContestTable>thead>tr>th:nth-of-type(3),.TraderContestResultThisContestTable>tbody>tr>td:nth-of-type(3){
    padding-left: 20px;
}
.TraderContestResultThisContestTable>tbody>tr>td{
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px #d8e4eb solid;
}

.TraderContestResultThisContestTable>tbody:before{
    content: "";
    display: block;
    height: 10px;
}

.TraderContestResultThisContestTable>thead>tr{
    text-align: left;
}

.TraderContestResultThisContestTable>thead>tr>th{
    padding-bottom: 10px;
    border-bottom: 1px solid #218ed2;
}

.TraderContestResultsDates{
    padding-left: 15px;
    padding-top: 15px;
    border-radius: 5px;
    border: 1px solid #d8e4eb;
}

.TraderContestResultsDatesTitle{
    font-weight: 700;
    font-size: 15px;
    padding-top: 1px;
    padding-left: 10px;
    padding-bottom: 10px;
    color: #c3c3c3;
}

.ContestResultsYear{
    padding-right: 10px;
}
.ContestResultsYearTitle{
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    padding-left: 10px;
}

.TraderContestResultsDatesElementActive{
    font-weight: 400;
    font-size: 15px;
    color: white;
    border-radius: 5px;
    background-color: #09518e;
    padding: 4px 10px 4px 6px;
    margin-bottom: 5px;
    margin-top: 5px;
    width: auto;
    /*display: flex;*/
    align-items: center;
}

.TraderContestResultsDatesElementOut{
    display: flex;
}

.TraderContestResultsDatesElement{
    font-weight: 700;
    font-size: 14px;
    color: #116ea9;
    text-decoration: underline;
    padding: 4px 10px 4px 6px;
    margin-bottom: 5px;
    margin-top: 5px;
    cursor: pointer;
}
.TraderContestResultsElementTop{
    font-weight: 700;
}

.TraderContestResultsElementBottom{
    font-weight: 400;
    /*text-align: center;*/
    width: 100%;
}

@media only screen and (min-width: 800px) {
    .TraderContestResults>.StandartAllBlack500{
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    .TraderContestResults{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ContestResultsYearTitle{
        display: none;
    }
    .TraderContestResultsDatesElementOut{
        display: block;
        justify-content: center;
    }
    .TraderContestResultsDatesElement{
        display: none;
    }
    .TraderContestResultsDatesElementActive{
        display: flex;
        color: black;
        border: solid 1px #2b79bb;
        background-color: white;
        /*border: solid 1px #c4c4c4;*/
        /*background-image: linear-gradient(to top, #fafafa, #ededed, #f2f2f2, #f7f7f7, #ffffff);*/
    }
    .TraderContestResultsElementTop{
        margin-right: 20px;
        justify-self: flex-start;
        text-align: center;
        min-width: 100px;
    }
    .ResultsPhoneVisible>.ContestResultsYear>.TraderContestResultsDatesElementOut>div>.TraderContestResultsElementBottom{
        /*padding-right: 30px;*/
        margin-right: 5px;
    }
    .ResultsPhoneVisible>.ContestResultsYear>.TraderContestResultsDatesElementOut>.TraderContestResultsDatesElementActive>.TraderContestResultsElementBottom{
        background-image: url("../../../../../static/img/Trader/Contest/forexstartarrow2.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: right;
        /*background-position-y: px;*/
    }
    .ResultsPhoneVisible{
        position: absolute;
        z-index: 1000;
        background-color: white;
        padding: 50px;
        margin: auto;
        border: 1px solid black;
        border-radius: 15px;
    }
    .ResultsPhoneVisible>.ContestResultsYear>.TraderContestResultsDatesElementOut>.TraderContestResultsDatesElement{
        display: flex;
        justify-content: space-between;
    }
    .ResultsPhoneVisible>.ContestResultsYear>.TraderContestResultsDatesElementOut>.TraderContestResultsDatesElementActive{
        display: flex;
        background-image: none;
        color: black;
    }
}
@media only screen and (max-width: 700px) {
    .NoInPhone700{
        display: none;
    }
    .TraderContestResultThisContestTable{
        padding: 10px;
    }
}
@media only screen and (min-width: 700px) {
    .TraderContestResultThisContestTableInPhone700 {
        display: none;
    }



    .TraderContestResultsPhone {
        display: block;
    }


}

.TraderContestResultThisContestTable>tbody>tr>td{
    vertical-align: middle;
}

@media only screen and (min-width: 500px) {
    .TraderContestResults > .TraderContestResultsPhone{
        display: none;
    }
}

@media only screen and (max-width: 500px) {
    .TraderContestResults{
        display: block;
    }
    /*.TraderContestResultsDatesElementActive{*/
    /*    font-weight: 700;*/
    /*    font-size: 14px;*/
    /*    height: 35px;*/
    /*    border-radius: 12px;*/
    /*    background-image: linear-gradient(to top, #0a79bf, #0fa2fe);*/
    /*    color: white;*/
    /*    line-height: 32px;*/
    /*    cursor: pointer;*/
    /*}*/
    .TraderContestResultThisContestTableInPhone700{
        font-weight: 500;
        font-size: 12px;
        color: black;
    }
    .TraderContestResultThisContestTableInPhone700Out {
        display: flex;
        flex-direction: column;
    }
    .TraderContestResultThisContestTable>tbody>tr:nth-of-type(2n){
        background-color: #5e8bc3;
    }
    .TraderContestResultThisContestTable>tbody>tr>td{
        padding: 13px;
    }
    .TraderContestResultsDatesElementOut{
        display: block;
        text-align: center;
    }
    .TraderContestResultsDatesElement,.ContestResultsYearTitle{
        display: none;
    }
    .ResultsPhoneVisible {
        padding: 20px;
    }

}

@media only screen and (max-width: 380px) {
    .ResultsPhoneVisible {
        padding: 5px;
    }
}


.TraderContestResultsPhone{
    position: fixed;
    top: 40px;
    width: 100%;
    background-color: white;
}

.TraderContestResultsPhone>.TraderContestResultsPhoneDates>.ContestResultsYear>.TraderContestResultsDatesElementOut>.TraderContestResultsDatesElement{
    display: block;
}

.TraderContestResultsPhone>.TraderContestResultsPhoneDates>.ContestResultsYear>.ContestResultsYearTitle{
    display: block;
    width: 100%;
    padding: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    color: black;
}

.TraderContestResultsPhoneDates{
    padding: 22px 26px 26px 22px;
}

.TraderContestResultsPhoneDatesTitle{
    background-color: #155eb9;
    padding-bottom: 10px;
    text-align: center;
    position: relative;
    height: 28px;
    line-height: 34px;
    font-weight: 700;
    font-size: 24px;
    color: black;
}

.TraderContestResultsPhoneDatesTitle>img{
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
}

.TraderContestResultsElementBottomIMG{
    display: none;
    position: relative;
    top: 3px;
    left: -4px;
    margin-left: 10px;
}
.TraderContestResults>.ResultsPhoneNoVisible>.ContestResultsYear>.TraderContestResultsDatesElementOut>.TraderContestResultsDatesElementActive{
    cursor: pointer;
}
.TraderContestResults>.ResultsPhoneNoVisible>.ContestResultsYear>.TraderContestResultsDatesElementOut>.TraderContestResultsDatesElementActive>.TraderContestResultsElementBottomIMG{
    display: block;
}