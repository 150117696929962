.EducationForexCommon{
    display: flex;
    justify-content: center;
    max-width: 640px;
    margin: auto;
}

.EducationForexCommon>div{
    width: 100%;
}

.EducationForexCommon iframe{
    width: 100%;
}