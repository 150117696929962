.EducationForexWomen{
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 640px;
    margin: auto;
}

.EducationForexWomen>div{
    width: 100%;
}

.EducationForexWomen iframe{
    width: 100%;
    padding-bottom: 1%;
}