.TraderVPSGrid01{
    margin-top: 34px;
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-column-gap: 41px;

    margin-bottom: 39px;
}

.vps_block>a{
    text-decoration: none;
}

.vps_table{
    width: 100%;
    border-collapse: collapse;
}

.vps_tableThead{
    background: url("../../../static/img/Trader/VPS/TraderVPS01.png") center;
    background-size: cover;
}

.vps_tableThead>tr{
    height: 44px;
    text-align: left;
    font-weight: 700;
    font-size: 13px;
    line-height: 40px;
    color: #252525;
    border: 0;
}

.vps_tableThead>tr>th,.vps_table>tbody>tr>td{
    padding-left: 14px;
}

.vps_table>tbody>tr{
    height: 42px;
    border-bottom: 1px solid #c8d9e3;
}

.TraderVPSGrid0102{
    background-color: #eafbe1;
    border-left: 1px solid #c0e1ae;
}

.TraderVPSGrid0102Title{
    font-weight: 700;
    font-size: 13px;
    line-height: 23px;
    color: #252525;
    padding: 23px 33px 15px 28px;
}

.TraderVPSGrid0102Text{
    font-weight: 400;
    font-size: 13px;
    line-height: 30px;
    color: #252525;
    padding: 0 33px 23px 28px;
}

@media only screen and (max-width: 900px) {
    .TraderVPSGrid01{
        grid-template-columns: auto;
        grid-row-gap: 23px;
    }

}

.TraderVPSGrid02{
    background-color: #8fc8f5;
    box-shadow: inset 0 0 46px 0 rgba(0, 55, 98, 0.25);
}

.vps_blocks{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 27px 27px 0 12px;
}

.vps_block{
    padding-left: 16px;
    padding-bottom: 27px;
    border-radius: 5px;
}

.vps_block>a{
    display: flex;
    justify-content: left;
    padding-left: 20px;
    padding-top: 8px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

@media only screen and (max-width: 500px) {
    .vps_blocks{
        justify-content: center;
    }
}


.vps_block{
    width: 175px;
}

.vps_block_head{
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.vps_block_head>h2{
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    padding: 13px 16px 0 20px;
    margin: 0;
}

.vps_block_head>.price{
    font-weight: 700;
    font-size: 15px;
    line-height: 23px;
    padding: 0 16px 10px 20px;
}


.vps_block_head_1{
    background-image: linear-gradient(to top, #eafbe1, #f8fff5);
    border-bottom: 1px solid #cfedbf;
}

.vps_block_head_1>.price{
    color: #58a92c;
}

.vps_block_head_2{
    background-image: linear-gradient(to top, #e1f8fb, #f5fdff);
    border-bottom: 1px solid #c5e4e8;

}

.vps_block_head_2>.price{
    color: #58a92c;
}

.vps_block_head_3{
    background-image: linear-gradient(to top, #fbf9e1, #fffef5);
    border-bottom: 1px solid #e7e3ba;
}

.vps_block_head_3>.price{
    color: #b4a95d;
}

.vps_block_head_4{
    background-image: linear-gradient(to top, #fbe9e1, #fff9f7);
    border-bottom: 1px solid #eecec1;
}

.vps_block_head_4>.price{
    color: #c78063;
}

.vps_block_head_5{
    background-image: linear-gradient(to top, #eae1fb, #fcf5ff);
    border-bottom: 1px solid #cfedbf;
}

.vps_block_head_5>.price{
    color: #927eb4;
}

.vps_block>ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-top: 18px;
    padding-left: 19px;
    background-color: white;

    font-weight: 400;
    font-size: 13px;
    line-height: 27px;
}

.vps_block>a{
    background-color: white;
    padding-bottom: 20px;
}

