.PartnerInternet{
    margin-bottom: 43px;
}

cite {
    font-style: normal;
}

.PartnerInternetGridTextText{

    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    position: relative;
}

.PartnerInternetGrid>.PartnerInternetGridTextText{
    padding: 23px 23px 0 35px;
}

.PartnerInternetGridTextImg>img{
    position: absolute;
    top: 0;
    left : 35px;
    width: 24px;
    height: 2px;
}

.PartnerInternetGridImg{
    background: url("../../../static/img/Partners/Internet/PartnersInternet01.png") center;
    background-size: cover;
    width: 100%;
    height: 320px;

    max-width: 650px;
    margin: auto auto 31px;
}
.PartnerInternetGrid{
    display: grid;
    grid-template-columns: 652px auto;
}
.PartnerInternetGridText{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 80%;
    margin: 30px auto;
}

.PartnerInternetGridText01{

    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
}

.PartnerInternetGridText02{

    font-weight: 400;
    font-size: 13px;
    line-height: 27px;
}


@media only screen and (max-width: 1000px) {
    .PartnerInternetGrid {
        display: grid;
        grid-template-columns: auto;
    }

    .PartnerInternetGridTextImg > img {
        display: none;
    }

    .PartnerInternetGrid>.PartnerInternetGridTextText{
        padding: 0 0 36px 0;
        grid-row: 1;
    }


}




@media only screen and (max-width: 700px) {
    .PartnerInternetGridTextText{
        padding-left: 14px;
        padding-right: 14px;
    }
    .StandartPage>.PartnerInternetGridIn{
        padding-left: 1px;
        padding-right: 1px;

    }
    .PartnerInternetGridText{
        width: 100%;
        max-width: 400px;
    }
    .PartnerInternetGridTextText{
        font-size: 17px;
        line-height: 25px;
    }
    .PartnerInternetGridText{
        grid-template-columns: auto;
        width: auto;
    }
    .PartnerInternetGridText02{
        margin-top: 20px;
    }}

@media only screen and (max-width: 500px) {

    .PartnerInternetGridImg{
        background-size: cover;
        width: 100%;
        height: 240px;
    }
}

.PartnerInternetNumber01{
    display: flex;
    padding-top: 20px;
}

.PartnerInternetNumber01>img{
    position: relative;
    top: 3px;
    margin-right: 6px;
}

.PartnerInternetNumber01:first-of-type{
    padding-top: 0;
}

.PartnerInternetBlue{
    background-color: #e5f3ff;
    padding-top: 22px;
    padding-bottom: 32px;
}

.PartnerInternetBlueWhite{
    font-weight: 700;
    background-color: white;
    padding: 15px 45px 15px 15px;
    position: relative;
}

.PartnerInternetPink{
    background-color: #ffe9e6;
    padding-bottom: 22px;
    margin-top: 27px;
}

.PartnerInternetPink>.StandartH2{
    padding-bottom: 0;
}

@media only screen and (max-width: 800px) {
    .PartnerInternetBlueWhite{
        background: none;
    }
    .PartnerInternetPink{
        margin-top: 0;
    }
    .PartnerInternetBlue{
        margin-bottom: 0;
    }
}

.PartnerInternet01{
    padding-bottom: 13px;
}

.PartnerInternetNumber01{
    padding-top: 10px;
}

