.AboutSegregateClients{
    margin-bottom: 45px;
}

.AboutSegregateClientsImg{
    width: auto;
    height: 320px;
    background: url("../../../static/img/About/Segreg/AboutSegreg01.png") center;
    background-size: cover;
    margin-bottom: 30px;
}

@media only screen and (max-width: 700px) {
    .AboutSegregateClientsImg{
        background-position-x: -250px;
    }
}

@media only screen and (max-width: 600px) {
    .AboutSegregateClientsImg{
        background-position-x: -350px;
    }
}

@media only screen and (max-width: 500px) {
    .AboutSegregateClientsImg{
        height: 240px;
        background-position-x: -300px;
        background-size: cover;

    }
}


@media only screen and (max-width: 400px) {
    .AboutSegregateClientsImg{
        height: 240px;
    }
}

.AboutSegregateClientsBlue{
    background-color: #e5f3ff;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 22px;
    margin-bottom: 22px;
}

.AboutSegregateClientsNumber01{
    display: flex;
    padding-top: 30px;
}

.AboutSegregateClientsNumber02{
    display: flex;
    padding-top: 20px;
    padding-bottom: 30px;
}

.AboutSegregateClientsNumber01>img,.AboutSegregateClientsNumber02>img{
    position: relative;
    top: 3px;
    margin-right: 6px;
}
