.IndexProviders{
    width: 100%;
    height: 251px;
    object-fit: contain;
    box-shadow: 2px 3px 15px 0 rgba(44, 81, 103, 0.1);
    border: solid 1px #d8e4eb;
    background-color: #ffffff;
    grid-column: 1;
    display: grid;
    grid-template-rows: auto 160px 40px;
}

.IndexProvidersTop{
    display: flex;
    justify-content: space-between;
}

.IndexProvidersTop{
    font-size: 17px;
    font-weight: bold;
    color: #181818;
    padding: 16px 22px 0 20px;

}

.IndexProvidersTopArrowsUp{
    width: 20px;
    height: 11px;
    background-image: url("../../../static/img/Index/Providers/ArrowUp.svg");
    background-repeat: no-repeat;
}
.IndexProvidersTopArrowsUp:hover{
    background-image: url("../../../static/img/Index/Providers/ArrowUpHover.svg");
}
.IndexProvidersTopArrowsUp:active{
    background-image: url("../../../static/img/Index/Providers/ArrowUpActive.svg");
}


.IndexProvidersTopArrowsDown{
    width: 20px;
    height: 11px;
    background-image: url("../../../static/img/Index/Providers/ArrowUp.svg");
    background-repeat: no-repeat;
    transform: rotate(180deg);
}
.IndexProvidersTopArrowsDown:hover{
    background-image: url("../../../static/img/Index/Providers/ArrowUpHover.svg");
}
.IndexProvidersTopArrowsDown:active{
    background-image: url("../../../static/img/Index/Providers/ArrowUpActive.svg");
}

.IndexProvidersTopArrows{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 30px;
    position: relative;
    top: -5px;
}

.IndexProvidersTopArrows>div{
    cursor: pointer;
}

.IndexProvidersMain{
    padding-left: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.IndexProvidersCirclesOne{
    width: 8px;
    height: 8px;
    object-fit: contain;
    border-radius: 10px;
    border: solid 1px #6e8cb0;
    background-color: #d8e4eb;
    cursor: pointer;
}

.IndexProvidersCirclesOne:nth-of-type(2),.IndexProvidersCirclesOne:nth-of-type(3),.IndexProvidersCirclesOne:nth-of-type(5),.IndexProvidersCirclesOne:nth-of-type(6),.IndexProvidersCirclesOne:nth-of-type(8){
    display: none;
}


.IndexProvidersCircles{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
}

.IndexProvidersCirclesOne{
    margin-right: 10px;
}

.IndexProvidersCirclesActive{
    background-color: #6e8cb0;
    cursor: default;
}

.bankofamerika_index{
    padding-top: 28px;
    /*padding-bottom: 32px;*/
}
.barclyse_index{
    padding-top: 29px;
    /*padding-bottom: 33px;*/
}
.citibank_index{
    padding-top: 18px;
    /*padding-bottom: 18px;*/
}
.creditsuisse_index {
    padding-top: 13px;
    /*padding-bottom: 26px;*/
}
.deutchebank_index{
    padding-top: 12px;
    /*padding-bottom: 36px;*/
}
.goldman_sachs_index{
    padding-top: 31px;
    /*padding-bottom: 25px;*/
}
.jp_morgan_chase_index{
    padding-top: 21px;
    /*padding-bottom: 34px;*/
}
.usb_index{
    padding-top: 27px;
    /*padding-bottom: 17px;*/
}