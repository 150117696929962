

.TerminalWindows{
    font-family: Geometria, sans-serif;

}
.TerminalWindowsIn{
    max-width: 1000px;
    margin: 20px auto auto;
}

.TerminalWindowsPart01{
    background-color: white;
}

.TerminalWindowsPart01Top{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.TerminalWindowsPart01Top>img{
    padding-top: 10px;

}

.TerminalWindowsInPart01Right{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.TerminalWindowsInPart01Right>.TerminalWebComponent3IMG>.TerminalWebComponent3IMGCircles{
    margin-bottom: 30px;
}

.TerminalWindowsInPart01Right>.TerminalDesktopComponent3IMG>.TerminalDesktopComponent3IMGImage{
    display: block;
}

@media only screen and (max-width: 970px) {
    .TerminalWindowsInPart01Right>.TerminalWebComponent3IMG>.TerminalWebComponent3IMGImage>.TerminalWebComponent3IMGImageIn>img{
        width: 100%;
        object-fit: contain;
    }
}

@media only screen and (max-width: 700px) {
    .TerminalWindowsPart01Top{
        justify-content: center;
    }
    .TerminalWeb>.TerminalIn>.TerminalWindowsPart01{
        padding-right: 10px;
        padding-left: 10px;
    }
    .TerminalInPart01LeftText{
        padding-right:  20px;
    }
}

.TerminalWindowsInPartStore{
    padding-top: 0;
}

.TerminalWindowsInPart01RightMainImage{
    position: relative;
}


.TerminalWindowsInPartStore>.TerminalInPart01LeftStore,.TerminalWindowsInPartStore>.TerminalInPart01LeftText{
    padding-bottom: 10px;
}

@media only screen and (max-width: 400px) {
    .TerminalWindowsPart01Top>img{
        display: none;
    }
}

.TerminalWindowsPart01TextMain{
    padding-bottom: 20px;
}

.TerminalWindowsPart02Out{
    background-color: #f2f2ee;
}

.TerminalWindowsPart02{
    display: grid;
    grid-template-columns: 600px auto;
    grid-column-gap: 40px;
    padding-top: 40px;

}

.TerminalWindowsPart02>.TerminalWindowsPart02Left>img{
    object-fit: contain;
}

.TerminalWindowsPart03{
    display: grid;
    grid-template-columns: auto 600px;
    grid-column-gap: 40px;
    padding-top: 40px;
}

.TerminalWindowsPart03>.TerminalWindowsInPart03Right>img{
    object-fit: contain;
}


.TerminalWindowsPart02Left,.TerminalWindowsInPart03Right{
    position: relative;
    overflow: hidden;

}

    .TerminalWindowsPart02{
        padding-left: 0;
        grid-template-columns: auto 300px;
        position: relative;
    }
    .TerminalWindowsPart02>.TerminalWindowsPart02Left>img{
        position: absolute;
        right: -3px;
    }
    .TerminalWindowsPart02:before{
        content: '';
        height: 100%;
        width: 500px;
        position: absolute;
        top: 0;
        left: -500px;
        z-index: 10;
        background-color: #edf1f1;
    }
    .TerminalWindowsPart03{
        padding-right: 0;
        padding-left: 0;
        grid-template-columns: 300px auto;
        position: relative;
    }
    .TerminalWindowsPart03>.TerminalWindowsInPart03Right{overflow: hidden;}


    .TerminalWindowsPart03>.TerminalWindowsInPart03Right>img{
        position: absolute;
        left: -3px;

    }


.TerminalWindowsInPart02Right>ul,.TerminalWindowsInPart03Left>ul{
    padding: 0;
}

.TerminalWindowsInPart03Left>ul{
    padding-left: 15px;

}

.TerminalWindowsInPart02RightAndroidDesktop>.TerminalTitleH2{
    margin-top: 40px;
}


.TerminalWindowsInPart02Right>ul>li,.TerminalWindowsInPart03Left>ul>li{
    line-height: 27px;
    color: #413422;
    font-size: 16px;
    list-style: none;
    margin-top: 25px;
}

.TerminalWindowsInPart02Right>ul>li:before,.TerminalWindowsInPart03Left>ul>li:before{
    content: "";
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #ff9306;
    margin-right: 10px;
    margin-top: 5px;
    display: block;
    float: left;
    position: relative;
    top: 3px;
}

.TerminalWindowsInPart02Right>.TerminalTitleH2,.TerminalWindowsInPart03Left>.TerminalTitleH2{
    padding: 0 30px 0 15px;
    font-size: 24px;
    line-height: 28px;
    color: #413422;
}

@media only screen and (max-width: 600px) {
    .TerminalWindowsInPart02Right>.TerminalTitleH2,.TerminalWindowsInPart03Left>.TerminalTitleH2{
        padding-top: 20px;
    }

    .TerminalWindowsPart02{
        display: block;
        background-color: white;
        padding-right: 0;
    }
    .TerminalWindowsInPart02Right,.TerminalWindowsInPart03Left{
        padding-left: 20px;
        padding-right: 20px;
    }
    .TerminalWindowsPart02>.TerminalWindowsPart02Left>img{
        position: static;
        width: 100%;
    }
    .TerminalWindows{
        margin-left: 0;
        margin-right: 0;
    }
    .TerminalWindowsPart03{
        display: flex;
        flex-direction: column;
    }
    .TerminalWindowsInPart03Right{
        position: relative;
        order : 1;
    }
    .TerminalWindowsInPart03Left{
        order: 2;
    }

    .TerminalWindowsPart03>.TerminalWindowsInPart03Right>img{
        position: static;
        width: 100%;
    }
    .TerminalWindowsPart03:after{
        width: 0;
    }
    .TerminalWindowsInPart03Left>ul{
        padding-left: 0;

    }
    .TerminalWindowsInPart03Left>.TerminalTitleH2{
        padding-left: 0;

    }
}

.TerminalWindowsPart01TextMain{
    padding-bottom: 20px;
    font-weight: 400;
    font-size: 18px;
    color: #413422;
    padding-top: 30px;
    line-height: 27px;
}

.TerminalWindowsInPart01Right>.TerminalDesktopComponent3IMG>.TerminalDesktopComponent3IMGImage{
    margin: auto;
    width: 700px;
    left: -390px;
    top: 100px;
}

@media only screen and (max-width: 1000px) {
    .TerminalWindowsInPart01RightMainImage{
        display: none;
    }
    .TerminalWindowsInPart01Right>.TerminalDesktopComponent3IMG>.TerminalDesktopComponent3IMGImage{
        position: relative;
        left: auto;
        top: auto;
    }
}

@media only screen and (max-width: 1000px) {
    .TerminalWindowsInPart01Right>.TerminalDesktopComponent3IMG>.TerminalDesktopComponent3IMGImage>img{
        width: 100%;
    }
    .TerminalWindowsInPart01Right>.TerminalDesktopComponent3IMG>.TerminalDesktopComponent3IMGImage{
        height: auto;
        width: 100%;
        display: block;
        object-fit: contain;
    }

}

.TerminalWindowsInPart03Right>img{
    width: 857px;
}


.TerminalWindowsInPartStore{
    padding-top: 0;
    display: flex;
    align-items: center;
}
.TerminalWindowsInPartStore>.TerminalInPart01LeftStore{
    margin-right: 30px;
}

@media only screen and (max-width: 500px) {
    .TerminalWindowsInPartStore{
        flex-direction: column;
        align-items: center;
    }
}

.TerminalWindowsInPart01Right>.TerminalWindowsInPart01RightMainImage{
    left: 10px;
}


.TerminalWindowsPart02Left:before{
    content: '';
    height: 100%;
    width: 200px;
    position: absolute;
    top: 0;
    left: -205px;
    z-index: 10;
    background-color: #edf1f1;
}

.TerminalWindowsInPart03Left>div{
    padding-left: 15px;
}

@media only screen and (max-width: 1050px) {
   .TerminalWindowsIn{
       margin-left: 20px;
       margin-right: 20px;
   }
}
@media only screen and (max-width: 800px) {
   .TerminalWindowsIn{
       margin-left: 0;
       margin-right: 0;
       margin-top: 0;
   }
}