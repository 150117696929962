.FindFormOut{
    position: relative;
    display: flex;
    align-items: center;
}

.FindForm{
    width: 118px;
    height: 20px;
    border: #d2d2d2 1px solid;
    border-radius: 3px;
    color: black;
}

.FindForm::placeholder{
    color: #757575;
}
/*.FindForm:focus~.FindFormImgActive{*/


.FindFormImgActive{
    display: none;
}
.FindFormOutIMGActive>.FindFormImgActive{
    display: block;
}
/*.FindForm:focus~.FindFormImg{*/
/*    display: none;*/
/*}*/

.FindFormOutIMGActive>.FindFormImg{
    display: none;
}

.FindFormImg,.FindFormImgActive{
    position: absolute;
    top: 4px;
    right: 10px;
    cursor: pointer;
    z-index: 50;
}


.FindFormImgActive{
    display: none;
}

.FindFormButton{
    display: none;
}

.FindFormOut>input{
    padding-left: 9px;
}


@media only screen and (max-width: 800px) {
    .FindFormOut{
        display: grid;
        width: 255px;
        margin: auto;
        grid-template-columns: auto auto;
        border-bottom: 1px solid white;
        background-color: #003269;
    }
    .FindFormOut>a{
        display: none;
    }
    .FindFormOut>input{
        margin: 10px;
    }
    .FindFormOut>div{
        position: relative;
        top: -1px;
        right: 5px;
    }
    .FindFormButton{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 67px;
        height: 35px;
        object-fit: contain;
        background-image: linear-gradient(to top, #ffd68d, #ffe992);
        border-radius: 3px;
        margin-top: 2px;
        color: #033569;
        font-size: 13px;

    }
    .FindFormOut>img{
        display: none !important;
    }
    .FindForm{
        width: 141px;
        height: 10px;
        padding: 10px 0 12px 11px;
    }
}
