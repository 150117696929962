.TisInvestmentTis{
    padding-bottom: 48px;
}

.TisInvestmentTisGrid{
    display: grid;
    grid-template-columns: auto 236px;
    grid-column-gap: 30px;
}

@media only screen and (max-width: 700px) {
    .TisInvestmentTisGrid{
        grid-template-columns: auto;
    }
    .TisInvestmentTisGrid02{
        grid-row: 1;
    }

}


.TisInvestmentTisGridIMG{
    width: 100%;
    background: url("../../../static/img/TIS/TIS/TISTIS01.png") right;
    background-size: cover;
    height: 320px;
    border-radius: 5px;
}

.TisInvestmentTisGrid02{
    padding-top: 27px;
    padding-bottom: 25px;
}
.TisInvestmentTisGridGreen03{
    padding-top: 28px;
}

.TisInvestmentTisGridGreen{
    height: 106px;
    background-color: #eefbe4;
    text-align: center;
}

.TisInvestmentTisGridGreen01{
    padding-top: 27px;
}

.TisInvestmentTisGridGreen02{

    font-weight: 400;
    font-size: 23px;
    line-height: 35px;
}