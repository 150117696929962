.TradingFAQButtonButtonAllDivActive,.TraderFAQElementTitle{
    cursor: pointer;

}

.TradingFAQButtonButtonAllDivActive{
    color: #218ed2;
}

.TradingFAQPartTitle {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top:  20px;
    color: #0a0a0a;
}
.FAQElement{
    margin-bottom: 40px;
}

.FAQElement:first-of-type>.TradingFAQPartTitle{
    margin-top: 0;
}

.TitleGlobalFlex{
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 650px) {
    .TitleGlobalFlex{
        flex-direction: column;

    }
    .TradingFAQButtonButtonAll{
        margin-left: 30px;
    }

    .TitleGlobalFlex>.TradingFAQButtonButtonAll{
        margin-top: 0;
    }
}
@media only screen and (max-width: 400px) {
    .TitleGlobalFlex>.TradingFAQButtonButtonAll{
        grid-template-columns: 157px;
        grid-row-gap: 10px;
        padding-bottom: 10px;
    }
}

.TradingFAQButtonButtonAllDivActiveNo{
    border: solid #828282 2px;
    color: gray;
    cursor: default;
    position: relative;
    top: -1px;
}

.TradingFAQButtonButtonAll{
    margin-top: 16px;
    display: grid;
    grid-template-columns: 157px 157px;
    grid-column-gap: 20px;
    position: absolute;
    right: 0;
    width: 334px;
}
@media only screen and (max-width: 1200px) {
    .TradingFAQButtonButtonAll{
        right: 30px;
    }
}

.TradingFAQButtonButtonAll>div>img{
    padding-right: 15px;
}

.TradingFAQButtonButtonAll>div{
    width: 129px;
    border-radius: 4px;
    background-color: white;
    padding: 6px 13px 8px 13px;
    height: 22px;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 800px) {
    .TradingFAQButtonButtonAll{
        position: relative;
    }
}

.TraderFAQ>div>div>div>div>div>.TradingFAQPartTitle{
    /*display: none;*/
}
.TraderFAQ{
    position: relative;
    max-width: 1144px;
    margin: auto;
    padding-bottom: 15px;
}

@media only screen and (max-width: 1150px) {

}

.TradingFAQPartElement{
    background-color: white;
    padding: 12px 58px 1px 19px;
    margin-bottom: 1px;
}

.TradingFAQPartElement>.TradingFAQPartElementTitle{
    cursor: pointer;
    border-bottom: 1px dashed;
    font-size: 15px;
    color: #02518e;
    width: auto;
}

.TradingFAQPartElement>.TradingFAQPartElementText{
    display: none;
}

.TradingFAQPartElementActive{
    background-color: #f3f3f3;
}

.TradingFAQPartElementActive>.TradingFAQPartElementTitle{
    font-weight: 700;
    font-size: 17px;
    color: rgb(24,24,24);
}

.TradingFAQPartElementActive>.TradingFAQPartElementText{
    display: block;
    margin-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
    font-size: 15px;
    color: rgb(24,24,24);
}

.TradingFAQButtonButtonIMGDis{
    display: none;
}

.TradingFAQButtonButtonAllDivActiveNo>.TradingFAQButtonButtonIMGDis{
    display: block;
}
.TradingFAQButtonButtonAllDivActiveNo>.TradingFAQButtonButtonIMGActive{
    display: none;
}