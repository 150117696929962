.InvestStepTopLinks{
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 36px;
    color: #1e1a1f;
    margin-top: 50px;
}

.InvestStep1MainTopLinksRight>a>img{
    padding-left: 10px;
}
.InvestStep1MainTopLinksRight>a{
    color: #50a7fa;
}
.InvestStep1MainTopLinksRight{
    display: flex;
}

.InvestStepTopImages{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 16px;
    color: #645b67;
    flex-wrap: wrap;
}

.InvestStepTopImage{
    width: 190px;
    margin-left: 15px;
}
.InvestStepTopImage:first-of-type{
    margin-left: 0;
}

.InvestStepTopImagesText{
    margin-top: 5px;
}

.InvestStepUl{
    padding-left: 0;
    font-size: 16px;
    color: #645b67;
    list-style: none;
}

.InvestStepLi{
    line-height: 1.8;
    color: #645b67;
    font-size: 16px;
    list-style: none;
}

.InvestStepLi:before{
     content: "";
     width: 8px;
     height: 8px;
     border-radius: 50%;
     background: black;
     margin-right: 10px;
     margin-top: 5px;
     display: block;
     float: left;
     position: relative;
     top: 3px;
 }

.InvestStepText{
    color: #645b67;
    font-size: 16px;
    line-height: 24px;
}

@media only screen and (max-width: 600px) {
    .InvestStepTopLinks{
        font-size: 20px;
    }
}
@media only screen and (max-width: 500px) {
    .InvestStepTopImages{
        justify-content: center;
    }
}

.InvestStep6IMG{
    display: flex;
    justify-content: center;
}
@media only screen and (max-width: 700px) {
    .InvestStep6IMG>img{
        width: 100%;
    }
}


