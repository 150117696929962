.TraderWebinars>.StandartPageIn>.StandartTitle{
    padding-top: 0;
    padding-bottom: 0;
}

.TraderWebinarsTopGridGreen{
    border-radius: 4px;
    box-shadow: 1.5px 2.6px 15px 0 rgba(72, 133, 0, 0.1);
    border: 2px solid #8cc845;
}

.TraderWebinarsTopGrid{
    display: grid;
    grid-column-gap: 50px;
    grid-template-columns: auto auto 220px;
}

.TraderWebinarsTopGrid03{

    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
}

.TraderWebinarsTopGrid0301{
    display: flex;
}

.TraderWebinarsTopGrid0301>img{
    margin-right: 10px;
    position: relative;
    top: 3px;
}

@media only screen and (max-width: 1000px) {
    .TraderWebinarsTopGrid{
        grid-template-columns: auto 220px;
    }
    .TraderWebinarsTopGrid01{
        grid-column: 1/span 2;
        margin-bottom: 32px;
    }
}

@media only screen and (max-width: 600px) {
    .TraderWebinarsTopGrid{
        grid-template-columns: auto;
    }
    .TraderWebinarsTopGrid01{
        grid-column: 1;
        margin-bottom: 22px;
    }
    .TraderWebinarsTopGrid02{
        margin-bottom: 26px;
    }
}

.TraderWebinarsTopGridGreenTitle{
    display: flex;
    justify-content: space-between;
    padding: 25px 24px 28px 26px;
}

.TraderWebinarsTopGridGreenTitleText{

    font-weight: 700;
    font-size: 19px;
    line-height: 27px;
    color: #75ba22;
}

.TraderWebinarsTopGridGreenTitleTime{
    display: flex;
    align-items: center;
}

.TraderWebinarsTopGridGreenTitleTime>img{
    margin-right: 8px;
}

.TraderWebinarsTopGridGreenTitleTimeTitle{
    font-weight: 700;
    margin-right: 4px;
}

.TraderWebinarsTopGridGreenTitleTimeText{
    position: relative;
    top: 1px;
}

.TraderWebinarsTopGridGreenGridImg{
    padding: 0 24px 28px 26px;
    display: grid;
    grid-template-columns: 230px auto;
    grid-column-gap: 28px;
}

.TraderWebinarsTopGridGreenGridRightButton{
    display: flex;
    flex-wrap: wrap;
}

.TraderWebinarsTopGridGreenGridRightButton>img{
    margin-left: 22px;
    margin-top: 4px;
}

.TraderWebinarsTopGridGreenGridRight{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.TraderWebinarsTopGridGreenGridRight>.StandartTitle{
    padding-top: 0;
}

@media only screen and (max-width: 700px) {
    .TraderWebinarsTopGridGreenTitle{
        flex-direction: column;
    }
    .TraderWebinarsTopGridGreenTitleTime{
        padding-top: 10px;
    }
    .TraderWebinarsTopGridGreenGridImg{
        grid-template-columns: auto;
    }
    .TraderWebinarsTopGridGreenGridImg>img{
        padding-bottom: 20px;
    }
}

@media only screen and (max-width: 400px) {
    .TraderWebinarsTopGridGreenTitle,.TraderWebinarsTopGridGreenGridImg{
        padding-right: 5px;
        padding-left: 10px;
    }
    .TraderWebinarsTopGridGreenGridRightButton>img{
        margin-left: 0;
    }
    .TraderWebinarsTopGridGreenGridRightButton>a{
        padding-right: 15px;
        padding-bottom: 10px;
    }
}

.TraderWebinarsTopGridVideoGrid{
    display: grid;
    grid-template-columns: 640px auto;
    grid-column-gap: 40px;
}

.TraderWebinarsTopGridVideoGridSet{
    grid-column: 2;
    height: 340px;
    overflow-y: scroll;
    overflow-x: hidden;
}

@media only screen and (max-width: 1000px) {
    .TraderWebinarsTopGridVideoGridSet{
        height: auto;
        overflow-y: hidden;
    }
}


.TraderWebinarsTopGridVideoGridVideo{
    grid-column: 1;
    grid-row: 1;
}

.TraderWebinarsTopGridVideoGridSetElement{
    cursor: pointer;
    display: grid;
    grid-template-columns: 90px auto;
    grid-column-gap: 10px;
    padding: 10px 30px 10px 10px;
}

.TraderWebinarsTopGridVideoGridSetElementTextTitle{
    color: #2288da;
    font-size: 16px;
    text-decoration: underline;
    margin-bottom: 20px;
}
.TraderWebinarsTopGridVideoGridSetElementTextDuration{
    color: gray;
    font-size: 12px;
    font-weight: 700;
}

.TraderWebinarsTopGridVideoGridSetElementActive{
    background-color: #413422;
}
.TraderWebinarsTopGridVideoGridSetElementActive>.TraderWebinarsTopGridVideoGridSetElementText>.TraderWebinarsTopGridVideoGridSetElementTextTitle{
    color: white;
    font-weight: 700;
    text-decoration: none;
}

@media only screen and (max-width: 1000px) {
    .TraderWebinarsTopGridVideoGrid{
        display: block;
    }
    .TraderWebinarsTopGridVideoGridVideo{
        display: flex;
        justify-content: center;
    }
    .TraderWebinarsTopGridVideoGridVideo,.TraderWebinarsTopGridVideoGridVideo>div{
        width: 100%;
    }
    .TraderWebinarsTopGridVideoGridVideo iframe{
        width: 100%;
        padding-bottom: 1%;
    }
    .TraderWebinarsTopGridVideoGridSet{
        display: flex;
        width: 100%;
        overflow-x: scroll;
    }
    .TraderWebinarsTopGridVideoGridSetElement{
        grid-template-columns: 90px 200px;
    }
}