.FooterAllHref{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    grid-column-gap: 25px;
}

.FooterAllHrefElement{
    display: flex;
    flex-direction: column;
}

.FooterAllHrefElementTitle{

    font-weight: 700;
    font-size: 15px;
    color: #100e08;
    border-bottom: 1px solid #ced4d9;
    padding-top: 15px;
    padding-bottom: 15px;
}

.FooterAllHrefElementMenu{
    display: flex;
    flex-direction: column;
}

.FooterAllHrefSubMenuElement>a{
    text-decoration: none;
}
.FooterAllHrefSubMenuElement{

    font-weight: 400;
    font-size: 13px;
    color: #125590;
    padding-top: 15px;
}

@media only screen and (max-width: 1150px) {
    .FooterAllHref{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    .FooterAllHrefElement0{
        grid-column: 1;
        grid-row: 1;
    }
    .FooterAllHrefElement1{
        grid-column: 2;
        grid-row: 1;
    }
    .FooterAllHrefElement2{
        grid-column: 4;
        grid-row: 2;
        position: relative;
        top: -56px;
    }
    .FooterAllHrefElement3{
        grid-column: 3;
        grid-row: 1;
    }
    .FooterAllHrefElement4{
        grid-column: 4;
        grid-row: 1;
    }
    .FooterAllHrefElement5{
        grid-column: 5;
        grid-row: 1;
    }
}


.FooterAllHrefElement4{
    position: relative;
    min-width: 130px;
}

.FooterAllHrefElement4>.FooterAllHrefElementMenu>div>a{
    display: flex;
    align-items: flex-end;

}

.FooterAllHrefElement4>.FooterAllHrefElementMenu>.FooterAllHrefSubMenuElement1>a>div{
    width: 14px;
    height: 14px;
    margin-right: 10px;
    background: url("../../../../static/img/FooterAll/FooterAll02.png") center;
    background-size: cover;
    position: relative;
    top: -1px;
}

.FooterAllHrefElement4>.FooterAllHrefElementMenu>.FooterAllHrefSubMenuElement2>a>div{
    width: 16px;
    height: 18px;
    margin-right: 10px;
    background: url("../../../../static/img/FooterAll/FooterAll03.png") center;
    background-size: cover;
    position: relative;
    top: -1px;
}

.FooterAllHrefElement4>.FooterAllHrefElementMenu>.FooterAllHrefSubMenuElement3>a>div{
    width: 14px;
    height: 16px;
    margin-right: 10px;
    background: url("../../../../static/img/FooterAll/FooterAll04.png") center;
    background-size: cover;
    position: relative;
    top: -1px;
}

.FooterAllHrefElement4>.FooterAllHrefElementMenu>.FooterAllHrefSubMenuElement4>a>div{
    width: 14px;
    height: 14px;
    margin-right: 10px;
    background: url("../../../../static/img/FooterAll/FooterAll05.png") center;
    background-size: cover;
    position: relative;
    top: -1px;
}

.FooterAllHrefElement4>.FooterAllHrefElementMenu>.FooterAllHrefSubMenuElement5>a>div{
    width: 15px;
    height: 17px;
    margin-right: 10px;
    background: url("../../../../static/img/FooterAll/FooterAll06.png") center;
    background-size: cover;
    position: relative;
    top: -1px;
}

.FooterAllHrefElement4>.FooterAllHrefElementMenu>.FooterAllHrefSubMenuElement6>a>div{
    width: 14px;
    height: 12px;
    margin-right: 10px;
    background: url("../../../../static/img/FooterAll/FooterAll07.png") center;
    background-size: cover;
    position: relative;
    top: -1px;
}

.FooterAllHrefElement4>.FooterAllHrefElementMenu>.FooterAllHrefSubMenuElement1::before{
    content: "Mobius Trader";

    font-weight: 700;
    font-size: 13px;
    color: #bcc1c4;
    position: relative;
    top: -15px;
}

.FooterAllHrefElement4>.FooterAllHrefElementMenu>.FooterAllHrefSubMenuElement1{
    padding-top: 30px;
}

.FooterAllHrefElement4>.FooterAllHrefElementMenu>.FooterAllHrefSubMenuElement7{
    padding-top: 40px;
}

.FooterAllHrefElement4>.FooterAllHrefElementMenu>.FooterAllHrefSubMenuElement7::before{
    content: "Meta Trader";

    font-weight: 700;
    font-size: 13px;
    color: #bcc1c4;
    position: relative;
    top: -10px;
}

.FooterAllHrefElement4>.FooterAllHrefElementMenu>.FooterAllHrefSubMenuElement2::before{
    content: "";
    width: 26px;
    height: 20px;
    background: url("../../../../static/img/FooterAll/FooterAll01.png");
    background-size: cover;
    position: absolute;
    top: 60px;
    right: 2px;
}

.FooterAllHrefElement4>.FooterAllHrefElementMenu>.FooterAllHrefSubMenuElement7>a>div{
    width: 14px;
    height: 14px;
    margin-right: 10px;
    background: url("../../../../static/img/FooterAll/FooterAll02.png") center;
    background-size: cover;
    position: relative;
    top: -1px;
}


@media only screen and (max-width: 900px) {
    .FooterAllHref {
        display: none;
    }
    .Footer404{
        display: flex;
        flex-wrap: wrap;
        width: auto;
        grid-template-columns: auto auto;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 40px;
    }
    .FooterAllHrefElement2{
        position: unset;
    }
}