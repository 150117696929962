.MediaSolutionFlexElementText{
    font-size: 15px;
    font-weight: 700;
    margin-top: 10px;
}

.MediaSolutionFlexElement{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 50px;
}
.MediaSolutionFlexElementLink{
    font-size: 15px;
    margin-top: 10px;
}

.MediaSolutionFlex{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}