.ForexstartContestRegistration{
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 500;
    height: 100%;
    margin-bottom: 20px;
}

.ForexstartContestRegistrationIn{
    padding-left: 30px;
}

.ForexstartContestRegistrationSuccess{
    height: 100%;
}


.TraderContestFormRegistrationTitle{
    color: rgb(24,24,24);
    font-size: 26px;
    padding-top: 20px;
    padding-bottom: 30px;
}

.ForexstartContestRegistrationGrid>.ButtonStandartOut{
    justify-content: flex-start;
}

.ForexstartContestRegistrationGridRegistrationFormElement{
    display: grid;
    grid-template-columns: 250px 26px;
    grid-column-gap: 50px;
    margin-top: 20px;
}


.ForexstartContestRegistrationGridRegistrationFormElementTopText{
    font-size: 12px;
    text-align: left;
    color: #898989;
    display: block;
}



.ForexstartContestRegistrationGridRegistrationFormElementTopTextError,.ForexstartContestRegistrationGridRegistrationFormElementTopTextErrorCheck{
    display: none;
}


.ForexstartContestRegistrationGridRegistrationFormElementInputInput{
    width: 100%;
    object-fit: contain;
    border: solid 1px #c8c8c8;
    background-color: #ffffff;
    padding: 6px;
    font-size: 20px;
    font-weight: 600;
    line-height: 0.2;
    text-align: left;
    color: #1e1e1e;
}

.ForexstartContestRegistrationGridRegistrationFormElementInputIn{
    position: relative;
}

.ButtonForexstartContestRegistrationPassword{
    position: absolute;
    top: 3px;
    right: -13px;
    font-family: Intro, sans-serif;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
    height: 28px;
    padding-left: 13px;
    padding-right: 12px;
    line-height: 30px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    border: solid 1px #08b7c2;
    background-image: linear-gradient(to top, #09d0dd 1%, #09d0dd);
}




.ButtonForexstartContestRegistration{
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
    height: 40px;
    padding-left: 30px;
    padding-right: 30px;
    line-height: 42px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    border: solid 1px rgb(33,142,210);
    background-image: linear-gradient(to top, #005eb3, #097ac9);
    margin: 20px 0 0 300px;
}

.ForexstartContestRegistrationSuccessAllInfoTitle{
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    color: #161616;
}


.ButtonForexstartContestRegistration:hover{
    transition: all .2s linear;

}

.ButtonForexstartContestRegistration:active{
    color: white;
}

.ForexstartContestRegistrationSuccessBottomLink>a>.ButtonForexstartContestRegistration{
    font-size: 15px;
}


.InvestRegPasswordOpen{
    position: absolute;
    top: 10px;
    left: 250px;
    cursor: pointer;
}

.InvestRegPasswordClose{
    position: absolute;
    top: 10px;
    left: 250px;
    cursor: pointer;
}

.ForexstartContestRegistrationGridRegistrationFormElementInput>.ForexstartContestRegistrationGridRegistrationFormElementInputIn>.ForexstartContestRegistrationGridRegistrationFormElementInputInput{
    width: 261px;
}
.ForexstartContestRegistrationGridRegistrationFormElementPassword>.ForexstartContestRegistrationGridRegistrationFormElementInput>.ForexstartContestRegistrationGridRegistrationFormElementInputIn>.ForexstartContestRegistrationGridRegistrationFormElementInputInput{
    width: 209px;
}

.PasswordNoView>.ForexstartContestRegistrationGridRegistrationFormElementInput>.ForexstartContestRegistrationGridRegistrationFormElementInputIn>.InvestRegPasswordOpen{
    display: none;
}

.PasswordNoView>.ForexstartContestRegistrationGridRegistrationFormElementInput>.ForexstartContestRegistrationGridRegistrationFormElementInputIn>.InvestRegPasswordClose {
    display: block;
}

.PasswordView>.ForexstartContestRegistrationGridRegistrationFormElementInput>.ForexstartContestRegistrationGridRegistrationFormElementInputIn>.InvestRegPasswordOpen{
    display: block;
}

.PasswordView>.ForexstartContestRegistrationGridRegistrationFormElementInput>.ForexstartContestRegistrationGridRegistrationFormElementInputIn>.InvestRegPasswordClose{
    display: none;
}

.ForexstartContestRegistrationGridRegistrationFormElementTopTextError,.ForexstartContestRegistrationGridRegistrationFormElementTopTextErrorCheck{
    color: rgb(255,104,104);
    font-size: 13px;
    padding-top: 10px;
    width: max-content;
}

.ForexstartContestRegistrationGridRegistrationFormElementError>.ForexstartContestRegistrationGridRegistrationFormElementTop>.ForexstartContestRegistrationGridRegistrationFormElementTopText{
    display: none;
}

.ForexstartContestRegistrationGridRegistrationFormElementError>.ForexstartContestRegistrationGridRegistrationFormElementInput>.ForexstartContestRegistrationGridRegistrationFormElementInputIn>.ForexstartContestRegistrationGridRegistrationFormElementTopTextError{
    display: block;
}
.ForexstartContestRegistrationGridRegistrationFormElementError2>.ForexstartContestRegistrationGridRegistrationFormElementInput>.ForexstartContestRegistrationGridRegistrationFormElementInputIn>.ForexstartContestRegistrationGridRegistrationFormElementTopTextError2{
    display: block;
}
.ForexstartContestRegistrationGridRegistrationFormElementError3>.ForexstartContestRegistrationGridRegistrationFormElementInput>.ForexstartContestRegistrationGridRegistrationFormElementInputIn>.ForexstartContestRegistrationGridRegistrationFormElementTopTextError3{
    display: block;
}
.ForexstartContestRegistrationGridRegistrationFormElementError4>.ForexstartContestRegistrationGridRegistrationFormElementInput>.ForexstartContestRegistrationGridRegistrationFormElementInputIn>.ForexstartContestRegistrationGridRegistrationFormElementTopTextError4{display: block;}
.ForexstartContestRegistrationGridRegistrationFormElementError5>.ForexstartContestRegistrationGridRegistrationFormElementInput>.ForexstartContestRegistrationGridRegistrationFormElementInputIn>.ForexstartContestRegistrationGridRegistrationFormElementTopTextError5{display: block;}
.ForexstartContestRegistrationGridRegistrationFormElementError6>.ForexstartContestRegistrationGridRegistrationFormElementInput>.ForexstartContestRegistrationGridRegistrationFormElementInputIn>.ForexstartContestRegistrationGridRegistrationFormElementTopTextError6{display: block;}
.ForexstartContestRegistrationGridRegistrationFormElementError7>.ForexstartContestRegistrationGridRegistrationFormElementInput>.ForexstartContestRegistrationGridRegistrationFormElementInputIn>.ForexstartContestRegistrationGridRegistrationFormElementTopTextError7{display: block;}
.ForexstartContestRegistrationGridRegistrationFormElementError8>.ForexstartContestRegistrationGridRegistrationFormElementInput>.ForexstartContestRegistrationGridRegistrationFormElementInputIn>.ForexstartContestRegistrationGridRegistrationFormElementTopTextError8{display: block;}
.ForexstartContestRegistrationGridRegistrationFormElementErrorMail>.ForexstartContestRegistrationGridRegistrationFormElementInput>.ForexstartContestRegistrationGridRegistrationFormElementInputIn>.ForexstartContestRegistrationGridRegistrationFormElementTopTextErrorCheck{
    display: block;
}

.ForexstartContestRegistrationGridRegistrationFormElementError>.ForexstartContestRegistrationGridRegistrationFormElementInput>.ForexstartContestRegistrationGridRegistrationFormElementInputIn>.ForexstartContestRegistrationGridRegistrationFormElementInputInput{
    border: solid 1px #e52d50;
    background-color: #ffecee;
}

@media only screen and (max-width: 900px) {
    .ForexstartContestRegistrationGridRegistrationFormElement{
        grid-template-columns : 150px auto;
    }
    .ButtonForexstartContestRegistration{
        margin-left: 200px;
    }
}

@media only screen and (max-width: 600px) {
    .ForexstartContestRegistrationGridRegistrationFormElement{
        display: block;
    }
    .ButtonForexstartContestRegistration{
        margin-left: 0;
    }
}
@media only screen and (max-width: 400px) {
    .ForexstartContestRegistrationGridRegistrationFormElementInput>.ForexstartContestRegistrationGridRegistrationFormElementInputIn>input{
        width: 30px;
    }
    .InvestRegPasswordClose,.InvestRegPasswordOpen{
        left: 230px;
    }
    .ForexstartContestRegistrationIn{
        padding-left: 15px;
    }
}

.ForexstartContestRegistration>.RegInvestNoVisible{
    display: none;
}

.ForexstartContestRegistrationGridRegistrationFormElementTopTextTradingClient{
    font-size: 15px;
    color: #47478a;
    font-weight: 500;
}

.ForexstartContestRegistrationButtomText>a>img{
    margin-left: 10px;
}
.ForexstartContestRegistrationButtomText{
    padding-top: 15px;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 800px) {
    .TraderContestFormRegistration{
        grid-template-columns: auto;
    }
    .TraderContestFormRegistrationLeftTop,.TraderContestFormRegistrationLeftBottom{
        grid-column: 1;
        width: 100%;
        margin: auto;
    }
    .TraderContestFormRegistrationLeftBottomIn{
        text-align: center;
    }
}


.ForexstartContestRegistrationSuccess{
    padding-top: 40px;
}

.ForexstartContestRegistrationSuccessTitle{
    display: flex;
    font-size: 17px;
    color: #181818;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #eef1f2;
}

.ForexstartContestRegistrationSuccessTitle>img{
    padding-right: 10px;
}

.ForexstartContestRegistrationSuccessAllInfo{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 293px;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
    padding: 24px 28px 20px 24px;
    margin-bottom: 30px;
}

.ForexstartContestRegistrationSuccessAllInfoElement{
    display: flex;
}



.ForexstartContestRegistrationSuccessAllInfoElementText{
    width: 300px;
    font-weight: 500;
    font-size: 15px;
    color: #181818;
}
.ForexstartContestRegistrationSuccessAllInfoElementInfo{
    font-weight: 400;
    font-size: 15px;
    color: #181818;
}

.ForexstartContestRegistrationSuccessBottomLink{
    display: flex;
}


@media only screen and (max-width: 600px) {
    .ForexstartContestRegistrationSuccessAllInfo{
        height: auto;
        padding-bottom: 0;
    }

    .ForexstartContestRegistrationSuccessAllInfoElement{
        display: block;
        padding-bottom: 20px;
    }
    .ForexstartContestRegistrationSuccessAllInfoElementText{
        width: auto;
    }
    .ForexstartContestRegistrationSuccessBottomLink>a>div{
        margin-top: 0;
    }
}
