.FriendsAndForexResultTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FriendsAndForexMainH2{
    font-size: 17px;
    font-weight: 700;
}

.FriendsAndForexYouNeed{
    font-size: 15px;
    line-height: 2;
}

.FriendsAndForexMain{
    margin-bottom: 30px;
}

@media only screen and (max-width: 760px) {
.FriendsAndForexMain>img{
    width: 100%;
}
}