.InvestNextStepFlex>.TerminalAndroidInPartStore{
    margin-left: 20px;
}
.InvestNextStepFlex{
    display: flex;
    margin-top: 20px;
    align-items: center;
}

.InvestNextStepIn{
    max-width: 1000px;
    width: 100%;
    height: 100%;
}

.InvestNextStepMain{
    width: 575px;
    padding-top: 33px;
    padding-bottom: 68px;
}

.InvestRegistrationGridRegistrationText{
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #8a8d91;
}

.InvestRegistrationGridRegistrationTextMail{
    color: blue;
    text-decoration: underline;
    padding-top: 5px;
}

.InvestRegistrationGridRegistrationTextSmall{
    margin-top: 30px;
    margin-bottom: 5px;
    color: black;
    font-weight: 700;
    font-size: 18px;
}


.SendQuestionMainBottomError{
    color: rgb(255,104,104);
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 13px;
    padding-top: 12px;
    grid-column: 2;
}

.SendQuestionMainBottomMainIn>form>.EmailOutFlex>input{
    height: 41px;
    font-size: 18px;
    width: 198px;
}

.InvestNextStepMain>form>input{
    width: 100%;
    height: 46px;
    padding-left: 20px;
    font-size: 24px;
}

.CompanyContactPlace03{
    margin-top: 20px;
}
@media only screen and (max-width: 680px) {
    .InvestNextStepMain>form>input{
        width: calc(100% - 60px);
    }
    .InvestNextStepMain{
        width: auto;
        padding-left: 15px;
        padding-right: 15px;
    }
}


.SendQuestionMainBottomSuccess>div{
    padding-left: 30px;
    padding-right: 30px;
}
.SendQuestionMainBottomSuccess{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SendQuestionMainBottomSuccessText{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.InvestNextStepIn>.StandartNotVisible{
    display: none;
}