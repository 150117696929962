/*.TraderAllArchive>div>div>.ContestMenu{*/
/*    width: 100%;*/
/*}*/

.QueryAllContestResultsLoading{
    font-size: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

}

/*.QueryAllContestResultsLoading>img{*/
/*    */
/*}*/