.TisInvestmentCommonFlex>a{
    text-decoration: none;
}

.TisInvestmentCommonFlex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.TisInvestmentCommonGrid01{
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 45px;
    margin-bottom: 67px;
    grid-column-gap: 20px;
}

.TisInvestmentCommonGrid0101{

    font-weight: 400;
    font-size: 21px;
    line-height: 28px;
}

.TisButtonGreenImg{
    position: relative;
    top: 3px;
    left: 5px;
}

.TisInvestmentCommonGrid02{
    display: grid;
    grid-template-columns: 25fr 38fr 14fr;
    grid-column-gap: 40px;
}

.TisInvestmentCommonGrid0202{
    width: 100%;
    position: relative;
    top: -40px;
}

.TisInvestmentCommonGrid0201>div{
    padding-right: 50px;
}

.TisInvestmentCommonGrid0201Title{

    font-weight: 400;
    font-size: 19px;
    line-height: 18px;
    color: #2a9061;
    padding-bottom: 10px;
}

.TisInvestmentCommonGrid020201{

    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #308f62;
    padding-bottom: 100px;
}

.TisInvestmentCommonGrid020202{
    border-image-source: url("../../../static/img/TIS/Direct/TISDirect02.png");
    border-image-width: 6px;
    border-image-repeat: stretch;
    border-left: solid 6px;
    border-image-slice: 0 0 0 30;
    padding: 10px 0 23px 23px;
    margin-bottom: 15px;

}

.TisInvestmentCommonGrid020203{
    border-image-source: url("../../../static/img/TIS/Direct/TISDirect03.png");
    border-image-width: 6px;
    border-image-repeat: initial;
    border-style: solid;
    border-image-slice: 0 0 0 30;
    padding: 10px 0 23px 23px;
}

.TisInvestmentCommonGrid02020201{

    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    padding-bottom: 5px;
}

.TisInvestmentCommonGrid02020202{

    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    padding-bottom: 10px;
}

.TisInvestmentCommonGrid02020203{

    font-weight: 400;
    font-size: 11px;
    line-height: 19px;
    color: #a2a2a2;
}

@media only screen and (max-width: 1100px) {
    .TisInvestmentCommonGrid020201{
        padding-bottom: 80px;
    }
}


@media only screen and (max-width: 1000px) {
    .TisInvestmentCommonGrid020201{
        padding-bottom: 60px;
    }
}

@media only screen and (max-width: 900px) {
    .TisInvestmentCommonGrid02{
        grid-template-columns: auto 200px;
        grid-column-gap: 22px;
    }

    .TisInvestmentCommonGrid0201{
        grid-row: 1;
        grid-column: 1/span 2;
        padding-bottom: 20px;
    }
    .TisInvestmentCommonGrid0202{
        top: 0;
    }
    .TisInvestmentCommonGrid020201{
        padding-bottom: 200px;
    }
}

@media only screen and (max-width: 800px) {
    .TisInvestmentCommonGrid02{
        grid-template-columns: auto 200px;
        grid-column-gap: 25px;
    }
    .TisInvestmentCommonGrid0202Out{
        position: relative;
    }

    .TisInvestmentCommonGrid020201{
        padding-bottom: 130px;
    }
    .TisInvestmentCommonGrid02{
        position: relative;
    }
    .TisInvestmentCommonGrid0202{
        height: 650px;
        width: 520px;
        position: absolute;
        right: 0;
    }
}

@media only screen and (max-width: 600px) {

    .TisInvestmentCommonGrid020201{
        padding-bottom: 50px;
    }

    .TisInvestmentCommonGrid0202{
        height: 500px;
        width: 400px;
    }
}

.TisInvestmentCommonGrid0203Title{

    font-weight: 700;
    font-size: 23px;
    line-height: 28px;
    color: #181818;
    padding-bottom: 60px;
    width: 266px;
}

.TisInvestmentCommonGrid0201Out{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.TisInvestmentCommonGrid0203Flex{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 35px;
}

.TisInvestmentCommonGrid0203FlexElementText{
    display: flex;
}

.TisInvestmentCommonGrid0203FlexElement{
    max-width: 220px;
}

.TisInvestmentCommonGrid0203FlexElementImg{
    height: 238px;
}

.TisInvestmentCommonGrid0203FlexElementText01{

    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
    color: #181818;
    padding-right: 5px;
}

.TisInvestmentCommonGrid03{
    margin-top: -100px;
}

@media only screen and (max-width: 1000px) {
    .TisInvestmentCommonGrid03{
        margin-top: 0;
        background-color: #eaf1f7;
    }
    .TisInvestmentCommonGrid0203Title{
        padding-top: 40px;
        width: auto;
    }
}

.TisInvestmentCommonGrid0202Back{
    position: relative;
    z-index: -1;
    right: 20px;
    bottom: 100px;
}

@media only screen and (max-width: 800px) {
    .TisInvestmentCommonGrid03{
        margin-top: 130px;
    }
    .TisInvestmentCommonGrid0203Title{
        text-align: center;
    }

    .TisInvestmentCommonGrid0203Flex{
        display: grid;
        grid-template-columns: auto;
        padding-bottom: 35px;
        justify-content: center;
    }
    .TisInvestmentCommonGrid0203FlexElement{
        padding-bottom: 60px;
    }
}

.TisButtonGreen{
    height: 40px;
    width: 204px;
    background-image: linear-gradient(to top, #4ca800, #5bb500);
    display: flex;
    color: white;
    font-weight: 700;
    justify-content: space-around;
    align-items: center;
    border-radius: 3px;
}

.TisInvestmentCommonGrid02{
    padding-right: 10px;
}


@media only screen and (max-width: 600px) {
    .TisInvestmentCommonFlex,.TisInvestmentCommonGrid01{
        display: block;
    }
    .TisInvestmentCommonGrid01{
        padding-left: 25px;
        padding-right: 25px;
    }
    .TisInvestmentCommonGrid0101,.TisButtonGreen{
        margin-bottom: 30px;
    }
    .TisInvestmentCommonGrid01>.TisInvestmentCommonGrid0102,.TisInvestmentCommonGrid01>.TisInvestmentCommonGrid0101{
        padding-left: 0;
        padding-right: 0;
    }
}