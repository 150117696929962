.AboutAdvantagesMain{
    box-shadow: 0 0 25px 0 rgba(8, 78, 120, 0.15);
    background-color: #ffffff;
    border-radius: 5px;
    padding: 30px 30px 13px 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
@media only screen and (max-width: 750px) {
    .AboutAdvantagesMain {
        justify-content: space-around;
    }
}

.AboutAdvantagesMainElement{
    width: 260px;
    margin-bottom: 17px;
    margin-right: 20px;
    margin-left: 20px;
}

.AboutAdvantagesMainElementTitle{
    font-size: 15px;
    line-height: 23px;
    font-weight: 700;
    margin-top: 15px;
}
.AboutAdvantagesMainElementText{
    font-size: 13px;
    line-height: 27px;
    font-weight: 400;
    margin-top: 5px;
}

.AboutAdvantagesBottom{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
}

.AboutAdvantagesBottomElementTitle{
    font-size: 19px;
    line-height: 23px;
    font-weight: 700;
    margin-bottom: 15px;
}

.AboutAdvantagesBottomElement{
    width: 260px;
    margin-bottom: 40px;
    margin-right: 20px;
    margin-left: 20px;
}

.AboutAdvantagesBottomElementMainElement{
    display: flex;
    font-size: 13px;
    line-height: 30px;
    font-weight: 400;
}

.AboutAdvantagesBottomElementMainElementImg{
    margin-right: 10px;
    position: relative;
    top: 4px;
}

.AboutAdvantagesBottomElementForum{
    background: url("../../../static/img/About/Advantages/forum.png") center;
    width: 144px;
    height: 106px;
    font-size: 19px;
    line-height: 23px;

}

.AboutAdvantagesBottomElementForumIn{
    padding: 22px;
}