.MutationCreateClientLoading>img{
    margin-right: 10px;
}
.MutationCreateClientLoading{
    font-size: 18px;
    margin: 20px 0 0 80px;
    display: flex;
    align-items: center;
}

.MutationCreateResult{
    font-size: 18px;
    margin: 20px 0 0 80px;
    display: flex;
    align-items: center;
}