.IfYouHaveQuestions{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.IfYouHaveQuestionsText{
    padding-left: 10px;
}

.IfYouHaveQuestionsTextLink{
    color: #218ed2;
    text-decoration: underline;
    cursor: pointer;
}

.IfYouHaveQuestionsActive{
    position: fixed;
    z-index: 1000;
    top: 220px;
    right: calc(50% - 571px );
    background-color: white;
    width: 336px;
    border: 1px solid #8d8d8d;

}

@media only screen and (max-width: 1200px) {
    .IfYouHaveQuestionsActive{
        right: calc(50% - 546px );
    }
}
@media only screen and (max-width: 1157px) {
    .IfYouHaveQuestionsActive{
        right: 25px;
    }
}

.IfYouHaveQuestionsAllBlack{
    position: fixed;
    z-index: 900;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
}

.IfYouHaveQuestionsActiveIn{
    padding: 14px 18px 20px 18px;
}

.IfYouHaveQuestionsTop{
    display: flex;
    justify-content: space-between;
}

.IfYouHaveQuestionsTopTitle{
    font-size: 15px;
    font-weight: 700;
    color: black;
}

.TextPlaceHolder{
    font-size: 13px;
    font-weight: 700;
    color: black;
}

.PersonalPageInputText{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.PersonalPageInputText:nth-of-type(2){
    margin-top: 15px;
}

.PersonalPageInputError>label>.PersonalPageInputText>.PersonalPageInputErrorText{
    display: flex;
}

.PersonalPageInputErrorText{
    align-items: center;
    color: #c53838;
    font-size: 11px;
    display: none;
}

.IfYouHaveQuestionsActiveInput{
    width: 100%;
    height: 24px;
    font-size: 13px;
    margin-top: 10px;
}

.IfYouHaveQuestionsTextarea{
    width: 100%;
    height: 165px;
}

.TraderFeedbackSuccess{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TraderFeedbackSuccess>img{
    margin-top: 60px;
    margin-bottom: 32px;
}

.IfYouHaveQuestionsActiveIn>.StandartNotVisible{
    display: none;
}

.IfYouHaveQuestionsTopExit{
    cursor: pointer;
}

@media only screen and (max-width: 336px) {
    .IfYouHaveQuestionsActive{
        width: 100%;
    }
}
@media only screen and (max-width: 400px) {
    .IfYouHaveQuestionsActive{
        right: calc(50% - 170px);
    }
}
@media only screen and (max-width: 350px) {
    .IfYouHaveQuestionsActive{
        right: 0;
        /*width: calc(100% - 20px);*/
    }
    .IfYouHaveQuestionsActiveIn{
        width: auto;
    }
}

.IfYouHaveQuestionsTextPhone{
    display: none;
    margin-left: 10px;
}
@media only screen and (max-width: 700px) {
    .IfYouHaveQuestionsText{
        display: none;
    }
    .IfYouHaveQuestionsTextPhone{
        display: block;
    }
}
