.ContestPrizes>.ContestGridWithMenu>.ContestMenu{
    margin-top: 0;
}

.ContestPrizesGrid{
    display: grid;
    grid-template-columns: 442px auto;
    grid-column-gap: 18px;
}

@media only screen and (max-width: 800px) {
    .ContestPrizesGrid{
        display: block;
    }
}


.ContestPrizesGridLeft{
    background-color: white;
}
.ContestPrizesGridRight{
    color: #181818;
    background-color: white;

}

.ContestPrizesGridRightIn{
    padding: 26px 27px 27px 31px;
}

.ContestPrizesGridRightText01{
    font-size: 18px;
    line-height: 25px;
}

.ContestPrizesGridRightText02{
    font-size: 19px;
    line-height: 25px;
}

.ContestPrizesGridRightTitle{
    font-weight: 500;
    font-size: 19px;
    line-height: 25px;
    padding-top: 20px;
}
.ContestPrizesGridOut {
    padding-right: 24px;
    padding-left: 21px;
}

.ContestPrizesGridLeft>img{
    width: 100%;
}