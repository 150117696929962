.AboutAddEntry>.StandartPage>.StandartTitle{
    padding-bottom: 0;
}

.AboutAddEntryGrid{
    display: grid;
    grid-template-columns: auto 200px;
    align-items: center;
    grid-column-gap: 30px;
    font-weight: 400;
    font-size: 17px;
    line-height: 27px;
}

.AboutUserMap>.StandartPage>.StandartTitle{
    padding-bottom: 0;
}

.AboutAddEntryGrid02{
    width: 100%;
    height: 90px;
    background-size: cover;
    box-shadow: 0 0 25px 0 rgba(0, 86, 142, 0.15), inset 0 0 0 5px #ffffff;
    background: #edf2f7 url("../../../static/img/About/UsersMap/AboutUsersMap01.svg") center;
    display: grid;
    grid-template-columns: 88px auto;
    grid-column-gap: 20px;
    align-items: center;
}

@media only screen and (max-width: 900px) {
    .AboutAddEntryGrid{
        display: grid;
        grid-template-columns: auto;
        grid-row-gap: 30px;
        grid-template-rows: auto auto;
    }
}


.AboutAddEntryGrid0202>a{
    margin-left: 5px;
    margin-right: 5px;
}

.AboutAddEntryGrid0201>img{
    position: relative;
    top: 3px;
    left: 7px;
}

@media only screen and (max-width: 450px) {
    .AboutAddEntryGrid02{
        height: auto;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 15px;
    }
}

.AboutAddEntryUl{
    margin-left: 20px;
}

.AboutAddEntryLi{
    font-size: 13px;
    line-height: 1.8;
}

.AboutAddEntryFlagTextIn{
    margin-right: 10px;
    margin-left: 10px;

}
.AboutAddEntryFlag>div{
    display: flex;
}
.AboutAddEntryFlag{
    font-weight: 700;
    padding-bottom: 20px;
}