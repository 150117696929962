.InvestRight{

}

.InvestRightMenu{
    padding: 6px 18px 10px;
    border: 1px solid #ced4d9;
    border-top-width: 3px;
    display: flex;
    flex-direction: column;
}

.InvestRightText{
    margin-top: 15px;
    background-color: #ebfbe1;
}
.InvestRightTextTitle{
    font-size: 17px;
    font-weight: bold;
    line-height: 1.569;
    padding: 18px;
}
.InvestRightTextText{
    font-size: 13px;
    line-height: 18px;
    padding: 0 18px 18px 18px;
}

.InvestRightTextTextElement{
    margin-top: 15px;
    border-bottom: 1px solid black;
    padding-bottom: 15px;
}

.InvestRightTextGreen{
    font-size: 15px;
    color: #379000;
    margin-top: 5px;
}

.InvestRightMenu>a{
    margin-top: 7px;
}
.InvestRightMenu>.InvestRightMenuLinkActive{
    font-weight: 700;
    color: black;
    text-decoration: none;
}