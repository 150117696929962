
.HeaderAllTitleIMG>a>img{
    position: relative;
    top: 1px;
}

.IndexMainTopMain{
    height: 246px;
    display: grid;
    grid-template-columns: 753px auto;
    margin-bottom: 18px;
}

@media only screen and (max-width: 1030px) {
    .IndexMainTopMain{
        grid-template-columns: 723px auto;
    }
}


/*.IndexMainBannerMainThreeButtons{*/
    /*display: grid;*/
    /*grid-template-rows: 1fr 1fr 1fr;*/
    /*height: 100%;*/
/*}*/

.IndexMainBannerMainThreeButtons>a{
    text-decoration: none;
}

.IndexMainBannerMainThreeButtonsElement{
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    background-image: linear-gradient(to top, #fdfdfd, #f9f9f9);
    border-bottom: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
    align-items: center;
    color: #033569;
    height: 84px;
}




.IndexMainBannerMainThreeButtonsElement>img{
    padding-right: 15px;
}


@media only screen and (max-width: 1000px) {
    .IndexMainBannerMainThreeButtons>a:nth-of-type(3)>.IndexMainBannerMainThreeButtonsElement{
        border-right: none;
    }
    .IndexMainTopMain{
        grid-template-columns: auto;
        height: auto;
    }
}




.IndexMainElementsOut{
    overflow: hidden;
}

.IndexMainElementsOut>.IndexMainElements{
    margin-top: 28px;
}

.IndexMainElements{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 28px;
    margin-top: 28px;
    justify-content: space-between;

}

.IndexMainWebinar{
    height: 145px;
    object-fit: contain;
    /*border: solid 1px #ececec;*/
    background-color: white;
    margin-top: 26px;
}

.IndexMainWebinar01{
    border-radius: 3px;
    grid-column: 1/span 2;
}
.IndexMainWebinar02{
    border-radius: 3px;
    grid-column: 3/span 2;
}

.IndexMainWebinar02In{
    box-shadow: inset 0 0 59px 0 rgba(14, 106, 166, 0.05);
    border: solid 5px rgba(255, 255, 255, 0.7);
}

.IndexMainWebinarIMG{
    height: 133px;
}

.IndexMainWebinarInOut{
    border: solid 1px #ececec;
    border-radius: 5px;
    padding: 5px;
}

.IndexMainWebinarIn{
    /*background-color: rgba(200,217,227,0.7);*/
    box-shadow: inset 0 0 59px 0 rgba(0, 0, 0, 0.07);
    display: grid;
    grid-template-columns: 133px auto;
    grid-column-gap: 20px;
}

.IndexMainPromo,.IndexMainPromoText{
    padding-right: 40px;

}

.IndexMainPromo>a{
    text-decoration: none;
}
.IndexMainPromo{
    margin-bottom: 22px;
}

.IndexMainPromoGrid{
    font-size: 19px;
    display: grid;
    grid-template-rows: auto 62px;
}

.IndexMainPromoText{
    display: flex;
    align-self: center;
}

.IndexMainWebinarGrid{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.IndexMainWebinarGridTitle{
   font-weight: 700;
    font-size: 17px;
}

.IndexMainWebinarGrid>.IndexMainWebinarGridText>a{
   color: #75a02d;
}

.IndexMainWebinarGridTime{
    display: flex;
}

.IndexMainWebinarGridTime>img{
    padding-right: 5px;
}

.IndexTopButtonMobiusHover{
    display: none;
}

.IndexMainBannerMainThreeButtonsElement:hover>.IndexTopButtonMobiusHover,.IndexMainBannerMainThreeButtonsElement:hover>.IndexMainBannerMainThreeButtonsText{
    display: block;
    color: #2288da;
}
.IndexMainBannerMainThreeButtonsElement:hover>.IndexTopButtonMobius{
    display: none;
}





@media only screen and (max-width: 1100px) {
    .IndexMainElements>.IndexProviders{
        grid-column: 1/span 2;
        width: 250px;
        margin: auto;
    }
    .IndexMainElements>.IndexSpreads{
        grid-column: 3/span 2;
        margin: auto;
    }

    .IndexMainElements>.IndexAdvantages{
        grid-row: 2;
        grid-column: 1/span 4;
        margin-top: 10px;
    }
    .IndexMainElements>.IndexMainWebinar01, .IndexMainElements>.IndexMainWebinar02{
        grid-row: 3;
    }
}

@media only screen and (max-width: 1100px) {
    .IndexMainElements>.IndexMainWebinar01{
        grid-row: 3;
        grid-column: 1/span 4;
        justify-content: center;
    }
    .IndexMainElements>.IndexMainWebinar02{
        grid-row: 4;
        grid-column: 1/span 4;
    }
}


@media only screen and (max-width: 610px) {
    .IndexMainElements{
        grid-row-gap: 10px;
    }
    .StandartPage>.IndexMainElements>.IndexProviders{
        grid-column: 1/span 4;
    }
    .StandartPage>.IndexMainElements>.IndexSpreads{
        grid-column: 1/span 4;
        grid-row: 2;
    }
    .StandartPage>.IndexMainElements>.IndexAdvantages{
        grid-column: 1/span 4;
        grid-row: 3;
    }
    .StandartPage>.IndexMainElements>.IndexMainWebinar01{
        grid-row: 4;
    }
    .StandartPage>.IndexMainElements>.IndexMainWebinar02{
        grid-row: 5;
    }
}

@media only screen and (max-width: 820px) {
    .IndexMainTopMain>.IndexMainBannerMainThreeButtons{
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (max-width: 580px) {
    .IndexMainTopMain>.IndexMainBannerMainThreeButtons{
        grid-template-columns: auto;
    }
}

