@media only screen and (max-width: 1200px) {
    .InvestHow{
        margin-right: 20px;
        margin-left: 20px;
    }

}

.InvestHow01{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
    width: 100%;
}

.InvestHow01Step{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.47);
    border: solid 1px #e7e7e7;
    background-image: linear-gradient(to top, #e3e3e3, #ffffff);
    border-radius: 10px;
    position: relative;
    max-width: 752px;
}

.InvestHow01Img{
    padding-left: 50px;
}

.InvestHow01StepNumber{
    position: absolute;
    right: 30px;
    bottom: 0;
}

.InvestHow01StepNumber>img{
    position: relative;
    top: 7px;
}

.InvestHow01StepText{
    padding: 26px 110px 24px 23px;
    font-size: 14px;
    color: #645b67;
    line-height: 1.71;
}

.InvestHowBottom{
    border: 1px solid #bfb9c0;
    margin-top: 100px;
    display: flex;
    border-radius: 5px;
    justify-content: space-around;
    padding: 10px;
}

.InvestHowBottom02Top,.InvestHowBottom04Top{
    background-image: url("../../static/img/Invest/How/ForexLineRight.png");
    width: 219px;
    height: 45px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #7a717b;
}
.InvestHowBottom02Bottom,.InvestHowBottom04Bottom{
    width: 219px;
    height: 45px;
    background-image: url("../../static/img/Invest/How/ForexLineLeft.png");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #4a5c42;
}

.InvestHowBottom04Bottom>img,.InvestHowBottom02Bottom>img{
    padding-right: 10px;
}

@media only screen and (max-width: 950px) {
    .InvestHow01{
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        margin-top: 21px;
    }
    .InvestHow01Step{
        background: none;
        box-shadow: none;
        border: none;
    }
    .InvestHow01StepNumber{
        display: none;
    }
    .InvestHow01Img>img{
        padding-right: 20px;
    }
}
@media only screen and (max-width: 800px) {
    .InvestHow01{
        display: grid;
        justify-content: center;

    }
    .InvestHow01Img{
        grid-row: 1;
        margin: auto;
        padding-left: 0;
    }
    .InvestHow01StepText{
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media only screen and (max-width: 850px) {
    .InvestHowBottom{
        border: none;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }
    .InvestHowBottom02Bottom,.InvestHowBottom04Bottom{
        transform: rotate(90deg);
        width: 200px;
        position: relative;
        left: -20px;
        top: 50px;

    }
    .InvestHowBottom02Top,.InvestHowBottom04Top{
        transform: rotate(90deg);
        position: relative;
        left: 40px;
        top: 90px;
    }
    .InvestHowBottom02,.InvestHowBottom04{
        margin-bottom: 150px;
    }
}