.TradingOptions{
    position: relative;
    margin-bottom: 40px;
}

.TradingOptions>.ButtonRedInline>a{
    text-decoration: none;
}
.TradingOptions>.ButtonRedInline{
    padding-top: 36px;
    padding-bottom: 36px;
    display: flex;
    justify-content: center;
}

.TradingOptionsText{
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    color: #353b50;
    text-align: center;
}

.TradingOptionsTitle{
    text-align: center;
    font-weight: 400;
    font-size: 30px;
    line-height: 25px;
    padding-top: 30px;
    color: #ea4f40;
}

.TradingOptionsPadding{
    padding-top: 14px;
}

.TradingOptionsChange{
    padding-top: 28px;
    padding-bottom: 32px;
    display: flex;
    justify-content: center;
}

.TradingOptionsChange>div{
    cursor: pointer;
}

.TradingOptionsChangePart{
    width: 180px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.TradingOptionsPartActive{
    object-fit: contain;
    background-image: linear-gradient(to right, #3f4868, #4a3559 100%);
    height: 60px;
    color: white;
}

.TradingOptionsPartActiveNot{
    object-fit: contain;
    border: #f5f6f6 solid 2px;
    height: 56px;
    color: #2f4563;
}

.TradingOptionsChangePart:first-of-type{
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    padding-left: 5px;
}

.TradingOptionsChangePart:nth-of-type(2){
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    padding-right: 5px;
}

.TradingOptionsChangePart:first-of-type>.TradingOptionsChangeText{
    left: -5px;
}

.TradingOptionsChangePart:nth-of-type(2)>.TradingOptionsChangeText{
    left: 5px;
}

.TradingOptionsChangeText{
    position: relative;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    top: -4px;

}

.TradingOptionsTopImageOut{
    margin: auto;
    display: flex;
    justify-content: center;
    text-align: center;
}

.TradingOptionsTopImageOut>a{
    padding-left: 5px;
    padding-right: 5px;
}

.TradingOptionsTopImage{
    display: inline-block;
    width: auto;
    background-color: #f5f6f6;
    font-weight: 400;
    font-size: 19px;
    line-height: 23px;
    color: #2f4563;
    padding: 16px 28px 16px 29px;
}

.TradingOptionsMainImage{
    display: flex;
    justify-content: center;
    position: relative;
}

.TradingOptionsMainImageIn{
    width: auto;
    position: relative;
}

.TradingOptionsMainImageMainOut{
    position: absolute;
    top: 59px;
}

.TradingOptionsBottomBlue{
    background-image: linear-gradient(to right, #3f4868, #4a3559 100%);
    color: white;
    padding-bottom: 25px;
    position: relative;
    z-index: -3;
    margin-top: -56px;
}

.TradingOptionsBottomBlueTitle{
    text-align: center;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    padding-top: 86px;
    padding-bottom: 26px;
}

.TradingOptionsBottomBlueFlexElementMain{
    margin: auto;
    height: 190px;
}

.TradingOptionsBottomBlueFlexElement{
    max-width: 268px;
    display: grid;
    grid-template-rows: 200px auto;
}

.TradingOptionsBottomBlueFlexElementNumber{
    font-weight: 100;
    font-size: 34px;
    line-height: 21px;
    color: #dbff95;
    position: relative;
    top: 20px;
    right: 5px;
}

.TradingOptionsBottomBlueFlex{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-bottom: 20px;
}

.TradingOptionsBottomBlueFlexElementText{
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;

    text-align: center;
}

@media only screen and (max-width: 710px) {
    .TradingOptionsMainImageBorder{
        display: none;
    }

    .TradingOptionsMainImageMain{
        position: relative;
        top: 0;
    }

    .TradingOptionsBottomBlue{
        margin-top: 0;
    }
    .TradingOptionsBottomBlueTitle{
        padding-top: 25px;
    }
}

.TradingOptionsBottomBlueFlexElementImgPhone{
    display: none;
}

@media only screen and (max-width: 600px) {
    .TradingOptionsMainImageIn>.TradingOptionsBOHelp{
        display: none;
    }
    .TradingOptionsBottomBlueFlexElementImgPhone{
        display: block;
        width: 100%;
    }
    .TradingOptionsBottomBlueFlexElementImgPhone>img{
        width: 100%;
    }

    .TradingOptionsBottomBlueFlexElementImg,.TradingOptionsMainImageMain{
        display: none;
    }
    .TradingOptions>.StandartPageIn{
        padding: 0;
    }
    .TradingOptionsBottomBlueFlexElementText{
        font-size: 13px;
    }
    .TradingOptionsBottomBlueFlexElementNumber{
        top: 20px;
        right: 15px;
    }
    .TradingOptionsBottomBlueFlexElement{
        grid-template-rows: 150px auto;
    }
    .TradingOptionsBottomBlueTitle{
        padding-right: 10px;
        padding-left: 10px;
        font-size: 21px;
    }

}
@media only screen and (max-width: 400px) {

    .TradingOptionsChangePart:first-of-type {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    .TradingOptionsChangePart:nth-of-type(2) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
}

.TradingOptionsImgBack01,.TradingOptionsImgBack02{
    position: absolute;
    z-index: -1;
    top: 95px;
}

.TradingOptionsImgBack01{
    left: 100px;
}

.TradingOptionsImgBack02{
right: 100px;
}

@media only screen and (max-width: 600px) {
    .TradingOptionsImgBack01,.TradingOptionsImgBack02{
        display: none;
    }

}

.TradingOptionsBOHelp{
    display: flex;
    position: absolute;
    z-index: 5;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #353b50;

}

.TradingOptionsBOHelpName{
    top: 85px;
    left: -160px;
    width: 420px;

}

@media only screen and (max-width: 1100px) {
    .TradingOptionsBOHelpName{
        top: 75px;
        left: 260px;
    }
}

.TradingOptionsBOHelpPeriod{
    top: 170px;
    left: -160px;
    width: 350px;
}

@media only screen and (max-width: 1100px) {
    .TradingOptionsBOHelpPeriod{
        top: 160px;
        left: 190px;
    }
}


.TradingOptionsBOHelpAmount{
    top: 200px;
    left: -160px;
    width: 340px;
}

@media only screen and (max-width: 1100px) {
    .TradingOptionsBOHelpAmount{
        top: 190px;
        left: 190px;
    }
}

.TradingOptionsBOHelpButtons{
    top: 240px;
    left: -160px;
    width: 350px;
}

@media only screen and (max-width: 1100px) {
    .TradingOptionsBOHelpButtons{
        top: 220px;
        left: 190px;
    }
}

.TradingOptionsBOHelpHistory{
    top: 380px;
    left: -160px;
    width: 350px;
}

@media only screen and (max-width: 1100px) {
    .TradingOptionsBOHelpHistory{
        top: 360px;
        left: 190px;
    }
}

.TradingOptionsBOHelpNowOptions{
    top: 440px;
    left: -160px;
    width: 350px;
}

@media only screen and (max-width: 1100px) {
    .TradingOptionsBOHelpNowOptions{
        top: 420px;
        left: 190px;
    }
}
.TradingOptionsBOHelpNameAmerica{
    top: 85px;
    left: -160px;
    width: 420px;

}

@media only screen and (max-width: 1100px) {
    .TradingOptionsBOHelpNameAmerica{
        top: 75px;
        left: 260px;
    }
}

.TradingOptionsBOHelpPeriodAmerica{
    top: 165px;
    left: -160px;
    width: 350px;
}

@media only screen and (max-width: 1100px) {
    .TradingOptionsBOHelpPeriodAmerica{
        top: 150px;
        left: 170px;
    }
}


.TradingOptionsBOHelpAmountAmerica{
    top: 190px;
    left: -160px;
    width: 340px;
}

@media only screen and (max-width: 1100px) {
    .TradingOptionsBOHelpAmountAmerica{
        top: 170px;
        left: 190px;
    }
}

.TradingOptionsBOHelpPriceAmerica{
    top: 220px;
    left: -160px;
    width: 340px;
}

@media only screen and (max-width: 1100px) {
    .TradingOptionsBOHelpPriceAmerica{
        top: 200px;
        left: 190px;
    }
}

.TradingOptionsBOHelpButtonsAmerica{
    top: 240px;
    left: -160px;
    width: 350px;
}

@media only screen and (max-width: 1100px) {
    .TradingOptionsBOHelpButtonsAmerica{
        top: 230px;
        left: 150px;
    }
}

.TradingOptionsBOHelpHistoryAmerica{
    top: 380px;
    left: -160px;
    width: 350px;
}

@media only screen and (max-width: 1100px) {
    .TradingOptionsBOHelpHistoryAmerica{
        top: 370px;
        left: 170px;
    }
}

.TradingOptionsBOHelpNowOptionsAmerica{
    top: 440px;
    left: -160px;
    width: 350px;
}

@media only screen and (max-width: 1100px) {
    .TradingOptionsBOHelpNowOptionsAmerica{
        top: 420px;
        left: 190px;
    }
}

.TradingOptionsBOHelpLineOut{
    display: flex;
    align-items: center;
    width: 100%;
}

.TradingOptionsBOHelpLine{
    margin-left: 10px;
    width: 100%; height: 1px;  background-color: #666666;
}



@media only screen and (max-width: 1100px) {
    .TradingOptionsBOHelp{
        display: block;
        position: absolute;
        width: 46px;
        height: 46px;
        border-radius: 8px;
        background-image: url("../../../static/img/Trader/Options/point.svg");
        z-index: 5;
    }

    .TradingOptionsBOHelp:hover{
        background-image: url("../../../static/img/Trader/Options/pointActive.svg");
        cursor: pointer;
    }


    .TradingOptionsBOHelp>.TradingOptionsBOHelpText{
        display: none;
        z-index: 200;
        top: -40px;
        position: absolute;

    }
    .TradingOptionsBOHelp>.TradingOptionsBOHelpTextRight{
        right: 0;

    }

    .TradingOptionsBOHelp:active>.TradingOptionsBOHelpText{
        display: block;
        padding: 11px 13px 11px 13px;
        color: white;
        background-color: #353b50;
        font-size: 13px;
        line-height: 19px;
    }


    .TradingOptionsBOHelpLineOut{
        display: none;
    }

    .TradingOptionsBOHelpLine{
        margin-left: 10px;
        width: 100%; height: 1px;  background-color: black;
    }
}

/*.TradingOptions{*/
    /*position: relative;*/
/*}*/

/*.TradingOptions>.ButtonRedInline{*/
    /*padding-top: 36px;*/
    /*padding-bottom: 36px;*/
/*}*/

/*.TradingOptionsText{*/
    /*font-weight: 400;*/
    /*font-size: 15px;*/
    /*line-height: 23px;*/
    /*padding-top: 30px;*/
    /*padding-left: 10px;*/
    /*padding-right: 10px;*/
    /*color: #353b50;*/
    /*text-align: center;*/
/*}*/

/*.TradingOptionsTitle{*/
    /*text-align: center;*/
    /*font-weight: 400;*/
    /*font-size: 30px;*/
    /*line-height: 25px;*/
    /*padding-top: 30px;*/
    /*color: #ea4f40;*/
/*}*/

/*.TradingOptionsPadding{*/
    /*padding-top: 14px;*/
/*}*/

/*.TradingOptionsChange{*/
    /*padding-top: 28px;*/
    /*padding-bottom: 32px;*/
    /*display: flex;*/
    /*justify-content: center;*/
/*}*/

/*.TradingOptionsChange>div{*/
    /*cursor: pointer;*/
/*}*/

/*.TradingOptionsChangePart{*/
    /*width: 180px;*/
    /*display: flex;*/
    /*justify-content: space-around;*/
    /*align-items: center;*/
/*}*/

/*.TradingOptionsPartActive{*/
    /*object-fit: contain;*/
    /*background-image: linear-gradient(to right, #3f4868, #4a3559 100%);*/
    /*height: 60px;*/
    /*color: white;*/
/*}*/

/*.TradingOptionsPartActiveNot{*/
    /*object-fit: contain;*/
    /*border: #f5f6f6 solid 2px;*/
    /*height: 56px;*/
    /*color: #2f4563;*/
/*}*/

/*.TradingOptionsChangePart:first-of-type{*/
    /*border-bottom-left-radius: 30px;*/
    /*border-top-left-radius: 30px;*/
    /*padding-left: 5px;*/
/*}*/

/*.TradingOptionsChangePart:nth-of-type(2){*/
    /*border-bottom-right-radius: 30px;*/
    /*border-top-right-radius: 30px;*/
    /*padding-right: 5px;*/
/*}*/

/*.TradingOptionsChangePart:first-of-type>.TradingOptionsChangeText{*/
    /*left: -5px;*/
/*}*/

/*.TradingOptionsChangePart:nth-of-type(2)>.TradingOptionsChangeText{*/
    /*left: 5px;*/
/*}*/

/*.TradingOptionsChangeText{*/
    /*position: relative;*/
    /*font-weight: 400;*/
    /*font-size: 15px;*/
    /*line-height: 23px;*/
    /*top: -4px;*/

/*}*/

/*.TradingOptionsTopImageOut{*/
    /*margin: auto;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*text-align: center;*/
/*}*/

/*.TradingOptionsTopImageOut>a{*/
    /*padding-left: 5px;*/
    /*padding-right: 5px;*/
/*}*/

/*.TradingOptionsTopImage{*/
    /*display: inline-block;*/
    /*width: auto;*/
    /*background-color: #f5f6f6;*/
    /*font-weight: 400;*/
    /*font-size: 19px;*/
    /*line-height: 23px;*/
    /*color: #2f4563;*/
    /*padding: 16px 28px 16px 29px;*/
/*}*/

/*.TradingOptionsMainImage{*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*position: relative;*/
/*}*/

/*.TradingOptionsMainImageIn{*/
    /*width: auto;*/
    /*position: relative;*/
/*}*/

/*.TradingOptionsMainImageMainOut{*/
    /*position: absolute;*/
    /*top: 59px;*/
/*}*/

/*.TradingOptionsBottomBlue{*/
    /*background-image: linear-gradient(to right, #3f4868, #4a3559 100%);*/
    /*color: white;*/
    /*padding-bottom: 25px;*/
    /*position: relative;*/
    /*z-index: -3;*/
    /*margin-top: -56px;*/
/*}*/

/*.TradingOptionsBottomBlueTitle{*/
    /*text-align: center;*/
    /*font-weight: 400;*/
    /*font-size: 30px;*/
    /*line-height: 35px;*/
    /*padding-top: 86px;*/
    /*padding-bottom: 26px;*/
/*}*/

/*.TradingOptionsBottomBlueFlexElementMain{*/
    /*margin: auto;*/
    /*height: 190px;*/
/*}*/

/*.TradingOptionsBottomBlueFlexElement{*/
    /*max-width: 268px;*/
    /*display: grid;*/
    /*grid-template-rows: 200px auto;*/
/*}*/

/*.TradingOptionsBottomBlueFlexElementNumber{*/
    /*font-weight: 100;*/
    /*font-size: 34px;*/
    /*line-height: 21px;*/
    /*color: #dbff95;*/
    /*position: relative;*/
    /*top: 20px;*/
    /*right: 5px;*/
/*}*/

/*.TradingOptionsBottomBlueFlex{*/
    /*display: flex;*/
    /*flex-wrap: wrap;*/
    /*justify-content: space-around;*/
    /*padding-bottom: 20px;*/
/*}*/

/*.TradingOptionsBottomBlueFlexElementText{*/
    /*font-weight: 400;*/
    /*font-size: 15px;*/
    /*line-height: 21px;*/

    /*text-align: center;*/
/*}*/

/*@media only screen and (max-width: 710px) {*/
    /*.TradingOptionsMainImageBorder{*/
        /*display: none;*/
    /*}*/

    /*.TradingOptionsMainImageMain{*/
        /*position: relative;*/
        /*top: 0;*/
    /*}*/

    /*.TradingOptionsBottomBlue{*/
        /*margin-top: 0;*/
    /*}*/
    /*.TradingOptionsBottomBlueTitle{*/
        /*padding-top: 25px;*/
    /*}*/
/*}*/

/*.TradingOptionsBottomBlueFlexElementImgPhone{*/
    /*display: none;*/
/*}*/

/*@media only screen and (max-width: 600px) {*/
    /*.TradingOptionsMainImageIn>.TradingOptionsBOHelp{*/
        /*display: none;*/
    /*}*/
    /*.TradingOptionsBottomBlueFlexElementImgPhone{*/
        /*display: block;*/
        /*width: 100%;*/
    /*}*/
    /*.TradingOptionsBottomBlueFlexElementImgPhone>img{*/
        /*width: 100%;*/
    /*}*/

    /*.TradingOptionsBottomBlueFlexElementImg,.TradingOptionsMainImageMain{*/
        /*display: none;*/
    /*}*/
    /*.TradingOptions>.StandartPageIn{*/
        /*padding: 0;*/
    /*}*/
    /*.TradingOptionsBottomBlueFlexElementText{*/
        /*font-size: 13px;*/
    /*}*/
    /*.TradingOptionsBottomBlueFlexElementNumber{*/
        /*top: 20px;*/
        /*right: 15px;*/
    /*}*/
    /*.TradingOptionsBottomBlueFlexElement{*/
        /*grid-template-rows: 150px auto;*/
    /*}*/
    /*.TradingOptionsBottomBlueTitle{*/
        /*padding-right: 10px;*/
        /*padding-left: 10px;*/
        /*font-size: 21px;*/
    /*}*/

/*}*/
/*@media only screen and (max-width: 400px) {*/

    /*.TradingOptionsChangePart:first-of-type {*/
        /*border-bottom-left-radius: 0;*/
        /*border-top-left-radius: 0;*/
    /*}*/

    /*.TradingOptionsChangePart:nth-of-type(2) {*/
        /*border-bottom-right-radius: 0;*/
        /*border-top-right-radius: 0;*/
    /*}*/
/*}*/

/*.TradingOptionsImgBack01,.TradingOptionsImgBack02{*/
    /*position: absolute;*/
    /*z-index: -1;*/
    /*top: 95px;*/
/*}*/

/*.TradingOptionsImgBack01{*/
    /*left: 100px;*/
/*}*/

/*.TradingOptionsImgBack02{*/
    /*right: 100px;*/
/*}*/

/*@media only screen and (max-width: 600px) {*/
    /*.TradingOptionsImgBack01,.TradingOptionsImgBack02{*/
        /*display: none;*/
    /*}*/

/*}*/

/*.TradingOptionsBOHelp{*/
    /*display: flex;*/
    /*position: absolute;*/
    /*z-index: 5;*/
    /*font-weight: 400;*/
    /*font-size: 13px;*/
    /*line-height: 17px;*/
    /*color: #353b50;*/

/*}*/

/*.TradingOptionsBOHelpName{*/
    /*top: 85px;*/
    /*left: -160px;*/
    /*width: 420px;*/

/*}*/

/*@media only screen and (max-width: 1100px) {*/
    /*.TradingOptionsBOHelpName{*/
        /*top: 75px;*/
        /*left: 260px;*/
    /*}*/
/*}*/

/*.TradingOptionsBOHelpPeriod{*/
    /*top: 170px;*/
    /*left: -160px;*/
    /*width: 350px;*/
/*}*/

/*@media only screen and (max-width: 1100px) {*/
    /*.TradingOptionsBOHelpPeriod{*/
        /*top: 160px;*/
        /*left: 190px;*/
    /*}*/
/*}*/


/*.TradingOptionsBOHelpAmount{*/
    /*top: 200px;*/
    /*left: -160px;*/
    /*width: 340px;*/
/*}*/

/*@media only screen and (max-width: 1100px) {*/
    /*.TradingOptionsBOHelpAmount{*/
        /*top: 190px;*/
        /*left: 190px;*/
    /*}*/
/*}*/

/*.TradingOptionsBOHelpButtons{*/
    /*top: 240px;*/
    /*left: -160px;*/
    /*width: 350px;*/
/*}*/

/*@media only screen and (max-width: 1100px) {*/
    /*.TradingOptionsBOHelpButtons{*/
        /*top: 220px;*/
        /*left: 190px;*/
    /*}*/
/*}*/

/*.TradingOptionsBOHelpHistory{*/
    /*top: 380px;*/
    /*left: -160px;*/
    /*width: 350px;*/
/*}*/

/*@media only screen and (max-width: 1100px) {*/
    /*.TradingOptionsBOHelpHistory{*/
        /*top: 360px;*/
        /*left: 190px;*/
    /*}*/
/*}*/

/*.TradingOptionsBOHelpNowOptions{*/
    /*top: 440px;*/
    /*left: -160px;*/
    /*width: 350px;*/
/*}*/

/*@media only screen and (max-width: 1100px) {*/
    /*.TradingOptionsBOHelpNowOptions{*/
        /*top: 420px;*/
        /*left: 190px;*/
    /*}*/
/*}*/
/*.TradingOptionsBOHelpNameAmerica{*/
    /*top: 85px;*/
    /*left: -160px;*/
    /*width: 420px;*/

/*}*/

/*@media only screen and (max-width: 1100px) {*/
    /*.TradingOptionsBOHelpNameAmerica{*/
        /*top: 75px;*/
        /*left: 260px;*/
    /*}*/
/*}*/

/*.TradingOptionsBOHelpPeriodAmerica{*/
    /*top: 165px;*/
    /*left: -160px;*/
    /*width: 350px;*/
/*}*/

/*@media only screen and (max-width: 1100px) {*/
    /*.TradingOptionsBOHelpPeriodAmerica{*/
        /*top: 150px;*/
        /*left: 170px;*/
    /*}*/
/*}*/


/*.TradingOptionsBOHelpAmountAmerica{*/
    /*top: 190px;*/
    /*left: -160px;*/
    /*width: 340px;*/
/*}*/

/*@media only screen and (max-width: 1100px) {*/
    /*.TradingOptionsBOHelpAmountAmerica{*/
        /*top: 170px;*/
        /*left: 190px;*/
    /*}*/
/*}*/

/*.TradingOptionsBOHelpPriceAmerica{*/
    /*top: 220px;*/
    /*left: -160px;*/
    /*width: 340px;*/
/*}*/

/*@media only screen and (max-width: 1100px) {*/
    /*.TradingOptionsBOHelpPriceAmerica{*/
        /*top: 200px;*/
        /*left: 190px;*/
    /*}*/
/*}*/

/*.TradingOptionsBOHelpButtonsAmerica{*/
    /*top: 240px;*/
    /*left: -160px;*/
    /*width: 350px;*/
/*}*/

/*@media only screen and (max-width: 1100px) {*/
    /*.TradingOptionsBOHelpButtonsAmerica{*/
        /*top: 230px;*/
        /*left: 150px;*/
    /*}*/
/*}*/

/*.TradingOptionsBOHelpHistoryAmerica{*/
    /*top: 380px;*/
    /*left: -160px;*/
    /*width: 350px;*/
/*}*/

/*@media only screen and (max-width: 1100px) {*/
    /*.TradingOptionsBOHelpHistoryAmerica{*/
        /*top: 370px;*/
        /*left: 170px;*/
    /*}*/
/*}*/

/*.TradingOptionsBOHelpNowOptionsAmerica{*/
    /*top: 440px;*/
    /*left: -160px;*/
    /*width: 350px;*/
/*}*/

/*@media only screen and (max-width: 1100px) {*/
    /*.TradingOptionsBOHelpNowOptionsAmerica{*/
        /*top: 420px;*/
        /*left: 190px;*/
    /*}*/
/*}*/

/*.TradingOptionsBOHelpLineOut{*/
    /*display: flex;*/
    /*align-items: center;*/
    /*width: 100%;*/
/*}*/

/*.TradingOptionsBOHelpLine{*/
    /*margin-left: 10px;*/
    /*width: 100%; height: 1px;  background-color: #666666;*/
/*}*/



/*@media only screen and (max-width: 1100px) {*/
    /*.TradingOptionsBOHelp{*/
        /*display: block;*/
        /*position: absolute;*/
        /*width: 46px;*/
        /*height: 46px;*/
        /*border-radius: 8px;*/
        /*background-image: url("../../static/img/Trader/Options/point.svg");*/
        /*z-index: 5;*/
    /*}*/

    /*.TradingOptionsBOHelp:hover{*/
        /*background-image: url("../../static/img/Trader/Options/pointActive.svg");*/
        /*cursor: pointer;*/
    /*}*/


    /*.TradingOptionsBOHelp>.TradingOptionsBOHelpText{*/
        /*display: none;*/
        /*z-index: 200;*/
        /*top: -40px;*/
        /*position: absolute;*/

    /*}*/
    /*.TradingOptionsBOHelp>.TradingOptionsBOHelpTextRight{*/
        /*right: 0;*/

    /*}*/

    /*.TradingOptionsBOHelp:active>.TradingOptionsBOHelpText{*/
        /*display: block;*/
        /*padding: 11px 13px 11px 13px;*/
        /*color: white;*/
        /*background-color: #353b50;*/
        /*font-size: 13px;*/
        /*line-height: 19px;*/
    /*}*/


    /*.TradingOptionsBOHelpLineOut{*/
        /*display: none;*/
    /*}*/

    /*.TradingOptionsBOHelpLine{*/
        /*margin-left: 10px;*/
        /*width: 100%; height: 1px;  background-color: black;*/
    /*}*/
/*}*/