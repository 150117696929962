.AboutContactsTop{
    display: flex;
    justify-content: space-between;
    background-color: white;
}

.AboutContactsTopOnline{
    background-image: url("../../../static/img/About/Contacts/onlineConsult.svg");
    background-size: cover;
    width: 400px;
    height: 182px;
    margin-top: -25px;
    /*filter: drop-shadow(0 0 25px rgba(0, 86, 142, 0.15));*/
    /*box-shadow : 0 0 25px rgba(0, 86, 142, 0.15), inset 0 0 0 5px #ffffff;*/
    /*background-color: #edf2f7;*/
}

.AboutContactsTopOnlineIn{
    padding: 40px 30px 30px 50px;
}

.AboutContactsTopTelephone{
    width: 562px;
    height: 132px;
    background-color: #5d3979;
    color: white;
    border-radius: 5px;
}

.AboutContactsTopTelephoneTop{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
}

.AboutContactsTopTelephoneTopText{
    font-size: 52px;
    font-weight: 700;

}
.AboutContactsTopTelephoneTop>img{
    padding-right: 18px;
}

.AboutContactsTopTelephoneBottom{
    text-align: center;
    font-size: 15px;
    font-weight: 700;
}

.AboutContactsTopOnlineText{
    font-size: 13px;
    font-weight: 700;
    padding-bottom: 5px;
}

.AboutContactsTopOnlineConsultAll{
    display: flex;
}

.AboutContactsTopOnlineConsult{
    font-size: 23px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #09518e;

    /*text-decoration: underline;*/
}

.AboutContactsTopOnlineConsultSmall{
    font-size: 15px;
    padding-top: 17px;
    padding-left: 7px;
    padding-bottom: 10px;
    color: #09518e;
}

.AboutContactsTopOnlineMail{
    display: flex;
    align-items: center;
    color: #09518e;
    text-decoration: underline;
}
.AboutContactsTopOnlineMail>img{
    margin-right: 5px;
}

.AboutContactsEmailsAll{
    display: grid;
    grid-template-columns: 280px 280px 280px;
    grid-row-gap: 30px;
    justify-content: space-between;
}

.AboutContactsEmails{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.AboutContactsEmailsElement{
    width: 280px;
}

.AboutContactsEmailsElementTitle{
    margin-top: 35px;
    font-size: 19px;
    font-weight: 700;
    padding-bottom: 10px;
    border-bottom: #e8eef4 solid 2px;
    margin-bottom: 10px;
}

.AboutContactsOfficesTitle{
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 23px;
}

.AboutContactsOfficesMain{
    display: flex;
    justify-content: space-around;
    margin-bottom: 25px;
}

.AboutContactsOfficesMainElement {
    width: 325px;
    height: 112px;
    border-radius: 6px;
    border: solid 1px #cbd8eb;
    cursor: pointer;
    /*background-color: #618fb6;*/
}

.AboutContactsOfficesMainElement>.AboutContactsOfficesMainElementText {
    color: #09518e;
}

.AboutContactsOfficesMainElementActive{
    color: white;
    width: 325px;
    height: 112px;
    background-image: url("../../../static/img/About/Contacts/AddressActive.svg");
    background-size: cover;
    padding-bottom: 10px;
}

.AboutContactsOfficesMainElementText{
    padding: 15px 20px 15px 20px;
    text-decoration: underline dotted;
    font-size: 15px;
    line-height: 1.5;
}

.AboutContactsOfficesMainElementTelephone{
    padding: 0 20px 0 20px;
    font-size: 15px;
}

@media only screen and (max-width: 1000px) {
.AboutContactsTop{
    flex-wrap: wrap;
    justify-content: center;
}
    .AboutContactsTopOnline{
        margin-top: 10px;
        text-align: center;
    }
    .AboutContactsTopOnlineIn>.AboutContactsTopOnlineMail{
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 950px) {
    .AboutContactsEmailsAll{grid-template-columns: 280px 280px;
        justify-content: space-around;
    }
}
@media only screen and (max-width: 750px) {
    .AboutContactsEmailsAll{grid-template-columns: 280px;
        justify-content: space-around;
    }
    .AboutContactsOfficesMain{
        flex-wrap: wrap;
    }
    .AboutContactsOfficesMainElementActive{
        background-image: unset;
        background-color: #0767b4;
        border-radius: 8px;
        margin-bottom: 20px;
    }
    .AboutContactsOfficesMainElementActive{
        margin-bottom: 20px;
    }


}

@media only screen and (max-width: 550px) {
    .AboutContacts>.StandartPage>.AboutContactsTop{
        padding-left: 0;
        padding-right: 0;
    }
    .AboutContactsTopTelephone{
        border-radius: 0;
    }
    .AboutContactsTopTelephoneTopText{
        font-size: 36px;
    }
    .AboutContactsTopTelephoneTop>img{
        display: none;
    }
}

@media only screen and (max-width: 360px) {
    .AboutContactsTopOnline{
        background-image: none;
    }
    .AboutContactsTopOnlineIn{
        padding-left: 20px;
        padding-right: 20px;
    }
}
