body{
    margin: 0;
    font-family: Arial, sans-serif;
}

div, input, button, textarea{
    outline:none;
}

a, a:visited{
    color: #02518e;
}

a:hover{
    color: #2288da;
}

.StandartEmail{
    color: #2288da;
    text-decoration: underline;
}
.StandartProbel{
    margin-left: 5px;
}
.StandartHref{
    color: #02518e;
    text-decoration: underline;
    cursor: pointer;
}

.StandartHref:hover{
    color: #2288da;
}

.TopTitle{
    display: flex;
    justify-content: space-between;
}

.StandartTitleOutTerminal{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.StandartTitleOutTerminal>.IfYouHaveQuestions{
    position: relative;
    top: 5px;
}


.StandartPage{
    width: 100%;
    margin: auto;
    max-width: 1144px;
}

.StandartPageIn{
    padding-left: 28px;
    padding-right: 28px;
}

.StandartPageInSpecial{
    margin-left: 28px;
    margin-right: 28px;
}

@media only screen and (max-width: 800px) {
    .StandartPageInSpecial{
        margin-left: 0;
        margin-right: 0;
    }
}

@media only screen and (max-width: 1200px) {
    .StandartInZeroBig {
        padding-left: 25px;
        padding-right: 25px;
    }
}

.StandartAllBlack500{
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    z-index: 500;
}

@media only screen and (max-width: 1200px) {
    .StandartIn{
        margin-left: 30px;
        margin-right: 30px;
    }
}

.StandartPhone{
    display: none;
}

@media only screen and (max-width: 500px) {
    .StandartPhone{
        display: block;
    }
    .StandartNoPhone{
        display: none;
    }
    .StandartIn{
        margin-left: 5px;
        margin-right: 5px;
    }
}
.StandartLinkLeft{
    padding-left: 5px;
}

.StandartTitle{
    font-weight: 700;
    font-size: 23px;
    color: black;
    line-height: 28px;
    padding-top: 28px;
    padding-bottom: 28px;
}

.StandartH2{
    font-weight: 700;
    font-size: 19px;
    color: black;
    line-height: 28px;
    padding-top: 28px;
    padding-bottom: 28px;
}

.StandartText{
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    color: black;
}

.StandartPageInRight{
    padding-right: 31px;
}

@media only screen and (max-width: 1000px) {
    .StandartPageIn{
        padding-left: 20px;
        padding-right: 20px;
    }

    .StandartPageInRight{
        padding-right: 20px;
    }

}

@media only screen and (max-width: 800px) {
    .StandartPageIn{
        padding-left: 14px;
        padding-right: 14px;
    }

    .StandartPageInRight{
        padding-right: 14px;
    }
    .StandartTitle{
        font-size: 19px;
        line-height: 28px;
        padding-top: 24px;
        padding-bottom: 28px;
    }

    .StandartText{
        font-size: 13px;
        line-height: 23px;
    }
}

.HrGrayVerticalLine{height: 100%; width: 1px;  background-color: #0a79bf;}

.StandartNotVisible{
    display: none;
}

.ButtonStandartOut{
    display: flex;
    justify-content: center;
}

.ButtonGreen{
    width: 136px;
    height: 39px;
    border-radius: 3px;
    background-image: linear-gradient(to top, #4da400, #5fb314);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
}

.StandartButtonOut{
    display: flex;
}