.CheckLanguage{
    display: flex;
    align-items: center;
    grid-column: 3;
    padding-right: 5px;
}

.CheckLanguageImg{
    padding-left: 10px;
    padding-right: 5px;
}

.CheckLanguageOut{
    padding: 12px 5px 0 5px;
    border-radius: 3px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.7);
    background-color: #ffffff;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 1000;
}

.CheckLanguageOut>a{
    padding-bottom: 11px;
    text-decoration: none;
}

.CheckLanguageOutOut{
    position: relative;
    display: flex;
    justify-content: flex-end;
    top: -1px;
    cursor: pointer;
}

.CheckLanguageNo{
    display: none;
}

.CheckLanguageTitle{
    color: #02457d;
}

.CheckLanguageTitleActive{
    color: black;
}


.CheckLanguageTriangle{
    margin-left: 5px;
    position: relative;
    top: -2px;
}

.TriangleForPlate{
    display: none;
}

