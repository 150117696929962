.ContestIndexTop{
    font-size: 14px;
}

.ContestIndexTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.ContestStarMainText{
    padding-right: 39px;
    display: grid;
    grid-column-gap: 60px;
    grid-template-columns: auto 400px;
}

.ContestStarMainText>img{
    width: 100%;
}

/*.ContestStarMainText02{*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*margin-top: 29px;*/
/*}*/

.ContestStarMainText02PrizesTitle{
    font-size: 17px;
    font-weight: 700;
}

.ContestStarMainText02Info{
    box-shadow: 1.5px 2.6px 15px 0 rgba(50, 93, 0, 0.1);
    border: solid 2px #8cc845;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 19px 19px 21px 21px;
    height: 124px;
}
.ContestStarMainText02PrizesGrid{
    border-spacing: unset;
    width: 100%;
    margin-top: 15px;
    max-width: 229px;
}

.ContestStarMainText02PrizesGrid>thead{
    background-color: #f0f5f8;

}

.ContestStarMainText02PrizesGrid>thead>tr>th{
    font-size: 15px;
    font-weight: 700;
    height: 44px;
    border-bottom: 1px solid #d5e3eb;
}

.ContestStarMainText02PrizesGrid>tbody>tr>td:nth-of-type(2){
    padding-left: 20px;
}

.ContestStarMainText02PrizesGrid>thead>tr>th:first-of-type{
    width: 70px;
    text-align: right;
    padding-right: 20px;
}

.ContestStarMainText02PrizesGrid>thead>tr>th:nth-of-type(2){
    text-align: left;
    padding-left: 20px;
}


.ContestStarMainText02PrizesGrid>tbody>tr>td:first-of-type{
    height: 30px;
    text-align: right;
    padding-right: 20px;
}

.ContestStarMainText01{
    grid-column: 1/span 2;
}

.ContestStarMainIMG{
    grid-column: 1/span 2;
    margin-bottom: 30px;
    margin-top: 28px;
}

@media only screen and (max-width: 800px) {
    .ContestStarMainText{
        grid-template-columns: auto;
    }
    .ContestStarMainIMG{
        grid-column: 1;
        grid-row: 1;
    }
    .ContestStarMainText01{
        grid-column: 1;
    }
    .ContestStarMainText02Info{
        grid-row: 2;
        height: auto;
        margin-bottom: 28px;
    }
}


