.SearchPageTitle{
    font-size: 40px;
    margin-top: 32px;
    font-weight: 700;
}

.SearchPageMain{
    display: flex;
}

.SearchPageRobot{
    margin-top: 45px;
    display: flex;
    align-items: center;
}

.SearchPageRobot>img{
    margin-right: 12px;
}


.SearchPageRobotTextOut{
    background: url("../static/img/Search/robotText.svg") center;
    width: 522px;
    height: 53px;
    background-size: cover;
}

.SearchPageRobotText{
    width: 70%;
    height: 100%;
    margin-left: 30px;
    display: flex;
    padding-left: 10px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
}

.SearchPageOnlineConsultOut{
    display: flex;
    justify-content: flex-end;
    margin-top: -1px;
}

.SearchPageOnlineConsult {
    display: flex;
    height: 20px;
    border: solid 1px #d9e1f1;
    border-radius: 3px;
    padding: 6px 19px;
    align-items: center;
}

.SearchPageOnlineConsult>img{
    margin-right: 10px;
}

.SearchPageMainLeft{
    max-width: 425px;

}

.SearchPageMainLeftBigText{
    font-size: 26px;
    text-align: right;
    margin-top: 15px;
}

.SearchPageMainRightIn{
    margin-right: 40px;
    margin-top: 20px;
}
.SearchPageMainRight{
    background: url("../static/img/Search/3.svg") center;
    display: flex;
    justify-content: flex-end;
    width: 465px;
    height: 321px;
    background-size: auto;
}

.SearchPageTextarea{
    width: 300px;
    height: 106px;
}

.IfYouHaveQuestionsActiveIn{
    width: 300px;
}

.SearchPageQuestionsTopTitle{
    font-size: 14px;
    font-weight: 700;
}

.SearchPageMainRightIn>.StandartNotVisible{
    display: none;
}

.SearchPageMainActiveInput{
    width: calc(100% - 4px);
    height: 24px;
    font-size: 13px;
    margin-top: 10px;
}

.SearchPageMain{
    margin-top: 54px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.SearchPageMainLeftIMG{
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 905px) {
    .SearchPageMainRight{
        margin-top: 15px;
    }
}


@media only screen and (max-width: 625px) {
    .SearchPageRobotTextOut{
        background: none;
    }
}
    @media only screen and (max-width: 500px) {
        .SearchPageMainRight{
            background: none;
        }
        .SearchPageMainRight{
            justify-content: center;
        }
        .SearchPageMainRightIn{
            margin-right: 0;
        }
        .SearchPageMainLeftIMG{
            justify-content: center;
        }
        .SearchPageOnlineConsultOut{
            justify-content: center;
        }
        .SearchPageMainLeftBigText{
            text-align: center;
            margin-right: 15px;
            margin-left: 15px;
        }

    }

@media only screen and (max-width: 400px) {
    .SearchPageMainRight{
        width: 100%;
    }
    .SearchPageMainRightIn {
        margin-left: 10px;
        margin-right: 10px;
    }
    .SearchPageTextarea{
        width: calc(100% - 6px);
    }
}