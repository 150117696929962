
.IndexMainSmallLinks{
    display: flex;
    align-items: center;
    position: relative;
    /*cursor: grab;*/
    border-left: 1px solid #ececec;
    margin-top: 15px;
}

.IndexMainSmallLinksButton{
    border: 1px solid #ececec;
    width: 30px;
    height: 30px;
    border-radius: 15px;
}

.IndexMainSmallLinksButtonIMGHover{
    display: none;
}

.IndexMainSmallLinksButton:hover>.IndexMainSmallLinksButtonIMGHover{
    display: block;
}
.IndexMainSmallLinksButton:hover>.IndexMainSmallLinksButtonIMG{
    display: none;
}

.IndexMainSmallLinksElement{
    height: 116px;
    min-width: 140px;
    width: auto;
    border: 1px solid #ececec;
    border-left: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
}

.IndexMainSmallLinksElement>div{
    text-align: center;
    padding-top: 10px;
    font-size: 12px;
}

.IndexMainSmallLinksElementIn{
    display: flex;
    border-left: 1px solid #ececec;
}

.IndexMainSmallLinksButtonLeft{
    position: absolute;
    left: -15px;
    background-color: white;
    z-index: 1;
    cursor: pointer;
}
.IndexMainSmallLinksButtonRight{
    position: absolute;
    right: -15px;
    background-color: white;
    z-index: 20;
    cursor: pointer;
}
@media only screen and (max-width: 1200px) {
    .IndexMainSmallLinks{
        margin-left: 30px;
        margin-right: 30px;
        border-left: 1px solid #ececec;

    }

}

.IndexMainSmallLinkInOut{
    overflow: hidden;
    width: 100%;
}

.IndexMainSmallLinksIn{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    align-items: center;
    position: relative;
    overflow: hidden;

}

.IndexMainSmallLinksIn>div{
    display: none;
}

.IndexMainSmallLinksIn>.IndexMainSmallLinksElement:nth-of-type(1),.IndexMainSmallLinksIn>.IndexMainSmallLinksElement:nth-of-type(2),.IndexMainSmallLinksIn>.IndexMainSmallLinksElement:nth-of-type(3),.IndexMainSmallLinksIn>.IndexMainSmallLinksElement:nth-of-type(4),.IndexMainSmallLinksIn>.IndexMainSmallLinksElement:nth-of-type(5),.IndexMainSmallLinksIn>.IndexMainSmallLinksElement:nth-of-type(6),.IndexMainSmallLinksIn>.IndexMainSmallLinksElement:nth-of-type(7){
    display: flex;
}


.IndexMainSmallLinksElement>div{
    padding-left: 15px;
    padding-right: 15px;
}

.IndexMainSmallLinksElementView,.IndexMainSmallLinksElementView>.IndexMainSmallLinksElement{
    display: flex !important;
}
.IndexMainSmallLinksElementView{
    width: auto !important;
}

@media only screen and (max-width: 1100px) {
    .IndexMainSmallLinksIn{
        grid-template-columns: auto auto auto auto auto auto;
    }
    .IndexMainSmallLinksIn>.IndexMainSmallLinksElement:nth-of-type(6){
        display: none;
    }
}
@media only screen and (max-width: 1000px) {
    .IndexMainSmallLinksIn{
        grid-template-columns: auto auto auto auto auto;
    }
    .IndexMainSmallLinksIn>.IndexMainSmallLinksElement:nth-of-type(5){
        display: none;
    }
}
@media only screen and (max-width: 750px) {
    .IndexMainSmallLinksIn{
        grid-template-columns: auto auto auto auto;
    }
    .IndexMainSmallLinksIn>.IndexMainSmallLinksElement:nth-of-type(4){
        display: none;
    }
}
@media only screen and (max-width: 600px) {
    .IndexMainSmallLinkInOut{
        width: 100%;
    }
    .IndexMainSmallLinksIn{
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .IndexMainSmallLinksIn>.IndexMainSmallLinksElement:nth-of-type(3){
        display: none;
    }
}@media only screen and (max-width: 500px) {
    .IndexMainSmallLinksIn{
        grid-template-columns: 1fr 1fr;
    }
    .IndexMainSmallLinksIn>.IndexMainSmallLinksElement:nth-of-type(2){
        display: none;
    }
}@media only screen and (max-width: 370px) {
    .IndexMainSmallLinksIn{
        grid-template-columns: auto;
    }
    .IndexMainSmallLinksIn>.IndexMainSmallLinksElement:nth-of-type(1){
        display: none;
    }
}
