.TraderRegistrationPromo01TopIMG>a{
    display: flex;
    justify-content: center;
    margin-top: 26px;
    margin-bottom: 20px;
}

.TraderRegistrationPromo01Title{
    font-size: 36px;
    color: black;
    text-align: center;
    font-weight: 700;
}
.TraderRegistrationPromo01H1{
    font-size: 25px;
    color: black;
    text-align: center;
    font-weight: 700;
    line-height: 41px;
}

.TraderRegistrationPromo01Main{
    margin-top: 45px;
    background-image: url("../../../static/img/Promo/promo1.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: contain;
    min-height: 600px;
    display: flex;
    justify-content: space-between;
}

.TraderRegistrationPromo01MainLeftElement{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 30px 290px;
    grid-column-gap: 19px;
    font-size: 15px;
    line-height: 20px;
}

.TraderRegistrationPromo01MainBottom>a{
    padding-left: 5px;
}
.TraderRegistrationPromo01MainBottom{
    margin-top: 23px;
    padding: 19px 35px 19px 35px;
    width: 150px;
    font-size: 14px;
    background-color: white;
}

.TraderRegistrationPromo01MainLeftTitle{
    font-size: 25px;
    color: black;
    font-weight: 700;
}

.TraderRegistrationPromo01In{
    background-color: white;
    box-shadow: 1.4px 1.4px 100px 0 #002648;
    padding-top: 26px;
    padding-bottom: 26px;
    border-radius: 3px;

}
.TraderRegistrationPromo01GridRegistrationFormElement{
    /*display: grid;*/
    /*grid-template-columns: 200px auto;*/
    /*margin: 0 26px 24px 26px;*/
}

.TraderRegistrationPromo01GridRegistrationForm>.TraderRegistrationPromo01PartPromo{
    margin-top: 24px;
}

.TraderRegistrationPromo01GridRegistrationFormElement3{
    /*display: grid;*/
    /*grid-template-columns: 200px 180px auto;*/
    margin: 0 26px 0 26px;
}

.TraderRegistrationPromo01GridRegistrationFormElementTopTitle{
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 700;
}

.TraderRegistrationPromo01GridRegistrationFormElementInput{
    width: 100%;
}

.TraderRegistrationPromo01GridRegistrationFormElementInput>input,.TraderRegistrationPromo01GridRegistrationFormElementInput>.TraderRegistrationPromo01GridPassword>input{
    width: 100%;
    height: 28px;
    font-size: 15px;
    padding-left: 9px;
    /*box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0 1px 3px 0 #171616;*/
}

.TraderRegistrationPromo01GridRegistrationFormElementTopText{
    font-size: 11px;
    color: #898989;
    margin-top: 6px;
}

.TraderRegistrationPromo01GridRegistrationFormElementTopTextPass{
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.TraderRegistrationPromo01GridRegistrationFormElementTopTextError{
    margin-top: 6px;
    font-size: 11px;
    color: #da343d;
    display: none;
    max-width: 280px;
}

.ButtonTraderRegistrationPromo01>a{
    text-decoration: none;
}


.TraderRegistrationPromo01PasswordOpen,.TraderRegistrationPromo01PasswordClose{
    position: absolute;
    right: 15px;
    top: 6px;
}

.TraderRegistrationPromo01GridPassword{
    position: relative;
}

.TraderRegistrationPromo01GridRegistrationFormElementInput>.TraderRegistrationPromo01GridPassword>div>img{
    cursor: pointer;
}
.PasswordNoView>.TraderRegistrationPromo01GridRegistrationFormElementInput>.TraderRegistrationPromo01GridPassword>div>.TraderRegistrationPromo01PasswordOpen{
    display: none;
}
.PasswordView>.TraderRegistrationPromo01GridRegistrationFormElementInput>.TraderRegistrationPromo01GridPassword>div>.TraderRegistrationPromo01PasswordClose{
    display: none;
}

.TraderRegistrationPromo01GridRegistrationFormElementTopTextError{
    grid-column: 2/span 3;
}

.ButtonTraderRegistrationPromo01Password{
    font-size: 11px;
    color: #09518e;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: underline dotted;
    display: flex;
    align-items: center;
}

.TraderRegistrationPromo01GridRegistrationFormElementTopTitleOut{
    display: flex;
    justify-content: space-between;
}

.TraderRegistrationPromo01ButtomText{
    font-size: 11px;
    margin-top: 15px;
    margin-left: 26px;
    line-height: 12px;
}

.TraderRegistrationPromo01GridRegistrationFormElementError>.TraderRegistrationPromo01GridRegistrationFormElementTopTitle{
    color: #da343d;
}
.TraderRegistrationPromo01GridRegistrationFormElementError>.TraderRegistrationPromo01GridRegistrationFormElementTopTextPassPromo{
    color: #da343d;
    display: block;
}
.TraderRegistrationPromo01GridRegistrationFormElementTopTextPassPromo{
    grid-column: 2/span 3;
    margin-left: 0;
    display: none;
}

.TraderRegistrationPromo01GridRegistrationFormElementError>.TraderRegistrationPromo01GridRegistrationFormElementInput>.TraderRegistrationPromo01GridRegistrationFormElementTopTextError,.TraderRegistrationPromo01GridRegistrationFormElementError>.TraderRegistrationPromo01GridRegistrationFormElementTopTextError {
    display: block;
}
.TraderRegistrationPromo01GridRegistrationFormElementError>.TraderRegistrationPromo01GridRegistrationFormElementInput>input,.TraderRegistrationPromo01GridRegistrationFormElementError>.TraderRegistrationPromo01GridRegistrationFormElementInput>.TraderRegistrationPromo01GridPassword>input{
    background-color: #fff1f2;
    border-top: 1px solid #beb6b6;
    border-left: 1px solid #beb6b6;
}

.TraderRegistrationPromo01Success>.TraderRegistrationPromo01In{
    padding: 0;
}

.TraderRegistrationPromo01GridRegistrationFormElement{
    padding-right: 28px;
    padding-left: 27px;
}

.TraderRegistrationPromo01Success>.TraderRegistrationPromo01In>.TraderRegistrationPromo01InIn>.ButtonTraderRegistrationPromo01{
    margin-left: 0;
}
.TraderRegistrationPromo01Success>.TraderRegistrationPromo01In>.TraderRegistrationPromo01InIn{
    padding: 26px;
}
.TraderRegistrationPromo01SuccessTitle{
    font-size: 23px;
    font-weight: 700;
}

.TraderRegistrationPromo01SuccessText{
    font-size: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.TraderRegistrationPromo01SuccessPasswordWithdrawal{
    background-color: #eefbdf;
    padding: 26px;
}

.TraderRegistrationPromo01SuccessAllInfo{
    margin-top: 15px;
    margin-right: 15px;
}

.TraderRegistrationPromo01SuccessAllInfoElement{
    display: flex;
}

.TraderRegistrationPromo01SuccessAllInfoElementText{
    font-weight: 700;
    width: 250px;
    padding-bottom: 20px;
}
.TraderRegistrationPromo01SuccessAllInfoOut{
    display: flex;
    justify-content: space-between;
}
.TraderRegistrationPromo01SuccessAllInfoPrint>img{
    margin-right: 10px;
}
.TraderRegistrationPromo01SuccessAllInfoPrint{
    padding: 18px 18px 18px 19px ;
    max-width: 252px;
    height: min-content;
    text-decoration: underline;
    font-size: 15px;
    color: #3f3e3e;
    border-radius: 5px;
    border: 1px solid #908f8f;
    margin-top: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 1050px) {
    .TraderRegistrationPromo01SuccessAllInfoOut{
        display: block;
    }
}
@media only screen and (max-width: 800px) {
    .TraderRegistrationMain {
        display: block;
    }
    .OpenRealDocuments{
        margin: auto;
    }
    .TraderRegistrationPromo01{
        border: none;
        padding: 0;
        position: relative;
        z-index: 500;
    }
}
@media only screen and (max-width: 900px) {

    .TraderRegistrationPromo01GridRegistrationFormElement3{
        max-width: 283px;
    }
    .TraderRegistrationPromo01GridRegistrationForm>.ButtonTraderRegistration{
        max-width: 253px;
    }
}


@media only screen and (max-width: 550px) {
    .TraderRegistrationPromo01GridRegistrationFormElement{
        display: block;
    }
    .TraderRegistrationPromo01GridRegistrationFormElementTopTitle{
        margin-bottom: 12px;
    }
    .TraderRegistrationPromo01GridRegistrationFormElement3{
        grid-template-columns: auto;
    }
    .TraderRegistrationPromo01GridRegistrationFormElementTopText{
        grid-row: 2;
        margin-left: 0;
        margin-top: 3px;
        margin-bottom: 3px;
        line-height: 17px;
    }
    .TraderRegistrationPromo01GridRegistrationFormElementInput{
        grid-column: 1/span 2;
    }
    .TraderRegistrationPromo01SmallInput{
        max-width: unset;
    }
    .TraderRegistrationPromo01GridRegistrationFormElement3>.TraderRegistrationPromo01GridRegistrationFormElementTopTitle{
        margin-bottom: 0;
        grid-row: 1;
    }
    .TraderRegistrationPromo01GridRegistrationFormElement3>.TraderRegistrationPromo01GridRegistrationFormElementTopTextError{
        grid-column: 1/span 2;
    }
    .TraderRegistrationPromo01GridRegistrationFormElementInput{
        grid-column: 1/span 2;
    }
    .ButtonTraderRegistrationPromo01Password{
        grid-row: 1;
        margin-left: 0;
        position: relative;
        right: 20px;
        top: 5px;
    }
    .ButtonTraderRegistrationPromo01{
        width: unset;
    }
    .TraderRegistrationPromo01AllPasswordBackground>.TraderRegistrationPromo01GridRegistrationFormElement3>.TraderRegistrationPromo01GridRegistrationFormElementTopTitle{
        padding-bottom: 10px;
    }
    .TraderRegistrationPromo01AllPasswordBackground>.TraderRegistrationPromo01GridRegistrationFormElement3>.TraderRegistrationPromo01GridRegistrationFormElementInput>.TraderRegistrationPromo01GridPassword{
        width: 90%;
    }
    .ButtonTraderRegistrationPromo01{
        max-width: 90%;
        margin: 20px auto auto;
        width: 70%;
        margin-bottom: 20px;
    }

    .TraderRegistration>.StandartPage>.StandartTitle{
        display: none;
    }
    .TraderRegistrationPromo01Success{
        border: none;
    }
    .TraderRegistrationPromo01Success>.TraderRegistrationPromo01In>.TraderRegistrationPromo01InIn{
        padding: 0;
    }
    .TraderRegistrationPromo01Success>.TraderRegistrationPromo01In>.TraderRegistrationPromo01InIn>.TraderRegistrationPromo01SuccessTitle,
    .TraderRegistrationPromo01Success>.TraderRegistrationPromo01In>.TraderRegistrationPromo01InIn>.TraderRegistrationPromo01SuccessAllInfo,
    .TraderRegistrationPromo01Success>.TraderRegistrationPromo01In>.TraderRegistrationPromo01InIn>.TraderRegistrationPromo01SuccessText
    {
        padding-left: 20px;
        padding-right: 20px;
    }
    .TraderRegistrationPromo01SuccessText{
        line-height: 27px;
    }
    .TraderRegistration>.StandartPage>.TraderRegistrationMain>.TraderRegistrationPromo01Success{
        padding: 0;
    }

}

.TraderRegistrationPromo01GridRegistrationFormElementInput>.TraderRegistrationPromo01GridPassword>input, .TraderRegistrationPromo01GridRegistrationFormElementInput>input {
    width: 283px;
    height: 28px;
    font-size: 15px;
    padding-left: 9px;
    border-radius: 2px;
    /* box-shadow: 0 1px 1px 0 rgba(0,0,0,.25), inset 0 1px 3px 0 #171616; */
    border: solid 1px #d8e4eb;
    /*box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0px 1px 3px 0 #171616;*/
}

@media print {
    footer,header,.SubMenuClassName,.MainMenuOut,.BlueFooter,.StandartTitle,.OpenRealDocumentsOut,.TraderRegistrationPromo01SuccessAllInfoOut>a,.ButtonTraderRegistrationPromo01,.PlateTop{
        display: none;
    }
}

.TraderRegistrationPromo01GridRegistrationForm>.ButtonTraderRegistration,.ButtonTraderRegistrationPromo01>a>.ButtonTraderRegistration{
    margin-right: 25px;
    width: auto;
}

@media only screen and (max-width: 900px) {
    .TraderRegistrationPromo01Main{
        display: block;
    }
}
@media only screen and (max-width: 550px) {
    .TraderRegistrationPromo01SuccessAllInfoElement{
        display: block;
    }
    .TraderRegistrationPromo01Success>.TraderRegistrationPromo01In{
        padding: 10px;
    }
    .TraderClientOpenPromo>.StandartInZeroBig{
        padding-left: 5px;
        padding-right: 5px;
    }
    .TraderRegistrationPromo01MainLeftElement{
        grid-template-columns: 30px auto;
    }
    .TraderRegistrationPromo01GridRegistrationForm>.ButtonTraderRegistration, .ButtonTraderRegistrationPromo01>a>.ButtonTraderRegistration{
        margin-right: auto;
    }
    .TraderRegistrationPromo01GridRegistrationFormElementInput>.TraderRegistrationPromo01GridPassword>input, .TraderRegistrationPromo01GridRegistrationFormElementInput>input {
        width: 90%;
    }
    .TraderRegistrationPromo01GridRegistrationForm>.ButtonTraderRegistration, .ButtonTraderRegistrationPromo01>a>.ButtonTraderRegistration{
    }
}
