.IndexAdvantages{
    grid-column: 3/span 2;
    height: 253px;
    object-fit: contain;
    border: solid 1px #ececec;
    background-color: #fafafa;
}

.IndexAdvantagesTopGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 22px;
    margin-top: 10px;
}

.IndexAdvantagesTopGridColumn{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.IndexAdvantagesTitle{
    font-size: 17px;
    font-weight: bold;
    color: #181818;
    padding: 16px 16px 0 20px;
}

.IndexAdvantagesTopGridElement{
    display: flex;
    align-items: center;
    padding-top: 1px;
    line-height: 25px;
}
.IndexAdvantagesTopGridElement>img{
    padding-right: 15px;
    align-self: flex-start;
    padding-top: 7px;
}

.IndexAdvantagesTopGridElementText{
    font-size: 13px;
    color: #151515;
}

.IndexAdvantagesTopGridElementTextA{
    position: relative;
    left: -3px;
}

.IndexAdvantagesTopGridElementTextA>a{
    margin-left: 5px;
}

.IndexAdvantagesSystems{
    display: flex;
    flex-wrap: wrap;
}

.IndexAdvantagesSystems{
    padding-top: 6px;
    padding-left: 22px;
}

.IndexAdvantagesSystems>img{
    padding-right: 10px;
    padding-bottom: 10px;
}

@media only screen and (max-width: 1100px) {
    .IndexAdvantages{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 30px auto;
        justify-content: space-between;
    }
    .IndexAdvantagesElement01{
        grid-column: 1;
        grid-row: 1;
    }
    .IndexAdvantagesElement02{
        grid-column: 1;
        grid-row: 2;
    }
    .IndexAdvantagesElement03{
        grid-column: 2;
        grid-row: 1;
    }
    .IndexAdvantagesElement04{
        grid-column: 2;
        grid-row: 2;
    }
    .IndexAdvantages{
        height: auto;
    }
    .IndexAdvantagesTopGrid,.IndexAdvantagesSystems{
        margin-top: 0;
        padding-top: 14px;
        margin-bottom: 20px;
    }
    .IndexAdvantagesTopGrid{
        grid-column-gap: 20px;
    }
}

@media only screen and (max-width: 800px) {
    .IndexAdvantagesTopGrid{
        display: block;
    }
}
@media only screen and (max-width: 480px) {
    .IndexAdvantages{
        display: block;
    }
}

