.TraderClientOpenPromo02{
    background-image: url("../../../static/img/Promo/promo2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.TraderRegistrationPromo02TopIMG>a{
    display: flex;
    justify-content: center;
    margin-top: 26px;
    margin-bottom: 20px;
}

.TraderRegistrationPromo02Title{
    font-size: 55px;
    color: white;
    font-weight: 700;
    margin-top: 10px;
}
.TraderRegistrationPromo02H1{
    font-size: 28px;
    color: white;
}

.TraderRegistrationPromo02Main{
    padding-top: 100px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: contain;
    min-height: 600px;
    display: flex;
    justify-content: space-between;
}

.TraderRegistrationPromo02MainLeft{
    padding-left: 20px;
}
.TraderRegistrationPromo02MainRight{
    padding-right: 20px;
}

.TraderRegistrationPromo02MainLeftElement{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 50px 290px;
    grid-column-gap: 21px;
    font-size: 20px;
    color: white;
}

.TraderRegistrationPromo02MainBottom>a{
    padding-left: 5px;
}
.TraderRegistrationPromo02MainBottom{
    margin-top: 23px;
    padding: 19px 35px 19px 35px;
    width: 150px;
    font-size: 14px;
    background-color: white;
}


.TraderRegistrationPromo02H102{
    margin-top: 10px;
}

.TraderRegistrationPromo02In{
    background-color: white;
    box-shadow: 1.4px 1.4px 100px 0 #002648;
    padding-top: 26px;
    padding-bottom: 26px;
    border-radius: 3px;

}
.TraderRegistrationPromo02GridRegistrationFormElement{
    /*display: grid;*/
    /*grid-template-columns: 200px auto;*/
    /*margin: 0 26px 24px 26px;*/
}

.TraderRegistrationPromo02GridRegistrationForm>.TraderRegistrationPromo02PartPromo{
    margin-top: 24px;
}

.TraderRegistrationPromo02GridRegistrationFormElement3{
    /*display: grid;*/
    /*grid-template-columns: 200px 180px auto;*/
    margin: 0 26px 0 26px;
}

.TraderRegistrationPromo02GridRegistrationFormElementTopTitle{
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 700;
}

.TraderRegistrationPromo02GridRegistrationFormElementInput{
    width: 100%;
}

.TraderRegistrationPromo02GridRegistrationFormElementInput>input,.TraderRegistrationPromo02GridRegistrationFormElementInput>.TraderRegistrationPromo02GridPassword>input{
    width: 100%;
    height: 28px;
    font-size: 15px;
    padding-left: 9px;
    /*box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0 1px 3px 0 #171616;*/
}

.TraderRegistrationPromo02GridRegistrationFormElementTopText{
    font-size: 11px;
    color: #898989;
    margin-top: 6px;
}

.TraderRegistrationPromo02GridRegistrationFormElementTopTextPass{
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.TraderRegistrationPromo02GridRegistrationFormElementTopTextError{
    margin-top: 6px;
    font-size: 11px;
    color: #da343d;
    display: none;
    max-width: 280px;
}

.ButtonTraderRegistrationPromo02>a{
    text-decoration: none;
}


.TraderRegistrationPromo02PasswordOpen,.TraderRegistrationPromo02PasswordClose{
    position: absolute;
    right: 15px;
    top: 6px;
}

.TraderRegistrationPromo02GridPassword{
    position: relative;
}

.TraderRegistrationPromo02GridRegistrationFormElementInput>.TraderRegistrationPromo02GridPassword>div>img{
    cursor: pointer;
}
.PasswordNoView>.TraderRegistrationPromo02GridRegistrationFormElementInput>.TraderRegistrationPromo02GridPassword>div>.TraderRegistrationPromo02PasswordOpen{
    display: none;
}
.PasswordView>.TraderRegistrationPromo02GridRegistrationFormElementInput>.TraderRegistrationPromo02GridPassword>div>.TraderRegistrationPromo02PasswordClose{
    display: none;
}

.TraderRegistrationPromo02GridRegistrationFormElementTopTextError{
    grid-column: 2/span 3;
}

.ButtonTraderRegistrationPromo02Password{
    font-size: 11px;
    color: #09518e;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: underline dotted;
    display: flex;
    align-items: center;
}

.TraderRegistrationPromo02GridRegistrationFormElementTopTitleOut{
    display: flex;
    justify-content: space-between;
}

.TraderRegistrationPromo02ButtomText{
    font-size: 11px;
    margin-top: 15px;
    margin-left: 26px;
    line-height: 12px;
}

.TraderRegistrationPromo02GridRegistrationFormElementError>.TraderRegistrationPromo02GridRegistrationFormElementTopTitle{
    color: #da343d;
}
.TraderRegistrationPromo02GridRegistrationFormElementError>.TraderRegistrationPromo02GridRegistrationFormElementTopTextPassPromo{
    color: #da343d;
    display: block;
}
.TraderRegistrationPromo02GridRegistrationFormElementTopTextPassPromo{
    grid-column: 2/span 3;
    margin-left: 0;
    display: none;
}

.TraderRegistrationPromo02GridRegistrationFormElementError>.TraderRegistrationPromo02GridRegistrationFormElementInput>.TraderRegistrationPromo02GridRegistrationFormElementTopTextError,.TraderRegistrationPromo02GridRegistrationFormElementError>.TraderRegistrationPromo02GridRegistrationFormElementTopTextError {
    display: block;
}
.TraderRegistrationPromo02GridRegistrationFormElementError>.TraderRegistrationPromo02GridRegistrationFormElementInput>input,.TraderRegistrationPromo02GridRegistrationFormElementError>.TraderRegistrationPromo02GridRegistrationFormElementInput>.TraderRegistrationPromo02GridPassword>input{
    background-color: #fff1f2;
    border-top: 1px solid #beb6b6;
    border-left: 1px solid #beb6b6;
}

.TraderRegistrationPromo02Success>.TraderRegistrationPromo02In{
    padding: 0;
}

.TraderRegistrationPromo02GridRegistrationFormElement{
    padding-right: 28px;
    padding-left: 27px;
}

.TraderRegistrationPromo02Success>.TraderRegistrationPromo02In>.TraderRegistrationPromo02InIn>.ButtonTraderRegistrationPromo02{
    margin-left: 0;
}
.TraderRegistrationPromo02Success>.TraderRegistrationPromo02In>.TraderRegistrationPromo02InIn{
    padding: 26px;
}
.TraderRegistrationPromo02SuccessTitle{
    font-size: 23px;
    font-weight: 700;
}

.TraderRegistrationPromo02SuccessText{
    font-size: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.TraderRegistrationPromo02SuccessPasswordWithdrawal{
    background-color: #eefbdf;
    padding: 26px;
}

.TraderRegistrationPromo02SuccessAllInfo{
    margin-top: 15px;
    margin-right: 15px;
}

.TraderRegistrationPromo02SuccessAllInfoElement{
    display: flex;
}

.TraderRegistrationPromo02SuccessAllInfoElementText{
    font-weight: 700;
    width: 250px;
    padding-bottom: 20px;
}
.TraderRegistrationPromo02SuccessAllInfoOut{
    display: flex;
    justify-content: space-between;
}
.TraderRegistrationPromo02SuccessAllInfoPrint>img{
    margin-right: 10px;
}
.TraderRegistrationPromo02SuccessAllInfoPrint{
    padding: 18px 18px 18px 19px ;
    max-width: 252px;
    height: min-content;
    text-decoration: underline;
    font-size: 15px;
    color: #3f3e3e;
    border-radius: 5px;
    border: 1px solid #908f8f;
    margin-top: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 1050px) {
    .TraderRegistrationPromo02SuccessAllInfoOut{
        display: block;
    }
}
@media only screen and (max-width: 800px) {
    .TraderRegistrationMain {
        display: block;
    }
    .OpenRealDocuments{
        margin: auto;
    }
    .TraderRegistrationPromo02{
        border: none;
        padding: 0;
    }
    .TraderRegistrationPromo02In{
        /*background-color: unset;*/
    }
}

@media only screen and (max-width: 550px) {
    .TraderRegistrationPromo02GridRegistrationFormElement{
        display: block;
    }
    .TraderRegistrationPromo02GridRegistrationFormElementTopTitle{
        margin-bottom: 12px;
    }
    .TraderRegistrationPromo02GridRegistrationFormElement3{
        grid-template-columns: auto;
    }
    .TraderRegistrationPromo02GridRegistrationFormElementTopText{
        grid-row: 2;
        margin-left: 0;
        margin-top: 3px;
        margin-bottom: 3px;
        line-height: 17px;
    }
    .TraderRegistrationPromo02GridRegistrationFormElementInput{
        grid-column: 1/span 2;
    }
    .TraderRegistrationPromo02SmallInput{
        max-width: unset;
    }
    .TraderRegistrationPromo02GridRegistrationFormElement3>.TraderRegistrationPromo02GridRegistrationFormElementTopTitle{
        margin-bottom: 0;
        grid-row: 1;
    }
    .TraderRegistrationPromo02GridRegistrationFormElement3>.TraderRegistrationPromo02GridRegistrationFormElementTopTextError{
        grid-column: 1/span 2;
    }
    .TraderRegistrationPromo02GridRegistrationFormElementInput{
        grid-column: 1/span 2;
    }
    .ButtonTraderRegistrationPromo02Password{
        grid-row: 1;
        margin-left: 0;
        position: relative;
        right: 20px;
        top: 5px;
    }
    .ButtonTraderRegistrationPromo02{
        width: unset;
    }
    .TraderRegistrationPromo02AllPasswordBackground>.TraderRegistrationPromo02GridRegistrationFormElement3>.TraderRegistrationPromo02GridRegistrationFormElementTopTitle{
        padding-bottom: 10px;
    }
    .TraderRegistrationPromo02AllPasswordBackground>.TraderRegistrationPromo02GridRegistrationFormElement3>.TraderRegistrationPromo02GridRegistrationFormElementInput>.TraderRegistrationPromo02GridPassword{
        width: 90%;
    }
    .ButtonTraderRegistrationPromo02{
        max-width: 90%;
        margin: 20px auto auto;
        width: 70%;
        margin-bottom: 20px;
    }

    .TraderRegistration>.StandartPage>.StandartTitle{
        display: none;
    }
    .TraderRegistrationPromo02Success{
        border: none;
    }
    .TraderRegistrationPromo02Success>.TraderRegistrationPromo02In>.TraderRegistrationPromo02InIn{
        padding: 0;
    }
    .TraderRegistrationPromo02Success>.TraderRegistrationPromo02In>.TraderRegistrationPromo02InIn>.TraderRegistrationPromo02SuccessTitle,
    .TraderRegistrationPromo02Success>.TraderRegistrationPromo02In>.TraderRegistrationPromo02InIn>.TraderRegistrationPromo02SuccessAllInfo,
    .TraderRegistrationPromo02Success>.TraderRegistrationPromo02In>.TraderRegistrationPromo02InIn>.TraderRegistrationPromo02SuccessText
    {
        padding-left: 20px;
        padding-right: 20px;
    }
    .TraderRegistrationPromo02SuccessText{
        line-height: 27px;
    }
    .TraderRegistration>.StandartPage>.TraderRegistrationMain>.TraderRegistrationPromo02Success{
        padding: 0;
    }

}

.TraderRegistrationPromo02GridRegistrationFormElementInput>.TraderRegistrationPromo02GridPassword>input, .TraderRegistrationPromo02GridRegistrationFormElementInput>input {
    width: 283px;
    height: 28px;
    font-size: 15px;
    padding-left: 9px;
    border-radius: 2px;
    /* box-shadow: 0 1px 1px 0 rgba(0,0,0,.25), inset 0 1px 3px 0 #171616; */
    border: solid 1px #d8e4eb;
    /*box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0px 1px 3px 0 #171616;*/
}

@media print {
    footer,header,.SubMenuClassName,.MainMenuOut,.BlueFooter,.StandartTitle,.OpenRealDocumentsOut,.TraderRegistrationPromo02SuccessAllInfoOut>a,.ButtonTraderRegistrationPromo02,.PlateTop{
        display: none;
    }
}

.TraderRegistrationPromo02GridRegistrationForm>.ButtonTraderRegistration,.ButtonTraderRegistrationPromo02>a>.ButtonTraderRegistration{
    margin-right: 25px;
    width: auto;
}

@media only screen and (max-width: 900px) {
    .TraderRegistrationPromo02Main{
        display: block;
    }
}
@media only screen and (max-width: 550px) {
    .TraderRegistrationPromo02Main{
        padding-top: 40px;
    }
    .TraderRegistrationPromo02Title{
        font-size: 30px;
    }
    .TraderRegistrationPromo02H1{
        font-size: 24px;
    }
    .TraderRegistrationPromo02SuccessAllInfoElement{
        display: block;
    }
    .TraderRegistrationPromo02Success>.TraderRegistrationPromo02In{
        padding: 10px;
    }
    .TraderClientOpenPromo02>.StandartInZeroBig{
        padding-left: 5px;
        padding-right: 5px;
    }
    .TraderRegistrationPromo02MainLeftElement{
        grid-template-columns: 50px auto;
    }
    .TraderRegistrationPromo02GridRegistrationForm>.ButtonTraderRegistration, .ButtonTraderRegistrationPromo02>a>.ButtonTraderRegistration{
        margin-right: auto;
    }
    .TraderRegistrationPromo02GridRegistrationFormElementInput>.TraderRegistrationPromo02GridPassword>input, .TraderRegistrationPromo02GridRegistrationFormElementInput>input {
        width: 90%;
    }
    .TraderRegistrationPromo02GridRegistrationForm>.ButtonTraderRegistration, .ButtonTraderRegistrationPromo02>a>.ButtonTraderRegistration{
    }

}
