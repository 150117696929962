.LayoutInvest{
    font-family: MyriadPro, sans-serif;
}

.LayoutInvestHeaderLogoMain>a>img{
    width: 288px;
}

.InvestTitle{
    font-size: 36px;
    padding-top: 10px;
}


.LayoutInvestHeaderLogo{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.LayoutInvest>.LayoutInvestHeader{
    margin-top: 40px;
}

.LayoutInvestHeaderLogoMainText{
    font-size: 14px;
    color: #aba6ad;
}

.LayoutInvestHeaderLogoLinkElement,.LayoutInvestHeaderLogoLinkElement>a{
    display: flex;
    align-items: center;
    text-decoration: none;
}

.LayoutInvestHeaderLogoLinkElement>img,.LayoutInvestHeaderLogoLinkElement>a>img{
    padding-right: 12px;
}


.LayoutInvestHeaderLogoLinkElementText{
    font-size: 18px;
    color: #1e1a1f;
}

.LayoutInvestHeaderLogoLinks{
    display: flex;
    align-items: center;
}

.LayoutInvestHeaderLogoLinks>a{
    text-decoration: none;
    margin-left: 10px;
    margin-right: 10px;
}

.LayoutInvestHeaderButtons{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 20px;
    /*height: 77px;*/
}

.LayoutInvestHeaderButtonElement {
    height: 60px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}

.LayoutInvestHeaderButtonElement>a{
    text-decoration: none;
}

.InvestIndex{
    margin-top: 66px;
}
.LayoutInvestHeaderButtonElementText{
    position: relative;
    z-index: 5;
    font-size: 19px;
    font-weight: 600;
    padding-left: 26px;
    padding-right: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
}

.LayoutInvestHeaderButtonElementText>img{
    padding-left: 15px;
}

.LayoutInvestHeaderButtonElementIn{
    position: absolute;
    transform: skewX(-15deg);
    border-radius: 5px;
    background-image: linear-gradient(to top, #ffeb7d, #ffde1f, #fff77e, #fffcd5);
    box-shadow: inset 0 0 0 2px rgba(255, 251, 153, 0.35);
    border: solid 2px #ffa900;
    height: 100%;
    width: 100%;
}
.LayoutInvestHeaderButtonElement:hover>.LayoutInvestHeaderButtonElementIn{
    background-image: linear-gradient(to top, #ffeb7d, #fce563, #fff77e, #fffcd5);

}

.LayoutInvestHeaderButtonElement:active>.LayoutInvestHeaderButtonElementIn{
    background-image: none;
    border: none;
    background: #5c505f;
}

.LayoutInvestFooter{
    margin-top: 40px;
}

.LayoutInvestFooterEmail{
    text-decoration: underline;
    font-size: 12px;
    padding-bottom: 3px;
    color: #0894f7;
}

.LayoutInvestFooterCopyright{
    font-size: 12px;
    color: #111111;
}

@media only screen and (max-width: 1100px) {
    .LayoutInvestHeader{
        margin-top: 5px;
    }
    .LayoutInvestHeaderLogo {
        display: grid;
        justify-content: center;
        grid-template-columns: 100%;
        width: 100%;
    }
    .LayoutInvestHeaderLogoMain{
        display: flex;
        justify-content: center;
    }
    .LayoutInvestHeaderLogoLinks{
       display: flex;
        margin-top: 1px;
       justify-content: space-around;
        flex-wrap: wrap;
    }
    .LayoutInvestHeaderLogoLinks>a{
        margin-top: 17px;
    }
}
@media only screen and (max-width: 500px) {
    .LayoutInvestHeaderLogoMain>img{
        width: 230px;
        height: 33px;
    }
}



    @media only screen and (max-width: 500px) {
    .InvestIndexMain>div{
        padding-right: 5px;
        padding-left: 5px;
    }
        .LayoutInvestHeaderButtonElement{
            width: 100%;
        }
}


.InvestPlateTop{
    top: 0;
    width: 100%;
    height: 47px;
    color: #acb2b8;
    background-color: #1e2e3e;
    position: fixed;
    z-index: 1000;
}

.InvestPlateTopIn>.CheckLanguageOutOut{
    display: none;
}
.InvestPlateTopIn{
    height: 100%;
    margin: auto;
    max-width: 1144px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 1184px) {
    .InvestPlateTopIn{
        padding-right: 20px;
        padding-left: 20px;
    }
}
@media only screen and (max-width: 1000px) {
    .InvestPlateTop {
        display: none;
    }
}

.InvestPlateTopIn>.CheckLanguageOutOut{
    height: 100%;
}
.InvestPlateTopIn>.CheckLanguageOutOut>.CheckLanguage>.CheckLanguageTriangle>img{
    display: none;
}
.InvestPlateTopIn>.CheckLanguageOutOut>.CheckLanguage>.CheckLanguageTriangle>.TriangleForPlate{
    display: block;
}
.InvestPlateTopIn>.CheckLanguageOutOut:hover{
    background-color: #101921;
}
.InvestPlateTopIn>.CheckLanguageOutOut>.CheckLanguage>.CheckLanguageTitle{
    display: none;
}

.InvestPlateTopInElement{
    display: flex;
    align-items: center;
}
.InvestPlateTopInElement>img{
    margin-right: 5px;
}

.InvestPlateTopIn>a,.InvestPlateTopIn>a:visited{
    text-decoration: none;
    color: #acb2b8;
}

.InvestPlateTopInElement>.HeaderAllRightLK{
    margin-top: 0;
    border-bottom: none;
}
.InvestPlateTopInElement>.HeaderAllRightLK>div{
    color: #acb2b8;
    font-size: 13px;
    font-weight: 700;
    border-bottom: none;
}
.InvestPlateTopInElement>.HeaderAllRightLK>div>.HeaderAllLockPlate{
    display: block;
}

.InvestPlateTopInElement>.HeaderAllRightLK>div>.HeaderAllLock{
    display: none;
}

.LayoutInvestHeaderTopLine{
    height: 10px;
    width: 100%;
    position: absolute;
    background-color: #f9bf11;
    top: 0;
    left: 0;
}