.AboutLoyality{
    margin-bottom: 43px;
}

.AboutLoyalityGridTextText{

    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    position: relative;
}

.AboutLoyalityGrid>.AboutLoyalityGridTextText{
    padding: 23px 23px 0 35px;
}

.AboutLoyalityGridTextImg>img{
    position: absolute;
    top: 0;
    left : 35px;
    width: 24px;
    height: 2px;
}

.AboutLoyalityGridImg{
    background: url("../../../static/img/About/Loyal/AboutLoyal01.png") center;
    background-size: cover;
    width: 100%;
    height: 320px;
}
.AboutLoyalityGrid{
    display: grid;
    grid-template-columns: 652px auto;
}
.AboutLoyalityGridText{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 80%;
    margin: 30px auto;
    margin-bottom: 0;
}
.AboutLoyality>div>.StandartH2{
    padding-bottom: 5px;
}

.AboutLoyalityGridText01{

    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
}

.AboutLoyalityGridText02{

    font-weight: 400;
    font-size: 13px;
    line-height: 27px;
}


@media only screen and (max-width: 1000px) {
    .AboutLoyalityGrid {
        display: grid;
        grid-template-columns: auto;
    }

    .AboutLoyalityGridTextImg > img {
        display: none;
    }

    .AboutLoyalityGrid>.AboutLoyalityGridTextText{
        padding: 0 0 36px 0;
        grid-row: 1;
    }

    .AboutLoyalityGridImg{
        margin: auto;
    }
}

@media only screen and (max-width: 700px) {
    .AboutLoyalityGridTextText{
        padding-left: 14px;
        padding-right: 14px;
    }
    .StandartPage>.AboutLoyalityGridIn{
        padding-left: 1px;
        padding-right: 1px;

    }
    .AboutLoyalityGridText{
        width: 100%;
        max-width: 400px;
    }
    .AboutLoyalityGridTextText{
        font-size: 17px;
        line-height: 25px;
    }
    .AboutLoyalityGridText{
        grid-template-columns: auto;
        width: auto;
    }
    .AboutLoyalityGridText02{
        margin-top: 20px;
    }}

@media only screen and (max-width: 500px) {

    .AboutLoyalityGridImg{
        background: url("../../../static/img/About/Loyal/AboutLoyal01.png") center;
        background-size: cover;
        width: 100%;
        height: 240px;
    }
}
