.TraderAllArchive>div{
    width: 100%;
}

.TraderAllArchive{
    display: flex;
}

.TraderAllArchiveMain{
    display: grid;
    grid-template-columns: minmax(500px,1000px) 204px;
}

.TraderAllArchiveMain>.ContestMenu {
    margin-top: 0;
}