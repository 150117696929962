.PartnerInternetGrid01{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 40px;
}

.PartnerInternetGrid01ElementTitle{

    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    color: #09518e;
    padding-bottom: 13px;
    border-bottom: 3px solid #e8eef4;
    margin-bottom: 13px;
}

.PartnerInternet02{
    background: url("../../../static/img/Partners/Program/PartnersProgram01.png") right;
    background-color: black;

    background-size: cover;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    color: white;
    text-align: center;
    padding: 37px 40px 37px 40px;
    margin-top: 34px;
    margin-bottom: 32px;
}

.PartnerInternet03{
    display: grid;
    /*grid-template-columns: 1fr 1fr 1fr;*/
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
}

.PartnerInternet03ElementTitle{

    font-weight: 700;
    font-size: 19px;
    line-height: 28px;
    color: #09518e;
    padding-bottom: 20px;
}

.PartnerInternet03Element01GridElementImg{
    width: 106px;
    height: 84px;
    border: 1px #cedce8 solid;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PartnerInternet03ElementImg{
    width: 249px;
    height: 189px;
    border: 1px #cedce8 solid;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 22px;
}

.PartnerInternet03Element01GridElement{
    display: grid;
    grid-template-columns: 106px auto;

    font-weight: 400;
    font-size: 13px;
    line-height: 23px;
    padding-bottom: 29px;
}

.PartnerInternet03Element01GridElementText{
    padding-left: 18px;
}

.PartnerInternet03Element01GridElementText>a{
    position: relative;
    top: -4px;
}

.PartnerInternet03ElementFlex{
    display: flex;

    font-weight: 400;
    font-size: 13px;
}

.PartnerInternet03ElementFlex>a{
    padding-right: 10px;
}

.PartnerInternet03{
    padding-bottom: 39px;
}

.PartnerInternet03Column{
    display: inline-block;
    height: available;
}

@media only screen and (max-width: 900px) {
    .PartnerInternet03{
        /*grid-template-columns: 1fr 1fr;*/
    }
}

@media only screen and (max-width: 550px) {
    .PartnerInternetGrid01,.PartnerInternet03{
        grid-template-columns: auto;
    }
    .PartnerInternetGrid01Element{
        padding-top: 35px;
    }
    .PartnerInternet03Column{
        padding-top: 29px;
    }
    .PartnerInternet03Element>.StandartText>img{
        display: none;
    }
    .PartnerInternet02{
        font-size: 13px;
    }
}