.MenuForTelephone{
    cursor: pointer;
}

.PhoneMenuOut{
    background-color: #003269;
    /*background-image: linear-gradient(to top, #98c7ee, #88b6dc);*/

    left: 0;
    z-index: 200;
    top: 0;
    position: absolute;
    width: 255px;
}

.PhoneMenuBorder>img{
    position: absolute;
    top: 10px;
    right: -10px;
}

.PhoneMenuElement{
    color: white;
    font-size: 15px;
    /*border-bottom: solid 1px #274c72 ;*/
    cursor: pointer;
}


.PhoneMenuElement>div>.PhoneMenuElementLeft{
    display: none;
}

.PhoneMenuElementIn{
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    height: 30px;
    padding-top: 14px;
    padding-bottom: 5px;
    border-bottom: 1px solid #274c72;
}

.PhoneMenuElementIn>img{
    padding-right: 10px;
    position: relative;
    top: 3px;
}

.PhoneMenuSubElementOut>.PhoneMenuElementIn>a{
    text-decoration: none;
    color: white;
    padding-left: 5px;
}

.PhoneMenuElementNoActive{
    display: none;
}

.PhoneMenuSubElementOut{
    display: none;
}

.PhoneMenuElementActive>.PhoneMenuSubElementOut{
    display: block;
}

.PhoneMenuElementActive>.PhoneMenuElementIn{
    background-color: #011531;
    justify-content: left;
}

.PhoneMenuElementActive>div>.PhoneMenuElementLeft{
    display: block;
}

.PhoneMenuElementActive>div>.PhoneMenuElementRight{
    display: none;
}

.MainMenuMainButtonElementDark{
    background-color: #061c34;
    margin: 10px 15px 10px 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
}

.MainMenuMainButtonsDark>.MainMenuMainButtonElementDark>a{
    color: white;

}

.PhoneMenuElementTwitter>a,.PhoneMenuElementTwitter>a:visited{
    color: white;
}
.PhoneMenuElementTwitter>a>img{
    position: relative;
    top: 3px;
    margin-right: 10px;
}
.PhoneMenuElementTwitter{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #274c72;
}