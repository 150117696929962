.PartnerRegional{
    margin-bottom: 43px;
}

.PartnerRegionalGridTextText{

    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    position: relative;
}

.PartnerRegionalGrid>.PartnerRegionalGridTextText{
    padding: 23px 23px 0 35px;
}

.PartnerRegionalGridTextImg>img{
    position: absolute;
    top: 0;
    left : 35px;
    width: 24px;
    height: 2px;
}

.PartnerRegionalGridImg{
    background: url("../../../static/img/Partners/Regional/PartnersRegional01.png") center;
    background-size: cover;
    width: 100%;
    height: 320px;

    max-width: 650px;
    margin: auto auto 31px;
}
.PartnerRegionalGrid{
    display: grid;
    grid-template-columns: 652px auto;
}
.PartnerRegionalGridText{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 80%;
    margin: 30px auto;
}

.PartnerRegionalGridText01{

    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
}

.PartnerRegionalGridText02{

    font-weight: 400;
    font-size: 13px;
    line-height: 27px;
}


@media only screen and (max-width: 1000px) {
    .PartnerRegionalGrid {
        display: grid;
        grid-template-columns: auto;
    }

    .PartnerRegionalGridTextImg > img {
        display: none;
    }

    .PartnerRegionalGrid>.PartnerRegionalGridTextText{
        padding: 0 0 36px 0;
        grid-row: 1;
    }


}




@media only screen and (max-width: 700px) {
    .PartnerRegionalGridTextText{
        padding-left: 14px;
        padding-right: 14px;
    }
    .StandartPage>.PartnerRegionalGridIn{
        padding-left: 1px;
        padding-right: 1px;

    }
    .PartnerRegionalGridText{
        width: 100%;
        max-width: 400px;
    }
    .PartnerRegionalGridTextText{
        font-size: 17px;
        line-height: 25px;
    }
    .PartnerRegionalGridText{
        grid-template-columns: auto;
        width: auto;
    }
    .PartnerRegionalGridText02{
        margin-top: 20px;
    }}

@media only screen and (max-width: 500px) {

    .PartnerRegionalGridImg{
        background-size: cover;
        width: 100%;
        height: 240px;
    }
}

.PartnerRegionalNumber01{
    display: flex;
    padding-top: 12px;
}

.PartnerRegionalNumber01>img{
    position: relative;
    top: 3px;
    margin-right: 6px;
}

.PartnerRegionalNumber01:first-of-type{
    padding-top: 0;
}

.PartnerRegionalBlue{
    background-color: #e5f3ff;
    padding-top: 22px;
    padding-bottom: 22px;
}

.PartnerRegionalBlueWhite{
    font-weight: 700;
    background-color: white;
    padding: 15px 45px 15px 15px;
    position: relative;
    left: -15px;
}

.PartnerRegionalPink{
    background-color: #ffe9e6;
    padding-bottom: 32px;
    margin-top: 27px;
}

.PartnerRegionalPink>.StandartH2{
    padding-bottom: 0;
}

@media only screen and (max-width: 800px) {
    .PartnerRegionalBlueWhite{
        background: none;
    }
}

.PartnerRegionalSmallText{

    font-weight: 700;
    font-size: 15px;
    line-height: 35px;
}

.PartnerRegionalNumberZero{
    padding-top: 0;
}

.StandartPageIn>.StandartH2NumberZero{
    padding-bottom: 0;
}

.PartnerRegional03{
    padding-top: 30px;
    padding-bottom: 30px;
}