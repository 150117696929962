.IndexPartnersCalc{
    width: 100%;
    /*background-image: url('../../../static/img/Index/PartnersCalc/backGround.svg');*/
    background: #fff3de;
    grid-column: 1/span 4;
    background-size: contain;
    margin-top: 30px;
    position: relative;
    min-height: 203px;
}

.IndexPartnersCalcTry{
    display: flex;
    margin-top: 25px;
    /*align-items: top;*/
    justify-content: center;
}
.IndexPartnersCalcTryBig{
    display: flex;
    justify-content: center;
}
.IndexPartnersCalc>div{
    position: relative;
    z-index: 100;
}

.IndexPartnersCalcTitleTop{
    font-weight: 700;
    font-size: 23px;
    text-align: center;
    padding-top: 25px;
    position: relative;
    z-index: 5;
}
.IndexPartnersCalcH2{
    font-size: 15px;
    text-align: center;
    margin-top: 10px;
    position: relative;
    z-index: 5;
}

.IndexPartnersCalcTryBigText{
    font-size: 15px;
    line-height: 21px;
    margin-right: 23px;
    position: relative;
    z-index: 5;
}

.IndexPartnersCalcTryBigEditText{
    font-size: 13px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 25px;
    position: relative;
    z-index: 5;
}

.IndexPartnersCalcTryBigEdit>input{
    background: #fff3de;
    border: rgba(0,0,0,0.8) solid 2px;
    border-radius: 3px;
    height: 36px;
    font-size: 15px;
    text-align: right;
    width: 80px;
    padding-right: 9px;
    margin-right: 17px;
    position: relative;
    z-index: 5;
}

.IndexPartnersCalc>.StandartNotVisible{
    display: none;
}

.IndexPartnersCalcResult{
    display: flex;
    justify-content: center;
    align-items: center;
}

.IndexPartnersCalcResultSum{
    font-size: 43px;
    color: #2ba048;
    margin-right: 16px;
}

.IndexPartnersCalcResultText{
    font-size: 17px;
}

.IndexPartnersCalcResultButton{
    color: #09518e;
    text-decoration: underline;
    cursor: pointer;
}

.IndexPartnersCalcResultButton>img{
    margin-right: 10px;
    margin-left: 17px;
}

.IndexPartnersCalcResult{
    margin-top: 25px;
    margin-bottom: 40px;
}

@media only screen and (max-width: 600px) {
    .IndexPartnersCalcTry {
        flex-wrap: wrap;
        padding-right: 30px;
        padding-left: 30px;
    }
    .IndexPartnersCalcTry>.ButtonStandartOut {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 450px) {
    .IndexPartnersCalcResult{
        display: grid;
        grid-template-columns: auto auto;
    }
    .IndexPartnersCalcResultSum{
        display: flex;
        justify-content: flex-end;
    }
    .IndexPartnersCalcResultButton{
        margin-top: 20px;
        grid-column: 1/span 2;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.IndexPartnersCalcLeft{
    height: 100%;
    position: absolute;
}
.IndexPartnersCalcLeft:nth-of-type(2){
    height: 100%;
    position: absolute;
    left: 140px;
}
.IndexPartnersCalcRight{
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}

.IndexPartnersCalcRightPhone{
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
}

@media only screen and (max-width: 800px) {
    .IndexPartnersCalcLeft:nth-of-type(2){
        display: none;
    }
    .IndexPartnersCalcRight{
        display: none;
    }
    .IndexPartnersCalcRightPhone{
        display: block;
    }
}
