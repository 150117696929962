.AboutPressUsGrid{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 40px -30px 40px -30px;
}

.AboutPressUsGridElement{
    margin: 0 30px 51px 30px;
}

.AboutPressUsGridElementImg{
    box-shadow: 3.5px 3.5px 20px 0 rgba(0, 39, 79, 0.35);

}

.AboutPressUsGridElementTextTitle{
    margin : 10px 0 0 0;
    text-align: left;
}

.AboutPressUpText{
    font-weight: 400;
    font-size: 21px;
    line-height: 28px;
    color: #181818;
}

.AboutPressUsGridElementText{
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    color: black;
}