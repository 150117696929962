body{
    margin: 0;
    font-family: Arial, sans-serif;
}

div, input, button, textarea{
    outline:none;
}

a, a:visited{
    color: #02518e;
}

a:hover{
    color: #2288da;
}

.StandartEmail{
    color: #2288da;
    text-decoration: underline;
}
.StandartProbel{
    margin-left: 5px;
}
.StandartHref{
    color: #02518e;
    text-decoration: underline;
    cursor: pointer;
}

.StandartHref:hover{
    color: #2288da;
}

.TopTitle{
    display: flex;
    justify-content: space-between;
}

.StandartTitleOutTerminal{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.StandartTitleOutTerminal>.IfYouHaveQuestions{
    position: relative;
    top: 5px;
}


.StandartPage{
    width: 100%;
    margin: auto;
    max-width: 1144px;
}

.StandartPageIn{
    padding-left: 28px;
    padding-right: 28px;
}

.StandartPageInSpecial{
    margin-left: 28px;
    margin-right: 28px;
}

@media only screen and (max-width: 800px) {
    .StandartPageInSpecial{
        margin-left: 0;
        margin-right: 0;
    }
}

@media only screen and (max-width: 1200px) {
    .StandartInZeroBig {
        padding-left: 25px;
        padding-right: 25px;
    }
}

.StandartAllBlack500{
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    z-index: 500;
}

@media only screen and (max-width: 1200px) {
    .StandartIn{
        margin-left: 30px;
        margin-right: 30px;
    }
}

.StandartPhone{
    display: none;
}

@media only screen and (max-width: 500px) {
    .StandartPhone{
        display: block;
    }
    .StandartNoPhone{
        display: none;
    }
    .StandartIn{
        margin-left: 5px;
        margin-right: 5px;
    }
}
.StandartLinkLeft{
    padding-left: 5px;
}

.StandartTitle{
    font-weight: 700;
    font-size: 23px;
    color: black;
    line-height: 28px;
    padding-top: 28px;
    padding-bottom: 28px;
}

.StandartH2{
    font-weight: 700;
    font-size: 19px;
    color: black;
    line-height: 28px;
    padding-top: 28px;
    padding-bottom: 28px;
}

.StandartText{
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    color: black;
}

.StandartPageInRight{
    padding-right: 31px;
}

@media only screen and (max-width: 1000px) {
    .StandartPageIn{
        padding-left: 20px;
        padding-right: 20px;
    }

    .StandartPageInRight{
        padding-right: 20px;
    }

}

@media only screen and (max-width: 800px) {
    .StandartPageIn{
        padding-left: 14px;
        padding-right: 14px;
    }

    .StandartPageInRight{
        padding-right: 14px;
    }
    .StandartTitle{
        font-size: 19px;
        line-height: 28px;
        padding-top: 24px;
        padding-bottom: 28px;
    }

    .StandartText{
        font-size: 13px;
        line-height: 23px;
    }
}

.HrGrayVerticalLine{height: 100%; width: 1px;  background-color: #0a79bf;}

.StandartNotVisible{
    display: none;
}

.ButtonStandartOut{
    display: flex;
    justify-content: center;
}

.ButtonGreen{
    width: 136px;
    height: 39px;
    border-radius: 3px;
    background-image: linear-gradient(to top, #4da400, #5fb314);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
}

.StandartButtonOut{
    display: flex;
}

.ButtonRed{
    font-weight: 700;
    font-style: normal;
    font-size: 17px;
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 42px;
    width: auto;
    border-radius: 28px;
    background-color: #ea4f40;
    border: 2px solid #ea4f40;
    color: white;
    text-align: center;
    cursor: pointer;

}

.ButtonRed:hover{
    background-color: #2f4563;
    border: 2px solid #2f4563;
    color: white;
    transition: all .2s linear;
}

.ButtonRed:active{
    background-color:  #2f4563;
    border-color:  #2f4563;
    color: white;
}

.ButtonRedContact{
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    height: 36px;
    line-height: 42px;
    width: 100%;
    background-color: #eb6054;
    border: 2px solid #eb6054;
    color: white;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
}

.ButtonRedContact>div{
    margin-top: -4px;
}

.ButtonRedContact:hover{
    background-color: #2f4563;
    border: 2px solid #2f4563;
    color: white;
    transition: all .2s linear;
}


.ButtonRedContact:active{
    background-color: #2f4563;
    border: 2px solid #2f4563;
    color: white;
    transition: all .2s linear;
}

.ButtonIndex{
    font-weight: 500;
    font-style: normal;
    font-size: 17px;
    height: 37px;
    padding-left: 17px;
    padding-right: 15px;
    line-height: 35px;
    width: auto;
    border-radius: 4px;
    background: none;
    border: 1px solid #d8d5db;
    color: #3d2c49;
    text-align: center;
    display: inline-flex;
}

.ButtonIndex:hover{
    background-color: white;
    border: 1px solid #8a8092;
    transition: all .2s linear;
}

.ButtonIndex:active{
    background-color:  #2f4563;
    border-color:  #2f4563;
    color: white;
}

.Button404{
    font-weight: 400;
    font-style: normal;
    font-size: 17px;
    height: 34px;
    line-height: 32px;
    padding-left: 20px;
    padding-right: 20px;
    width: auto;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #d7d8dc;
    color: #5e667b;
    text-align: center;
    cursor: pointer;

}

.Button404:hover{
    background-color: white;
    border: 1px solid #8a8092;
    transition: all .2s linear;
}

.Button404:active{
    background-color:  #2f4563;
    border-color:  #2f4563;
    color: white;
}

.ButtonBlack{
    font-weight: bold;
    font-size: 14px;
    height: 38px;
    border-radius: 4px;
    padding-left: 23px;
    padding-right: 23px;
    line-height: 38px;
    border: solid 2px #161645;
    background: none;
    color: #151544;
    text-align: center;
}

.ButtonBlack:hover{
    border-color:  #ffb14e;
}

.ButtonBlack:active{
    border-radius: 4px;
    box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.15);
    background-color: #ffbf58;
}

.ButtonBlue{
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 36px;
    width: auto;
    border-radius: 5px;
    background: linear-gradient(to top, #005eb3, #097ac9);
    color: white;
    text-align: center;
    cursor: pointer;
}

.ButtonBlue:hover{
    background: linear-gradient(to top, #ea4f40, #ea4f40);
    transition: all .2s linear;

}

.ButtonRed:active{
    background-color:  #ea4f40;
    color: white;
}

.ButtonBlueFAQ{
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    height: 35px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 36px;
    width: auto;
    border-radius: 5px;
    background: linear-gradient(to top, #005eb3, #097ac9);
    color: white;
    text-align: center;
    cursor: pointer;
    border: 1px #097ac9 solid;
}

.ButtonBlueFAQ:hover{
    background: #176fb8;
}

.ButtonBlueFAQ:active{
    background-color:  #f8f6f6;
    color: #828282;
    border: 1px #908f8f solid;
}



.ButtonWebinar{
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 36px;
    width: auto;
    border-radius: 5px;
    background-image: linear-gradient(to top, #4aa600, #5cb500);
    color: white;
    text-align: center;
    cursor: pointer;
}

.ButtonWebinar:hover{
    background: linear-gradient(to top, #005eb3, #097ac9);
    transition: all .2s linear;

}

.ButtonWebinar:active{
    background: linear-gradient(to top, #ea4f40, #ea4f40);
    color: white;
}


.ButtonVPS{
    width: 136px;
    height: 39px;
    border-radius: 3px;
    background-image: linear-gradient(to top, #4aa600, #5cb500);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
}

.ButtonVPS:hover{
    background-image: linear-gradient(to top, #51b302, #62bd03);
    transition: all .2s linear;
}

.ButtonVPS:active{
    background-image: linear-gradient(to top, #3a8101, #4d9602);
}

.ButtonBlueFooter{
    min-width: 210px;
    height: 50px;
    border-radius: 3px;
    background-image: linear-gradient(to top, #ffd491, #ffe897);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: 700;
}

.ButtonBlueFooter:hover{
    background-image: linear-gradient(to top, #fae9ce, #fdebab);
    transition: all .2s linear;
}

.ButtonBlueFooter:active{
    background-image: linear-gradient(to top, #fec773, #fdde71);
}
.ButtonSend{
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 36px;
    width: 100%;
    border-radius: 5px;
    background: linear-gradient(to top, #005eb3, #097ac9);
    color: white;
    text-align: center;
    cursor: pointer;
    border: 1px #097ac9 solid;
}

.ButtonSend:hover{
    background: #176fb8;
}

.ButtonSend:active{
    background-color:  #f8f6f6;
    color: black;
    border: 1px #908f8f solid;
}

.TraderFeedbackSuccessTitle{
    font-size: 13px;
    font-weight: 700;
}

.TraderFeedbackSuccessText{
    color: black;
    text-align: center;
    font-size: 13px;
    margin-top: 3px;
}

.TraderFeedbackSuccessTextBlue{
    color: #125590;
    text-decoration: underline;
    cursor: pointer;
}

.ButtonTraderRegistration{
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 36px;
    width: 100%;
    border-radius: 5px;
    background: linear-gradient(to top, #005eb3, #097ac9);
    color: white;
    text-align: center;
    cursor: pointer;
    border: 1px #097ac9 solid;
    max-width: 381px;
    margin-top: 25px;
    margin-left: 26px;
}

.ButtonTraderRegistration:hover{
    background: #176fb8;
}

.ButtonTraderRegistration:active{
    background-color:  #f8f6f6;
    color: black;
    border: 1px #908f8f solid;
}

.ButtonTraderRegistration>a{
    text-decoration: none;
    color: black;
}
.TraderRegistrationInIn>.ButtonTraderRegistration>a{
    text-decoration: none;
    color: white;
}

.ButtonNewsAll{
    position: relative;
    font-weight: 700;
    font-size: 14px;
    height: 35px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 32px;
    border-radius: 18px;
    background: none;
    color: #2d508d;
    text-align: center;
    cursor: pointer;
    border: 2px #2d508d solid;
    z-index: 5;
    margin-bottom: 20px;
}

.ButtonNewsAll:hover{
    border: 2px solid #6f8cbd;
}

.ButtonNewsAll:active{
    background: #abb9d1;
}

.html404TopButton{
    font-weight: bold;
    font-size: 14px;
    height: 38px;
    border-radius: 20px;
    padding-left: 23px;
    padding-right: 23px;
    line-height: 38px;
    border:  2px solid #560d4b;
    background: none;
    color: #023776;
    text-align: center;
    margin-right: 26px;
}

.html404TopButton:hover{
    border-color:  #560d4b;
    color: #560d4b;
}

.html404TopButton:active{
    border-radius: 4px;
    box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.15);
}

.ButtonTerminalMobile{
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 36px;
    width: auto;
    border-radius: 25px;
    background: linear-gradient(to top, #005eb3, #097ac9);
    color: white;
    text-align: center;
    cursor: pointer;
}

.ButtonTerminalMobile:hover{
    background: #eceae6;
    transition: all .02s linear;
    color: #0069d5;
    border: 1px solid #0069d5;
}

/*.ButtonTerminalMobile:active{*/
/*    background-color:  #ea4f40;*/
/*    color: white;*/
/*}*/