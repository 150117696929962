

.IndexContest{
    grid-column: 1/span 4;

}
.IndexContestsIMG{
    width: 492px;
    height: 251px;
    margin: auto;
    position: relative;

}

.IndexContestsIMG01{
    grid-column: 1/span 2;
}
.IndexContestsIMG02{
    grid-column: 3/span 2;
}

.IndexContestsIMGText{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.IndexContestsIMGArrowLeft{
    position: absolute;
    left: 10px;
    cursor: pointer;
}
.IndexContestsIMGArrowRight{
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.IndexContestsIMGTextIn{
    width: 290px;
    height: 74px;
    position: absolute;
    top: 10px;
    right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #18609e;
    color: white;
}

.IndexContestsIMGTextIn:before {
    content: "";
    position: absolute;
    bottom: -7px;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7px 0 0;
    border-color: #002e53 transparent transparent transparent;
}

.IndexContestsIMGTextInIn>.IndexContestsIMGTextTitle>a{
    color: white;
}
.IndexContestsIMGTextTitle{
    text-align: center;
    text-decoration: underline;
    font-weight: 700;
    font-size: 17px;
}
.IndexContestsIMGTextText{
    font-size: 13px;
    text-align: center;

}

.IndexContestPad{
    display: none;
}

@media only screen and (max-width: 1100px) {
    .IndexContestWeb{
        display: none;
    }
    .IndexContestPad{
        display: block;
    }
}
@media only screen and (max-width: 550px) {
    .IndexContestsIMG{
        width: 90%;
        height: auto;
        /*overflow: hidden;*/
    }
    .IndexContestsIMG>img{
        width: 100%;
        height: auto;
    }
    .IndexContestsIMGTextIn{
        width: 90%;
    }
    .IndexMainElements{
        padding-left: 5px;
        padding-right: 5px;
    }
}
@media only screen and (max-width: 400px) {
.IndexMainWebinarGrid,.IndexMainPromoGrid{
    font-size: 12px;
}}