.InvestVideo{
    display: grid;
    /*grid-template-columns: minmax(610px, 320px) minmax(310px, 200px) ;*/
    grid-template-columns: auto 250px;
    grid-column-gap: 50px;
}

.ContestVideoTop{
    margin-top: 30px;
}

.InvestIndexMainVideo{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.AllInvestMapVideosElement{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 320px;
}

.AllInvestMapVideosElement>img{
    margin-bottom: 10px;
}

@media only screen and (max-width: 1000px) {
.InvestVideo{
    grid-template-columns: auto;
}
    .InvestRight{
        grid-row: 1;
    }
}
