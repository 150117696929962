.MT4Top{
    border: 1px solid #d8e4eb;
    border-radius: 5px;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.MT4MainTop{
    border: 1px solid #d8e4eb;
    border-radius: 5px;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.MT4TopIn{
    box-shadow: inset 0 0 59px rgba(14, 106, 166, 0.05);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr ;
}
.MT4MainTopIn{
    box-shadow: inset 0 0 59px rgba(14, 106, 166, 0.05);
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.MT4TopIn>a{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MT4TopElementIn{
    justify-content: center;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    color: #02518e;
    text-decoration: underline;
    cursor: pointer;
}

a, a:visited {
    color: #02518e;
}

@media only screen and (max-width: 950px) {
    .MT4TopIn{
        grid-template-columns: 1fr 1fr;
    }
}

.MT4TopYoutube{
    display : flex;
    justify-content: center;
}
