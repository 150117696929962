.PartnerWhitelabel{
    margin-bottom: 43px;
}

.PartnerWhitelabelGridTextText{

    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    position: relative;
}

.PartnerWhitelabelGrid>.PartnerWhitelabelGridTextText{
    padding: 23px 23px 0 35px;
}

.PartnerWhitelabelGridTextImg>img{
    position: absolute;
    top: 0;
    left : 35px;
    width: 24px;
    height: 2px;
}

.PartnerWhitelabelGridImg{
    background: url("../../../static/img/Partners/WL/PartnersWL01.png") center;
    background-size: cover;
    width: 100%;
    height: 320px;

    max-width: 650px;
    margin: auto auto 31px;
}
.PartnerWhitelabelGrid{
    display: grid;
    grid-template-columns: 652px auto;
}
.PartnerWhitelabelGridText{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 80%;
    margin: 30px auto;
}

.PartnerWhitelabelGridText01{

    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
}

.PartnerWhitelabelGridText02{

    font-weight: 400;
    font-size: 13px;
    line-height: 27px;
}


@media only screen and (max-width: 1000px) {
    .PartnerWhitelabelGrid {
        display: grid;
        grid-template-columns: auto;
    }

    .PartnerWhitelabelGridTextImg > img {
        display: none;
    }

    .PartnerWhitelabelGrid>.PartnerWhitelabelGridTextText{
        padding: 0 0 36px 0;
        grid-row: 1;
    }


}




@media only screen and (max-width: 700px) {
    .PartnerWhitelabelGridTextText{
        padding-left: 14px;
        padding-right: 14px;
    }
    .StandartPage>.PartnerWhitelabelGridIn{
        padding-left: 1px;
        padding-right: 1px;

    }
    .PartnerWhitelabelGridText{
        width: 100%;
        max-width: 400px;
    }
    .PartnerWhitelabelGridTextText{
        font-size: 17px;
        line-height: 25px;
    }
    .PartnerWhitelabelGridText{
        grid-template-columns: auto;
        width: auto;
    }
    .PartnerWhitelabelGridText02{
        margin-top: 20px;
    }}

@media only screen and (max-width: 500px) {

    .PartnerWhitelabelGridImg{
        background-size: cover;
        width: 100%;
        height: 240px;
    }
}

.PartnerWhitelabel01{
    padding-top: 44px;
    padding-bottom: 24px;
}

.PartnerWhitelabelNumber01{
    display: flex;
    align-items: center;
    padding-top: 8px;
}
.PartnerWhitelabelNumber01:first-of-type{
    padding-top: 0;
}

.PartnerWhitelabelNumber01>img{
    position: relative;
    top: 1px;
    margin-right: 6px;
}

.PartnerWhitelabelNumber01:first-of-type{
    padding-top: 0;
}

.PartnerWhitelabelBlue{
    background-color: #e5f3ff;
    padding-top: 32px;
    padding-bottom: 32px;
}

.PartnerWhitelabelBlueWhite{
    font-weight: 700;
    background-color: white;
    padding: 15px 45px 15px 15px;
    position: relative;
    left: -15px;
}

.PartnerWhitelabelPink{
    background-color: #ffe9e6;
    padding-bottom: 32px;
    margin-top: 27px;
}

.PartnerWhitelabelPink>.StandartH2{
    padding-bottom: 0;
}

@media only screen and (max-width: 800px) {
    .PartnerWhitelabelBlueWhite{
        background: none;
    }
}

.PartnerWhitelabel>div>.StandartH2,.PartnerWhitelabel>div>.StandartTitle{
    padding-top: 20px;
    padding-bottom: 4px;
}