

.HeaderAllOut{
    font-weight: 400;
    width: 100%;
    margin-bottom: 5px;
}

.HeaderAll{
    width: 100%;
    margin: auto;
    max-width: 1144px;
}




.HeaderAllIn{
    display: grid;
    grid-template-columns: 200px auto minmax(140px,180px);
    grid-column-gap: 50px;
}

@media only screen and (max-width: 1000px) {
    .HeaderAll{
        grid-column-gap: 20px;
    }
}

.HeaderAllTitleFind{
    position: relative;
    top: -4px;
}

.HeaderAllTitle{
    padding-top: 10px;
}

.HeaderAllButtons{
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.HeaderAllButtonsElement{
    padding-left: 10px;
    display: grid;
}

.HeaderAllButtonsElement>a,.HeaderAllButtonsElement>a:visited{
    font-size: 17px;
}

.HeaderAllButtonsElementIcon,.HeaderAllButtonsElementIconActive{
    display: none;

}

@media only screen and (max-width: 900px) {

    .HeaderAllButtonsElement{
        grid-template-columns: 21px auto;
        grid-column-gap: 8px;
    }
    .HeaderAllButtonsTopImg{
        display: none;
    }
    .HeaderAllButtons{
        flex-direction: column;
        align-items: flex-start;
    }
    .HeaderAllButtonsElementIcon{
        display: flex;
        justify-content: center;
    }
    .HeaderAllButtonsElement:hover>.HeaderAllButtonsElementIcon{
        display: none;
    }
    .HeaderAllButtonsElement:hover>.HeaderAllButtonsElementIconActive{
        display: flex;
        justify-content: center;
    }
    header{
        margin-top: 16px;
    }
    .HeaderAllButtonsOut{
        display: flex;
        justify-content: center;
    }
}


@media only screen and (max-width: 800px) {
    .HeaderAllIn{
        grid-column-gap: 0;
    }
}

.HeaderAllButtonsTopImg{
    background: url("../../../static/img/HeaderAll/HeaderAll08.svg") center;
    background-size: cover;
    width: 100%;
    height: 5px;
}


@media only screen and (max-width: 750px) {
    .HeaderAllButtonsTopImg{
        background: none;
    }
    .HeaderAllButtons{
        flex-direction: column;
    }
}

.HeaderAllTitleBottom{
    display: grid;
    width: 200px;
    grid-template-columns: 65px 130px;
    grid-column-gap: 10px;
    padding-left: 5px;
}

.HeaderAllRightOut{
    display: flex;
    justify-content: flex-end;
}

.HeaderAllRight{
    display: inline-flex;
    flex-direction: column;
    height: 65px;
    justify-content: space-around;
    padding-top: 5px;

}

.HeaderAllRight>div{
    display: inline-flex;
}

.HeaderAllRightLK{
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-top: 15px;
}

/*.HeaderAllRightLK>.HeaderAllRightLKText{*/
/*    border-bottom: dashed 1px #02518e;*/

/*}*/
.HeaderAllRightLK>div{
    margin-right: 6px;
    margin-left: 6px;
    padding-bottom: 1px;
    cursor: pointer;
    font-size: 15px;
}



.HeaderAllRightLK>div:hover{
    color: #2288da;
    border-bottom: dashed 1px #2288da;
}

.HeaderAllRight>.HeaderAllRightOnlyPhone,.HeaderAllTitle>.HeaderAllRightOnlyPhone {
    display: none;
}


.HeaderAllRightPhone{
    font-size: 17px;
    padding-top: 10px;
    margin-bottom: 5px;
}


.HeaderAllRightPhone>img{
    position: relative;
    top: 2px;

}

.HeaderAllRight>.HeaderAllRightOnline{
    display: none;
    font-size: 13px;
    text-decoration: underline;
}

.HeaderAllRightLK{
    color: #02518e;
    text-decoration: dotted;
}

.HeaderAllRightOnline>img{
    padding-right: 5px;
    position: relative;
    top: 2px;
}

.MenuForTelephone{
    display: none;
}



@media only screen and (max-width: 800px) {
    .HeaderAllIn{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .HeaderAll>.StandartIn{
        margin-left: 0;
        margin-right: 0;
    }
    .HeaderAllButtonsElement{
        height: 36px;
        align-items: center;
    }
    .HeaderAllButtonsElement:nth-of-type(2){
        border-left: 1px solid #afb3bc;
        border-right: 1px solid #afb3bc;
    }
    .HeaderAllTitle{
        margin-left: 10px;
        position: relative;
        top: -10px;
    }
    .HeaderAllRight{
        position: relative;
        top: -5px;
    }

    header{
        margin-top: 5px;
    }

    .HeaderAllTitleFind{
        display: none;
    }
    .MenuForTelephone{
        display: block;
    }
    .HeaderAllIn{
        grid-template-columns: auto;
    }
    .HeaderAllButtonsOut{
        grid-row: 3;
    }
    .HeaderAllRightOut{
        grid-row: 2;
    }

    .HeaderAllButtons{
        height: 59px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        background-color: #eef2fa;
        border-top: 1px solid #afb3bc;
        border-bottom: 1px solid #afb3bc;
        width: 100%;
    }
    .HeaderAllTitle{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .HeaderAllTitleBottom{
        display: block;
        width: auto;
    }
    .HeaderAllRight{
        flex-direction: unset;
        justify-content: space-around;
        align-items: center;
        height: auto;
    }
    .HeaderAllRightLK{
        margin-top: 0;
    }
    .HeaderAllButtonsElement>a{
        text-decoration: none;
    }
}

@media only screen and (max-width: 500px) {
    .HeaderAllRightPhone{
        display: none;
    }
    .CheckLanguage>.CheckLanguageImg{
        padding-left: 0;
    }
}

@media only screen and (max-width: 400px) {
    .HeaderAllRightPhone{
        font-size: 12px;
    }
    .HeaderAllTitle{
        margin-left: 0;
    }
    .HeaderAllButtonsElement>a, .HeaderAllButtonsElement>a:visited{
        font-size: 14px;
    }

    .HeaderAllRightPhone>img{
        top: -1px;
    }
}


.HeaderAllButtonsPhone{
    display: none;
}

@media only screen and (max-width: 800px) {
    .HeaderAllRightOut{
        position: relative;
        top: -5px;
    }
    .HeaderAllButtons{
        display: none;
    }
    .HeaderAllButtonsPhone{
        display: grid;
    }
    .HeaderAllRightLK{
        height: 31px;
        border: solid 1.5px #033569;
        border-radius: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 21px;
        padding-left: 21px;
    }
    .HeaderAllRightOnline>img {
        padding-right: 13px;
    }
    .HeaderAllRightOnline{
        height: 20px;
        border: solid 1px #d9e1f1;
        border-radius: 3px;
        padding: 6px 19px;
    }
    .HeaderAllRight>.HeaderAllRightNoPhone,.HeaderAllTitleBottom>.HeaderAllRightNoPhone{
        display: none;
    }
    .HeaderAllRight>.HeaderAllRightOnlyPhone{
        display: block;
    }

}

.HeaderAllButtonsPhone{
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    min-height: 23px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 15px;
}

.HeaderAllButtonsPhone>a{
    text-decoration: none;
}

.HeaderAllButtonsPhone>a>.HeaderAllButtonsPhoneElement{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: calc(100% - 14px);
}


.HeaderAllButtonsPhoneElement01Out{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.HeaderAllButtonsPhoneElement01Out>div>img{
    margin-left : 17px;
    margin-right : 17px;
}

.HeaderAllButtonsPhone>a>.HeaderAllButtonsPhoneElement>img,.HeaderAllButtonsPhoneElement01>img{
    margin-left: 11px;
    margin-right: 11px;
    padding-top: 8px;
    position: relative;
    top: -3px;
}

.HeaderAllButtonsPhoneElement01{
    border-left: solid 2px #033569;
    border-top: solid 2px #033569;
    border-bottom: solid 2px #033569;
    margin-left: 8px;
    background-color: #ffffff;
    min-height: 19px;
    padding-top: 7px;
    padding-bottom: 7px;
    width: 100%;
    color : black;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 18px;
    border-top-left-radius: 18px;
    position: relative;
    height: calc(100% - 18px);

}
.HeaderAllButtonsPhoneElement01Triangle{
    position: absolute;
    /*width: 0;*/
    /*height: 0;*/
    /*border-top: 5px solid transparent;*/
    /*border-left: 7px solid white;*/
    /*border-bottom: 5px solid transparent;*/
    width: 9px;
    height: 16px;
    background: url("../../../static/img/HeaderAll/HeaderPhone/trianleWhite.svg") center;
    background-repeat: no-repeat;
    top: calc(50% - 8px);
    right: -8px;
    z-index: 5;
}
.HeaderAllButtonsPhoneElement02{
    color: #64bee9;
    background-image: linear-gradient(to top, #2e1e9d, #4e32c5);
    position: relative;
    font-weight: 700;
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;
    padding-top: 7px;
    padding-bottom: 7px;
}

.HeaderAllButtonsPhoneElement02>.HeaderAllButtonsPhoneElementText{
    position: relative;
    z-index: 5;
}

.HeaderAllButtonsPhoneElement02Triangle{
    position: absolute;
    /*width: 0;*/
    /*height: 0;*/
    /*border-top: 5px solid transparent;*/
    /*border-left: 7px solid #4e32c5;*/
    /*border-bottom: 5px solid transparent;*/
    width: 9px;
    height: 16px;
    /*width: 20px;*/
    /*height: 100%;*/
    /*background: url("../../../../static/img/HeaderAll/HeaderPhone/triangleWithLeft.svg") center;*/
    background: url("../../../static/img/HeaderAll/HeaderPhone/triangle.svg") center;
    background-repeat: no-repeat;
    right: -8px;

    /*width: 9px;*/
    /*height: 16px;*/
    /*background: url("../../../../static/img/HeaderAll/HeaderPhone/triangle.svg") center;*/
    /*top: calc(50% - 8px);*/
    /*right: -8px;*/
}
.HeaderAllButtonsPhoneElement03{
    box-shadow: -5px 0 7px 0 rgba(0, 0, 0, 0.4);
    background-image: linear-gradient(to top, #3f86d4, #3065c5);
    color: #000911;
    margin-right: 8px;
    border-bottom-right-radius: 18px;
    border-top-right-radius: 18px;
    font-weight: 700;
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;
    padding-top: 7px;
    padding-bottom: 7px;
}


@media only screen and (max-width: 400px) {
    .HeaderAllButtonsPhoneElement03, .HeaderAllButtonsPhoneElement02 {
        padding-left: 3px;
        padding-right: 3px;
        margin-right: 0;
    }

    .HeaderAllButtonsPhoneElement01 {
        margin-left: 0;
    }

    .HeaderAllButtonsPhone {
        grid-template-columns: auto auto auto;
    }


}

@media only screen and (max-width: 800px) {
    .HeaderAllTitle>.HeaderAllRightOnlyPhone {
        display: block;
    }
}
@media only screen and (max-width: 700px) {
    .HeaderAllButtonsPhone>a>.HeaderAllButtonsPhoneElement,.HeaderAllButtonsPhoneElement01{
        flex-direction: column;
        align-items: center;
        ;
    }
    .HeaderAllButtonsPhoneElementText{
        text-align: center;
        min-width: 100px;
    }
}
.HeaderAllRightLKPhone{
    display: none;
}
@media only screen and (max-width: 500px) {
    .HeaderAllRightLK{
        padding-left: 5px;
        padding-right: 5px;
    }
    .HeaderAllRightLK>div,.HeaderAllRightOnline>div{
        display: none;
    }
    .HeaderAllRightLK>.for_cabOut{
        display: block;
    }
    .HeaderAllRightOnline>img{
        padding-right: 0;
    }
    .HeaderAllRightLK>.HeaderAllRightLKPhone{
        display: block;
        border-bottom: unset;
    }
}

@media only screen and (max-width: 485px) {
    .HeaderAllButtonsPhoneElementTextRu{
        padding-left: 15px;
        padding-right: 15px;
    }
    .HeaderAllButtonsPhoneElement01>.HeaderAllButtonsPhoneElementTextRu{
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media only screen and (max-width: 410px) {
    .HeaderAllButtonsPhoneElementTextRu{
        padding-left: 10px;
        padding-right: 10px;
    }
    .HeaderAllButtonsPhoneElement01>.HeaderAllButtonsPhoneElementTextRu{
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media only screen and (max-width: 400px) {
    .HeaderAllButtonsPhoneElementTextRu{
        padding-left: 10px;
        padding-right: 10px;
    }
    .HeaderAllButtonsPhoneElement01>.HeaderAllButtonsPhoneElementTextRu{
        padding-left: 10px;
        padding-right: 10px;
    }
}