.InvestIndexMain{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.TisMonitoring{
    margin-top: 40px;
}

.TisMonitoringMainElement{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.InvestIndexMainElementText{
    color: #898989;
    font-size: 16px;
    margin-left: 12px;
}

.TisMonitoringMainElement:nth-of-type(4){
    margin-right: 10px;
    margin-left: 50px;
}

.TisMonitoringMainElement:nth-of-type(5){
    margin-right: 80px;
    margin-left: 10px;
}