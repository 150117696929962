.AboutProvidersGrid{
    margin-top: 32px;
}

.AboutProvidersGridElementIn{
    max-width: 1088px;
    margin: auto;
    display: grid;
    grid-template-columns: 160px auto;
    align-items: center;
}

@media only screen and (max-width: 1100px) {
    .AboutProvidersGridElementIn{
        padding-left: 20px;
        padding-right: 20px;
    }
}


.AboutProvidersGridElementText{
    padding-top: 31px;
    padding-bottom: 31px;
}

.ProvLiqGridElementImg{
    padding-left: 18px;
}

.AboutProvidersGridElementTextTitle{

    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
}

.AboutProvidersGridElementTextText{

    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
}

.AboutProvidersGridElement:nth-child(2n-1) {
    background-color: #f4f4f4;
}

@media only screen and (max-width: 600px) {
    .AboutProvidersGridElementIn{
        grid-template-columns: auto;
        grid-template-rows: 146px auto;
    }
    .ProvLiqGridElementImg{
        margin: auto;
    }
    .AboutProvidersGridElementText{
        padding-top: 0;
    }
}
