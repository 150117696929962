.BlueFooter{
    width: 100%;
    height: 88px;
    background-color: #126cb8;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ButtonBlueFooter{

    padding-left: 15px;
    padding-right: 15px;
}
.BlueFooter>a{
    text-decoration: none;
}
