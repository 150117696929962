.AboutUsersMap>.StandartPage>.StandartTitle{
    padding-bottom: 0;
}

.AboutUsersMapGrid{
    display: grid;
    grid-template-columns: auto 384px;
    align-items: center;
    grid-column-gap: 30px;
    font-weight: 400;
    font-size: 17px;
    line-height: 27px;
}

.AboutUserMap{min-height: 500px}
.AboutUserMap>.StandartPage>.StandartTitle{
    padding-bottom: 0;
}

.AboutUsersMapGrid02{
    width: 100%;
    height: 90px;
    background-size: cover;
    box-shadow: 0 0 25px 0 rgba(0, 86, 142, 0.15), inset 0 0 0 5px #ffffff;
    background: #edf2f7 url("../../../static/img/About/UsersMap/AboutUsersMap01.svg") center;
    display: grid;
    grid-template-columns: 88px auto;
    grid-column-gap: 20px;
    align-items: center;
}

@media only screen and (max-width: 900px) {
    .AboutUsersMapGrid{
        display: grid;
        grid-template-columns: auto;
        grid-row-gap: 30px;
        grid-template-rows: auto auto;
    }
}


.AboutUsersMapGrid0202{
    padding-right: 20px;
}

.AboutUsersMapGrid0202>a{
    margin-left: 5px;
    margin-right: 5px;
}

.AboutUsersMapGrid0201>img{
    position: relative;
    top: 3px;
    left: 7px;
}

@media only screen and (max-width: 450px) {
    .AboutUsersMapGrid02{
        height: auto;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 15px;
    }
}

.AboutUsersMapContestMap{
    width: 100%;
    height: 600px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.UserMapElementGoogleMap{
    background: url("../../../static/img/About/Travel/iconGoogleMaps.svg");
    background-size: contain;
    width: 40px;
    height: 40px;
    top: -40px;
    left: -20px;
    position: relative;
    cursor: pointer;
}

.UserMapBigImage{
    top: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 700;
}

.UserMapBigImageIn{
    position: relative;
    z-index: 1000;
}
.UserMapBigImageTop{
    color: white;
    font-size: 17px;
    margin-left: 10px;
    margin-bottom: 5px;

}
.UserMapBigImageInfo{
    color: black;
    font-size: 11px;
    margin-top: 5px;
    flex-wrap: wrap;
    width: 90%;
}

.UserMapBigImage>div>img,.UserMapBigImage>div>div>img{
    max-height: 500px;
    max-width: 800px;
}

.UserMapBigImageBottom{
    background-color: white;
    border-radius: 5px;
    padding: 10px 10px 10px 10px;
    word-wrap: break-word;
    display: inline-block;
    white-space: pre-wrap;
}