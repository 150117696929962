.MutationCreateInvestClientLoading>img{
    margin-right: 10px;
}
.MutationCreateInvestClientLoading{
    font-size: 18px;
    margin: 20px 0 0 80px;
    display: flex;
    align-items: center;
}

.MutationCreateInvestResult{
    font-size: 18px;
    margin: 20px 0 0 80px;
    display: flex;
    align-items: center;
}

.InvestRegistrationButtomTextError{
    font-size: 18px;
    color: #da343d;
}